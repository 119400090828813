import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getMarketApiRequestsAysnc,
  getMarketApiUsageAysnc,
  selectMarketApiRequests,
  selectMarketApiRequestsLoading,
} from "../../../store/market-api/overviewSlice";
import MarketApiBanner from "./MarketApiBanner";
import { Typography, SimpleTable, Card, EmptyState } from "djuno-design";
import ReportedUsageWidget from "./ReportedUsageWidget";
import { useEffect } from "react";
import { formatTimestamp } from "../../../utils/date";

const MarketApiOverviewTab = () => {
  const requests = useAppSelector(selectMarketApiRequests);
  const requestsLoading = useAppSelector(selectMarketApiRequestsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMarketApiUsageAysnc());
    dispatch(getMarketApiRequestsAysnc());
  }, [dispatch]);

  return (
    <div className="flex flex-col gap-8">
      <MarketApiBanner />
      <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4">
        <ReportedUsageWidget />
      </div>
      <Card title="API Request Log">
        <SimpleTable
          loading={requestsLoading}
          className="table-auto"
          containerClassName="min-h-[240px]"
        >
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Last 100 API Requests" />
              <SimpleTable.TH lable="Timestamp" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {requests.map((request, index) => (
              <SimpleTable.Row key={index}>
                <SimpleTable.TD>
                  <Typography.Text
                    className="!text-xs md:!text-sm"
                    uiType="warning"
                  >
                    {request.Request}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text
                    className="!text-xs md:!text-sm"
                    uiType="warning"
                  >
                    {formatTimestamp(request.Timestamp).datetime}
                  </Typography.Text>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
            {requests.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={2} className="!border-none">
                  <EmptyState />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </Card>
    </div>
  );
};

export default MarketApiOverviewTab;
