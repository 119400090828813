import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import { SurveyAnswer, SurveyQuestion, SurveyState } from "../../types/survey";
import {
  getSurveyQuestionsApi,
  setSurveyQuestionsApi,
} from "../../apis/surveyAPI";
import toast from "react-hot-toast";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: SurveyState = {
  questions: [],
  questionsLoading: false,
  saveQuestionLoading: false,
};

export const getSurveyQuestionsAsync = createAsyncThunk<
  { questions: SurveyQuestion[] },
  undefined,
  IThunkRejectValue
>("survey/questions", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getSurveyQuestionsApi();
    const questions = response.data.Result;
    // const questions = [
    //   {
    //     id: 1,
    //     question: "what are you building",
    //     options: [
    //       "Aggregation",
    //       "Automation",
    //       "Defi",
    //       "Gaming",
    //       "Exchange",
    //       "NFT/Metaverse",
    //       "wallet",
    //       "other",
    //     ],
    //   },
    //   {
    //     id: 2,
    //     question: "How large is the company you are working for?",
    //     options: [
    //       "I am individual developer",
    //       "2-10 People",
    //       "11-50 People",
    //       "50-100 People",
    //       "more than 100 People",
    //     ],
    //   },
    //   {
    //     id: 3,
    //     question: "what is your role in your company ?",
    //     options: [
    //       "Technology / Business Manager",
    //       "Agency / Partner Developer",
    //       "Hobbyist",
    //       "Professional Developer",
    //       "Student",
    //       "Other",
    //     ],
    //   },
    // ];

    return fulfillWithValue({ questions });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const setSurveyQuestionsAsync = createAsyncThunk<
  boolean,
  { answers: SurveyAnswer[] },
  IThunkRejectValue
>("survey/questions/answer", async ({ answers }, { rejectWithValue }) => {
  try {
    await setSurveyQuestionsApi(answers);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});
export const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSurveyQuestionsAsync.pending, (state) => {
        state.questionsLoading = true;
      })
      .addCase(getSurveyQuestionsAsync.fulfilled, (state, action) => {
        const { questions } = action.payload;

        state.questions = questions;
        state.questionsLoading = false;
      })
      .addCase(getSurveyQuestionsAsync.rejected, (state, { payload }) => {
        state.questionsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(setSurveyQuestionsAsync.pending, (state) => {
        state.saveQuestionLoading = true;
      })
      .addCase(setSurveyQuestionsAsync.fulfilled, (state, action) => {
        state.saveQuestionLoading = false;
      })
      .addCase(setSurveyQuestionsAsync.rejected, (state, { payload }) => {
        state.saveQuestionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectSurveyQuestions = (state: RootState) =>
  state.survey.questions;
export const selectSurveyQuestionsLoading = (state: RootState) =>
  state.survey.questionsLoading;

export const selectSaveSurveyQuestionsLoading = (state: RootState) =>
  state.survey.saveQuestionLoading;

// export const { } = surveySlice.actions;
export default surveySlice.reducer;
