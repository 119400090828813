import { Typography, Skeleton } from "djuno-design";
import { useAppSelector } from "../../../hooks";
import {
  selectWalletApiUsage,
  selectWalletApiUsageLoading,
} from "../../../store/wallet-api/overviewSlice";

const IpfsReportedUsageWidget = () => {
  const usage = useAppSelector(selectWalletApiUsage);
  const usageLoading = useAppSelector(selectWalletApiUsageLoading);

  return (
    <div className="w-full col-span-1 rounded-lg border dark:border-dark-2 bg-white dark:bg-dark-3 p-5">
      <div className="flex flex-col w-full gap-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Typography.Text className="!text-sm">
              Monthly Reported Usage
            </Typography.Text>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex">
              {usageLoading ? (
                <Skeleton
                  shape="rectangle"
                  style={{ width: 80, height: 30 }}
                  animation="pulse"
                />
              ) : (
                <Typography.Text className="!text-base" strong>
                  {usage}
                </Typography.Text>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IpfsReportedUsageWidget;
