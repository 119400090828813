import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { paymentMethodsAsync } from "../store/billing/billingSlice";
import { selectOnStageEnv } from "../store/auth/authSlice";
import { AiChatProvider } from "./AiChatProvider";

const PanelContentProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const onStageEnv = useAppSelector(selectOnStageEnv);
  const dispatch = useAppDispatch();

  useEffect(() => {
    onStageEnv?.IsAccountOwner && dispatch(paymentMethodsAsync());
  }, [dispatch, onStageEnv?.IsAccountOwner]);

  return <AiChatProvider>{children}</AiChatProvider>;
};

export default PanelContentProvider;
