import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { InviteUserData, Team, TeamsState } from "../../../types/team";
import { ToastClasses } from "../../../components/modals/alerts";
import { IThunkRejectValue, RootState } from "../../../types";
import {
  inviteUserApi,
  getTeamsApi,
  deleteTeamUserApi,
  acceptTeamInvitationApi,
  checkInvitationApi,
} from "../../../apis/teamAPI";
import { getExtractErrors } from "../../../apis";
import { CustomErrorToast } from "../../../components/general/Toast";

const initialState: TeamsState = {
  teams: [],
  loading: false,
  inviteModalIsOpen: false,
  inviteLoading: false,
  acceptInviteLoading: false,
  actionLoading: false,
};

export const getTeamsAsync = createAsyncThunk<
  { teams: Team[] },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "teams",
  async (
    { withoutLoading = false },
    { rejectWithValue, fulfillWithValue, requestId }
  ) => {
    try {
      getTeamsAsync.pending(requestId, { withoutLoading });

      const response = await getTeamsApi();
      const teams = response.data.Result;

      return fulfillWithValue({ teams });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const inviteUserAsync = createAsyncThunk<
  any,
  InviteUserData,
  IThunkRejectValue
>("teams/invite", async (data, { rejectWithValue }) => {
  try {
    const res = await inviteUserApi([data]);
    return res.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteTeamUserAsync = createAsyncThunk<
  any,
  { id: number },
  IThunkRejectValue
>("teams/user/delete", async ({ id }, { rejectWithValue }) => {
  try {
    const res = await deleteTeamUserApi(id);
    return res.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const checkInvitationAync = createAsyncThunk<
  any,
  { env: string; token: string },
  IThunkRejectValue
>("teams/invite/check", async ({ env, token }, { rejectWithValue }) => {
  try {
    const res = await checkInvitationApi(env, token);
    return res.data.Result;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const acceptTeamInvitationsync = createAsyncThunk<
  any,
  { env: string; token: string },
  IThunkRejectValue
>("teams/invite/accept", async ({ env, token }, { rejectWithValue }) => {
  try {
    const res = await acceptTeamInvitationApi(env, token);
    return res.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    openInviteModal: (state) => {
      state.inviteModalIsOpen = true;
    },
    closeInviteModal: (state) => {
      state.inviteModalIsOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getTeamsAsync.fulfilled, (state, action) => {
        const { teams } = action.payload;
        state.teams = teams;
        state.loading = false;
      })
      .addCase(getTeamsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(inviteUserAsync.pending, (state) => {
        state.inviteLoading = true;
      })
      .addCase(inviteUserAsync.fulfilled, (state, action) => {
        state.inviteLoading = false;
        toast.success("Verification email sent successfully", {
          className: ToastClasses,
        });
      })
      .addCase(inviteUserAsync.rejected, (state, { payload }) => {
        state.inviteLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteTeamUserAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteTeamUserAsync.fulfilled, (state, action) => {
        state.actionLoading = false;
      })
      .addCase(deleteTeamUserAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(acceptTeamInvitationsync.pending, (state) => {
        state.acceptInviteLoading = true;
      })
      .addCase(acceptTeamInvitationsync.fulfilled, (state, action) => {
        state.acceptInviteLoading = false;
      })
      .addCase(acceptTeamInvitationsync.rejected, (state, { payload }) => {
        state.acceptInviteLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectTeams = (state: RootState) => state.teams.teams;
export const selectTeamsLoading = (state: RootState) => state.teams.loading;

export const selectInviteModalIsOpen = (state: RootState) =>
  state.teams.inviteModalIsOpen;
export const selectInviteLoading = (state: RootState) =>
  state.teams.inviteLoading;

export const selectActionLoading = (state: RootState) =>
  state.teams.actionLoading;

export const selectAcceptLoading = (state: RootState) =>
  state.teams.acceptInviteLoading;

export const { closeInviteModal, openInviteModal } = teamsSlice.actions;
export default teamsSlice.reducer;
