import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Web3EndpointSchema } from "../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useCallback, useEffect, useState } from "react";
import {
  createWeb3AuthEndpointAsync,
  selectActionLoading,
  selectSelectedWeb3AuthEndpoint,
  selectShowEditorModal,
  closeWeb3AuthModal,
  getWeb3AuthEndpointsAsync,
  selectWeb3AuthEndpoints,
  selectWeb3EndpointsLoading,
} from "../../store/web3-auth/web3AuthEndpointsSlice";
import {
  getWeb3NetworksAsync,
  selectWeb3Networks,
  selectWeb3NetworksLoading,
} from "../../store/web3-auth/web3PublicSlice";
import { Button, Modal, Input, MultiSelect } from "djuno-design";

const CreateWeb3EndpointModal = () => {
  const isOpen = useAppSelector(selectShowEditorModal);

  const web3Endpoint = useAppSelector(selectSelectedWeb3AuthEndpoint);

  const endpoints = useAppSelector(selectWeb3AuthEndpoints);
  const endpointsLoading = useAppSelector(selectWeb3EndpointsLoading);

  const web3Networks = useAppSelector(selectWeb3Networks);
  const web3NetworksLoading = useAppSelector(selectWeb3NetworksLoading);

  const loading = useAppSelector(selectActionLoading);
  const dispatch = useAppDispatch();

  const [selectedNetworksOptions, setSelectedNetworksOptions] = useState<
    Array<string>
  >([]);

  const [selectedNetworksError, setSelectedNetworksError] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    if (selectedNetworksOptions.length > 0) {
      setSelectedNetworksError(undefined);
    }
  }, [selectedNetworksOptions]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(Web3EndpointSchema(endpoints.map((e) => e.Name))),
    mode: "all",
  });

  useEffect(() => {
    if (isOpen && web3Endpoint) {
      setValue("Name", web3Endpoint.Name);
    } else {
      setValue("Name", "");
    }
  }, [isOpen, setValue, web3Endpoint]);

  useEffect(() => {
    if (isOpen && web3Networks.length === 0) {
      dispatch(getWeb3NetworksAsync());
    }
  }, [dispatch, isOpen, web3Networks.length]);

  const onSubmit = (data: any) => {
    if (selectedNetworksOptions.length > 0) {
      if (!endpointsLoading && !loading) {
        dispatch(
          createWeb3AuthEndpointAsync({
            name: data.Name,
            networks: selectedNetworksOptions.map((sn) => Number(sn)),
          })
        ).then((action) => {
          if (action.type === "web3-auth/create/fulfilled") {
            reset();
            setSelectedNetworksOptions([]);
            dispatch(closeWeb3AuthModal());
            dispatch(getWeb3AuthEndpointsAsync());
          }
        });
      }
    } else {
      setSelectedNetworksError(true);
    }
  };

  const handleOnClose = useCallback(() => {
    setSelectedNetworksOptions([]);
    dispatch(closeWeb3AuthModal());
    reset();
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="max-w-lg"
      title={web3Endpoint ? "Update Web3 Endpoint" : "Create New Web3 Endpoint"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Input
            label="Name"
            {...register("Name")}
            error={errors.Name?.message}
            placeholder=""
          />
          <MultiSelect
            label="Chains"
            options={web3Networks.map((n) => ({
              label: n.NetworkName,
              value: n.Id.toString(),
            }))}
            values={selectedNetworksOptions}
            onChange={setSelectedNetworksOptions}
            loading={web3NetworksLoading}
            error={
              selectedNetworksError ? "Select at least one network!" : undefined
            }
            clearable
            // emptyString="Select networks"
          />
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading || endpointsLoading}
            uiType="primary"
            disabled={endpointsLoading}
            type="submit"
            className="w-[100px]"
          >
            {web3Endpoint ? "Update" : "Create"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateWeb3EndpointModal;
