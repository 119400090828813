import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { Button } from "djuno-design";
import {
  selectWebAppNameValidation,
  selectWebAppNameValidationLoading,
} from "../../store/web-app/webAppCreateSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { WebAppTemplateschema } from "../../utils/validations";
import CreateFormStep from "../../components/web-apps/create-page-steps/ConfigsStep";
import { WebAppsUrl, WebAppTemplateUrl } from "../../utils/urls";
import { useLocation, useNavigate } from "react-router-dom";
import useConfigIntercomeSetting from "../../hooks/useConfigIntercomeSetting";
import {
  craeteWebAppsMarketPlaceAsync,
  getWebAppsAsync,
  getWebAppsPlansAsync,
  selectWebAppsActionLoading,
} from "../../store/web-app/webAppsSlice";

const WebAppCreateFromMarketPlacePage = () => {
  useConfigIntercomeSetting();
  const form = useForm({
    resolver: yupResolver(WebAppTemplateschema),
    mode: "all",
    // defaultValues: { PlanId: 17 },
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { selectedTemplate } = location.state || {};

  const loading = useAppSelector(selectWebAppsActionLoading);
  const nameValidationLoading = useAppSelector(
    selectWebAppNameValidationLoading
  );
  const nameValidation = useAppSelector(selectWebAppNameValidation);

  useEffect(() => {
    dispatch(getWebAppsPlansAsync({}));
  }, [dispatch]);

  const handleCreateWebApp = (data: any) => {
    // console.log(data);
    if (!loading) {
      const formData = {
        AppName: data.AppName,
        PlanId: data.PlanId,
        ServiceName: selectedTemplate.Name,
      };

      dispatch(craeteWebAppsMarketPlaceAsync({ data: formData })).then(
        (action) => {
          if (action.type === "web-app/market/craete/fulfilled") {
            dispatch(getWebAppsAsync({}));
            navigate(WebAppsUrl);
          }
        }
      );
    }
  };

  return (
    <>
      <form onSubmit={form.handleSubmit(handleCreateWebApp)}>
        <div className="w-full px-6 mt-16 pb-10">
          <CreateFormStep form={form} showPort={false} showEnvVars={false} />
        </div>
        <div className="fixed bottom-0 right-0 left-0 flex items-center w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6 lg:pl-80 ">
          <div className="flex items-center justify-between w-full">
            <Button uiType="light" onClick={() => navigate(WebAppTemplateUrl)}>
              back
            </Button>
            <Button
              uiType="primary"
              type="submit"
              loading={loading || nameValidationLoading}
              disabled={
                !form.formState.isValid ||
                nameValidation !== null ||
                nameValidationLoading
              }
            >
              Create Web App
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default WebAppCreateFromMarketPlacePage;
