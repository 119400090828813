import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { WorkflowNotActiveTab } from "../../layouts/NotData";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { DeleteModal } from "../../modals/QuestionModal";
import { useSearch } from "../../../providers/SearchProvider";
import HighlighterText from "../../general/HighlighterText";
import {
  selectWorkflowsApiKeys,
  selectWorkflowsApiKeysActionLoading,
  selectWorkflowsApiKeysLoading,
  handleShowEditorModal,
  deleteWorkflowsApiKeyAsync,
  getWorkflowsApiKeysAsync,
} from "../../../store/workflows/apiKeysSlice";
import { IWorkflowApiKey } from "../../../types/workflows";
import WorkflowApiKeyEditorModal from "./WorkflowApiKeyEditorModal";
import { selectWorkflowStatus } from "../../../store/workflows/workflowsSlice";
import {
  Button,
  Dropdown,
  SimpleTable,
  Typography,
  SecureCopy,
  EmptyState,
  Flex,
} from "djuno-design";

const WorkflowsApiKeysTab = () => {
  const workflowStatus = useAppSelector(selectWorkflowStatus);

  const [deleteApiKeyId, setDeleteApiKeyId] = useState<string | null>(null);
  const apiKeys = useAppSelector(selectWorkflowsApiKeys);
  const [filteredApiKeys, setFilteredApiKeys] = useState<IWorkflowApiKey[]>([]);
  const loading = useAppSelector(selectWorkflowsApiKeysLoading);
  const actionLoading = useAppSelector(selectWorkflowsApiKeysActionLoading);
  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getWorkflowsApiKeysAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpApiKeys = apiKeys.filter((apiKey) =>
      apiKey.keyName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredApiKeys(lookedUpApiKeys);
  }, [dispatch, searchValue, apiKeys]);

  if (!workflowStatus) {
    return <WorkflowNotActiveTab />;
  }

  return (
    <>
      <div className="flex transition-all duration-500">
        <Typography.Text>API Keys</Typography.Text>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2">
          <Button
            uiType="primary"
            onClick={() => dispatch(handleShowEditorModal({}))}
            className="group"
          >
            Create API Key
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <SimpleTable loading={loading} containerClassName="min-h-[240px]">
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Label" />
              <SimpleTable.TH lable="Value" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredApiKeys.map((apiKey, index) => (
              <SimpleTable.Row key={index}>
                <SimpleTable.TD className="w-36">
                  <Flex>
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={apiKey.keyName}
                      className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate whitespace-pre"
                    />
                  </Flex>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <SecureCopy
                    text={apiKey.apiKey}
                    type="hide"
                    className=""
                    textClassName="!text-sm"
                    uiSize="small"
                  />
                </SimpleTable.TD>
                <SimpleTable.TD className="w-10">
                  <div className="w-8 flex justify-center items-center">
                    <Dropdown
                      anchor="bottom end"
                      itemsClassName="!w-40"
                      menu={[
                        {
                          key: "1",
                          label: (
                            <div className="flex items-center gap-1">
                              <EditIcon className="w-4" />
                              Edit
                            </div>
                          ),
                          onClick: (_, close) => {
                            close();
                            dispatch(handleShowEditorModal({ apiKey }));
                          },
                        },
                        {
                          type: "divider",
                        },
                        {
                          key: "end",
                          label: (
                            <div className="flex items-center gap-1">
                              <ArchiveIcon className="w-4" />
                              Delete
                            </div>
                          ),
                          danger: true,
                          onClick: (_, close) => {
                            close();
                            setDeleteApiKeyId(apiKey.id);
                          },
                        },
                      ]}
                    >
                      <Button uiSize="small" uiType="icon" className="!px-2">
                        <MoreIcon className="w-4 h-4" />
                      </Button>
                    </Dropdown>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
            {apiKeys.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={3} className="!border-0">
                  <EmptyState text="You have not created any API key yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <WorkflowApiKeyEditorModal />

      <DeleteModal
        title="Delete API Key"
        isOpen={deleteApiKeyId !== null}
        onClose={() => setDeleteApiKeyId(null)}
        description=""
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteApiKeyId &&
            dispatch(deleteWorkflowsApiKeyAsync({ id: deleteApiKeyId })).then(
              (action) => {
                if (action.type === "workflows/api-keys/delete/fulfilled") {
                  dispatch(getWorkflowsApiKeysAsync());
                }
                setDeleteApiKeyId(null);
              }
            );
        }}
        loading={actionLoading}
        // confirmString={
        //   apiKeys.find((api) => api.id === deleteApiKeyId)?.keyName
        // }
      />
    </>
  );
};

export default WorkflowsApiKeysTab;
