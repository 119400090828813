import {
  Button,
  cn,
  Flex,
  Loading,
  Select,
  Tag,
  Typography,
} from "djuno-design";
import { Helmet } from "react-helmet";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as Star } from "./../../assets/icons/star.svg";
import { ReactComponent as DocumentIcon } from "./../../assets/icons/document-text.svg";
import { WebAppsUrl, WebAppTemplateCreateUrl } from "../../utils/urls";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useConfigIntercomeSetting from "../../hooks/useConfigIntercomeSetting";
import {
  getWebAppsMarketPlaceAsync,
  selectWebAppsMarketPaceCategories,
  selectWebAppsMarketPlaces,
  selectWebAppsMarketPlacesLoading,
} from "../../store/web-app/webAppsSlice";
import { WebAppMarketPlace } from "../../types/web-app";

// export const templateOptions = [
//   {
//     label: "All",
//     value: "0",
//   },
//   {
//     label: "Private",
//     value: "1",
//   },
//   {
//     label: "Market",
//     value: "2",
//   },
// ];

export interface Template {
  TemplateDescription: string;
  TemplateImage: string;
  TemplateName: string;
  Stars: number;
  License: string;
}

const WebAppTemplatePage = () => {
  useConfigIntercomeSetting();

  const templates = useAppSelector(selectWebAppsMarketPlaces);
  const templatesLoading = useAppSelector(selectWebAppsMarketPlacesLoading);
  const categories = useAppSelector(selectWebAppsMarketPaceCategories);

  const [selectedTemplate, setSelectedTemplate] = useState<
    WebAppMarketPlace | undefined
  >();
  const [templateOption, setTemplateOption] = useState<string>("0");
  const [filtertemplates, setFiltertempaltes] = useState<WebAppMarketPlace[]>(
    []
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const templateOptions = Object.keys(categories).map((key, index) => ({
    label: key,
    value: String(index + 1), // Generate value starting from "1"
  }));

  templateOptions.unshift({
    label: "All",
    value: "0",
  });

  useEffect(() => {
    dispatch(getWebAppsMarketPlaceAsync());
  }, [dispatch]);

  const handleSelectTemplate = (template: WebAppMarketPlace) => {
    setSelectedTemplate(template);
  };

  useEffect(() => {
    const filterTemplatesByOption = () => {
      if (templateOption === "0") {
        setFiltertempaltes(templates);
      } else {
        const selectedLabel = templateOptions.find(
          (option) => option.value === templateOption
        )?.label;
        if (selectedLabel) {
          const filtered = templates.filter((template) =>
            template.Categories.some(
              (category) =>
                category.toLowerCase() === selectedLabel.toLowerCase()
            )
          );
          setFiltertempaltes(filtered);
        } else {
          setFiltertempaltes([]);
        }
      }
    };

    filterTemplatesByOption();
  }, [templateOption, templateOptions, templates]);

  const formatLicense = (license: string) => {
    if (!license) return "";

    const versionRegex = /v?(\d+(\.\d+)*$)/; // Matches versions like "v3.0" or "3.0"
    const versionMatch = license.match(versionRegex);
    const version = versionMatch ? versionMatch[0] : "";

    const licenseWithoutVersion = license.replace(versionRegex, "").trim();

    const words = licenseWithoutVersion.split(" ");
    if (words.length > 2) {
      const acronym = words.map((word) => word[0].toUpperCase()).join("");

      return version ? `${acronym}-${version}` : acronym;
    }

    return license;
  };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Templates</title>
        <meta
          name="description"
          content="RPC-endpoints, or Remote Procedure Call endpoints, are a fundamental aspect of distributed computing systems, enabling communication between different components or services across a network"
        />
      </Helmet>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 bg-white dark:bg-dark-1">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <div className="font-medium flex items-center gap-1">
            <Typography.Text>Templates</Typography.Text>
          </div>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(WebAppsUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <div className="mt-5 w-full overflow-x-auto px-6 min-h-[calc(100%-6rem)]">
        <div className="flex justify-start mb-5 gap-5">
          <Select
            label="Select Category:"
            value={templateOption}
            onChange={(v) => setTemplateOption(v || "0")}
            options={templateOptions.map((option) => ({
              label: option.label,
              value: option.value,
            }))}
            buttonClassName="!w-64"
            optionsClassName=" !max-h-[200px]"
          />
        </div>
        <div className="flex-1 flex  pb-20">
          <AnimatePresence>
            <>
              {!templatesLoading && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="grid grid-cols-12 w-full gap-6"
                >
                  {Array.isArray(filtertemplates) &&
                    filtertemplates.length > 0 &&
                    filtertemplates.map(
                      (template: WebAppMarketPlace, index: number) => (
                        <WebAppTemplateCard
                          key={index}
                          TemplateDescription={template.Description}
                          TemplateImage={template.Icon}
                          TemplateName={template.Name}
                          Stars={template.Stars}
                          License={formatLicense(template.License)}
                          selected={selectedTemplate?.Name === template.Name}
                          onClick={() => handleSelectTemplate(template)}
                        />
                      )
                    )}
                </motion.div>
              )}
              {templatesLoading && (
                <motion.div
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="min-h-[300px] w-full flex justify-center items-center"
                >
                  <Loading borderSize={2} />
                </motion.div>
              )}
            </>
          </AnimatePresence>
          <div className="fixed bottom-0 right-0 left-0 flex items-center justify-end w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6 lg:pl-80 z-30">
            <div className="">
              <Button
                uiType="primary"
                disabled={!!!selectedTemplate}
                onClick={() =>
                  navigate(WebAppTemplateCreateUrl, {
                    state: { selectedTemplate: selectedTemplate },
                  })
                }
                className="group"
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const WebAppTemplateCard = ({
  TemplateDescription,
  TemplateImage,
  TemplateName,
  Stars,
  License,
  selected,
  onClick,
}: Template & {
  selected?: boolean;
  onClick?: () => void;
}) => {
  return (
    <div
      className={cn(
        "col-span-12 md:col-span-4 xl:col-span-3 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white shadow hover:shadow-lg transition-all duration-300",
        {
          "border-primary-400 dark:border-primary-400": selected,
          "cursor-pointer": onClick,
        }
      )}
      onClick={() => (onClick ? onClick() : {})}
    >
      <Flex direction="col" items="center">
        <Flex
          items="center"
          justify="center"
          className="relative h-20 w-20  mt-5"
        >
          {TemplateImage ? (
            <img
              className="w-20 h-20 rounded-lg  "
              alt=""
              src={TemplateImage}
            />
          ) : (
            ""
          )}
        </Flex>
      </Flex>
      <Flex direction="col" className="gap-1 m-5 !items-start" items="start">
        <Typography.Text size="sm" className="font-semibold whitespace-pre">
          {TemplateName}
        </Typography.Text>
        <div className="flex flex-row">
          <Tag color="processing" className="ml-1 ">
            <div className="!flex !flex-row">
              <Star className="w-4 h-4" /> {Stars}
            </div>
          </Tag>
          <Tag color="processing" className="ml-1">
            <div className="!flex !flex-row">
              <DocumentIcon className="w-4 h-4" /> {License}
            </div>
          </Tag>
        </div>

        <Typography.Text
          size="sm"
          uiType="secondary"
          className="flex !flex-wrap !whitespace-normal !break-words max-w-[200px] max-h-[60px] overflow-hidden"
        >
          {TemplateDescription}
        </Typography.Text>
      </Flex>
    </div>
  );
};

export default WebAppTemplatePage;
