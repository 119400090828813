import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";

import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
// import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";

import { useNavigate } from "react-router-dom";
import { DataStreamGeneralInformationUrl } from "../../../utils/urls";
import { DeleteModal } from "../../modals/QuestionModal";
import { useSearch } from "../../../providers/SearchProvider";

import {
  deleteDSSConnectorAsync,
  getDSSConnectorsAsync,
  handleShowIPsEditorModal,
  handleToggleDSSConnectorModal,
  selectDatabaseService,
  selectDatabaseServiceLoading,
  selectDSSConnectors,
  selectDSSConnectorsActionLoading,
  selectDSSConnectorsLoading,
} from "../../../store/database/serviceSlice";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import {
  Button,
  Dropdown,
  EmptyState,
  SimpleTable,
  Typography,
} from "djuno-design";
import HighlighterText from "../../general/HighlighterText";
import { Connector } from "../../../types/data-straming";
import { ConnectorCreateModal } from "./ConnectorCreateModal";

const ConnectorsTab = () => {
  const [deleteConnector, setDeleteConnector] = useState<Connector | null>(
    null
  );

  const connectors = useAppSelector(selectDSSConnectors);
  const connectorsLoading = useAppSelector(selectDSSConnectorsLoading);
  const [filteredConnectors, setFilteredConnectors] = useState<Connector[]>([]);

  const service = useAppSelector(selectDatabaseService);
  const loading = useAppSelector(selectDatabaseServiceLoading);

  const actionLoading = useAppSelector(selectDSSConnectorsActionLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().connectors;
      if (!permissions.includes(service.engine)) {
        navigate(DataStreamGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDSSConnectorsAsync({
            engine: service.engine,
            clusterId: service.id,
          })
        );
      }
    }
  }, [dispatch, navigate, searchValue, service]);

  useEffect(() => {
    const lookedUpFiles = connectors?.filter((r) =>
      r.name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredConnectors(lookedUpFiles);
  }, [dispatch, searchValue, connectors]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Connectors</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex items-center justify-between">
            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Typography.Title level={5} className="!mb-0">
                Connectors
              </Typography.Title>
            </div>
            <div className="flex items-center gap-2">
              <Button
                uiType="light"
                onClick={() => {
                  if (service) {
                    dispatch(
                      getDSSConnectorsAsync({
                        engine: service.engine,
                        clusterId: service.id,
                      })
                    );
                  }
                }}
                disabled={!service}
                className="group"
                tooltip={{ content: "Refresh" }}
              >
                <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
              <Button
                uiType="primary"
                onClick={() => dispatch(handleToggleDSSConnectorModal(true))}
                className="group"
              >
                Add Connector
                <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
            </div>
          </div>

          <div className="mt-5 w-full">
            <SimpleTable
              loading={loading || connectorsLoading}
              containerClassName="min-h-[240px]"
            >
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Source" />
                  <SimpleTable.TH lable="Target" />
                  <SimpleTable.TH lable="Topics" />
                  <SimpleTable.TH lable="Topics blacklists" />
                  <SimpleTable.TH lable="Sync group effects" />
                  <SimpleTable.TH lable="Sync intervals(s)" />
                  <SimpleTable.TH lable="" />
                </SimpleTable.Row>
              </SimpleTable.Head>
              <SimpleTable.Body>
                {service &&
                  filteredConnectors.map((connector, i) => (
                    <SimpleTable.Row key={i}>
                      <SimpleTable.TD>
                        <div className="flex items-center flex-row justify-between space-x-3">
                          <HighlighterText
                            searchValue={searchValue}
                            textToHighlight={connector.id}
                            className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                          />
                        </div>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {connector.id}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {connector.id}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {connector.id}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {connector.id}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {connector.id}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD className="w-20 gap-1">
                        <div className="h-full w-full inline-flex items-center justify-end gap-1">
                          <div className="w-8 flex justify-center items-center">
                            <Dropdown
                              itemsClassName="!w-48"
                              anchor="bottom end"
                              menu={[
                                {
                                  key: "1",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <EditIcon className="w-4"></EditIcon>
                                      Edit IP address
                                    </div>
                                  ),
                                  onClick: (_, close) => {
                                    close();
                                    // dispatch(handleShowIPsEditorModal({ ip }));
                                  },
                                  disabled: loading,
                                },

                                {
                                  type: "divider",
                                },
                                {
                                  key: "end",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <ArchiveIcon className="w-4" />
                                      Delete IP address
                                    </div>
                                  ),
                                  danger: true,
                                  onClick: (_, close) => {
                                    close();
                                    setDeleteConnector(connector);
                                  },
                                  disabled: loading,
                                },
                              ]}
                            >
                              <Button
                                uiType="icon"
                                uiSize="small"
                                className="!px-2"
                              >
                                <MoreIcon className="w-4 h-4" />
                              </Button>
                            </Dropdown>
                          </div>
                          {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                        </div>
                      </SimpleTable.TD>
                    </SimpleTable.Row>
                  ))}

                {service?.ipRestrictions.length === 0 && (
                  <SimpleTable.Row withoutHoverStyle className="h-[200px]">
                    <SimpleTable.TD colSpan={7} className="!border-0">
                      <EmptyState text="You have not created any Connector Flow yet" />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>
          </div>
          <ConnectorCreateModal />

          <DeleteModal
            title="Delete Connector Flow"
            isOpen={deleteConnector !== null}
            onClose={() => setDeleteConnector(null)}
            description=""
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            onConfirm={() => {
              deleteConnector &&
                service &&
                dispatch(
                  deleteDSSConnectorAsync({
                    engine: service?.engine,
                    clusterId: service.id,
                    connectorId: deleteConnector.id,
                  })
                ).then((action) => {
                  if (action.type === "service/connectors/delete/fulfilled") {
                    dispatch(
                      getDSSConnectorsAsync({
                        engine: service.engine,
                        clusterId: service.id,
                      })
                    );
                    setDeleteConnector(null);
                  }
                });
            }}
            loading={actionLoading}
          />
        </>
      )}
    </>
  );
};

export default ConnectorsTab;
