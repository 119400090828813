import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { useNavigate } from "react-router-dom";
import {
  getDBSAvancedConfigurationCapabilitiesAsync,
  getDBSAvancedConfigurationsAsync,
  selectDBSAdvancedConfigurations,
  selectDBSAdvancedConfigurationsActionLoading,
  selectDBSAdvancedConfigurationsCapabilities,
  selectDBSAdvancedConfigurationsCapabilitiesLoading,
  selectDBSAdvancedConfigurationsLoading,
  selectDatabaseService,
  updateDBSAvancedConfigurationsAsync,
} from "../../../store/database/serviceSlice";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import {
  DatabaseServiceGeneralInformationUrl,
  DataStreamGeneralInformationUrl,
} from "../../../utils/urls";
import { DBS_ACC, DBSCategory } from "../../../types/database";
import RadioGrouper from "../../general/RadioGrouper";
import {
  Button,
  Flex,
  Input,
  JsonViewer,
  Loading,
  Select,
  SelectOption,
  Typography,
  uuid,
} from "djuno-design";

type AdvancedConfigFieldStatusType =
  | "default" // can't change key, can change value, has not any button
  | "key_selecting" // just change key
  | "value_selecting_open" // can change key, can change value, has add button
  | "value_selecting_close"; // can't change key, can change value, has delete button

interface AdvancedConfigData {
  id: string;
  name: string;
  value: string;
  status: AdvancedConfigFieldStatusType;
}

const DBSAdvancedConfigurationsTab: React.FC<{ section: DBSCategory }> = ({
  section,
}) => {
  const service = useAppSelector(selectDatabaseService);

  const ACCs = useAppSelector(selectDBSAdvancedConfigurationsCapabilities);
  const ACCsLoading = useAppSelector(
    selectDBSAdvancedConfigurationsCapabilitiesLoading
  );

  const configurations = useAppSelector(selectDBSAdvancedConfigurations);
  const configurationsLoading = useAppSelector(
    selectDBSAdvancedConfigurationsLoading
  );
  const configurationsActionLoading = useAppSelector(
    selectDBSAdvancedConfigurationsActionLoading
  );

  const [data, setData] = useState<AdvancedConfigData[]>([]);
  const dataObject = useMemo(() => {
    return data.reduce(
      (
        acc: {
          [key: string]: string;
        },
        obj: AdvancedConfigData
      ) => {
        if (obj.name && obj.value) {
          acc[obj.name] = obj.value;
        }
        return acc;
      },
      {}
    );
  }, [data]);

  const [activeKeyOptions, setActiveKeyOptions] = useState<
    SelectOption<string>[]
  >([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // get init data
  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().advancedConfigurations;
      if (!permissions.includes(service.engine)) {
        if (section === "ds")
          navigate(DataStreamGeneralInformationUrl(service.id));
        if (section === "db")
          navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSAvancedConfigurationCapabilitiesAsync({
            clusterId: service.id,
            engine: service.engine,
          })
        );
        dispatch(
          getDBSAvancedConfigurationsAsync({
            clusterId: service.id,
            engine: service.engine,
          })
        );
      }
    }
  }, [dispatch, navigate, section, service]);

  //initial data from prev configurations
  useEffect(() => {
    if (configurations) {
      const initData: AdvancedConfigData[] = Object.keys(configurations).map(
        (name) => ({
          id: name,
          name,
          value: configurations[name],
          status: "default",
        })
      );
      setData([
        ...initData,
        {
          id: uuid(),
          name: "",
          value: "",
          status: "key_selecting",
        },
      ]);
    }
  }, [configurations]);

  //generate initial oprions
  useEffect(() => {
    let filteredACCs: DBS_ACC[] = [];

    if (configurations) {
      filteredACCs = ACCs.filter(
        (acc) => !Object.keys(configurations).includes(acc.name)
      );
    }
    const initOptions = filteredACCs.map((acc) => ({
      label: acc.name,
      value: acc.name,
    }));
    setActiveKeyOptions(initOptions);
  }, [ACCs, configurations]);

  //regenerate select options
  const regenerateOptions = useCallback(
    (data: AdvancedConfigData[]) => {
      const options = ACCs.filter(
        (acc) => !data.map((d) => d.name).includes(acc.name)
      ).map((acc) => ({
        label: acc.name,
        value: acc.name,
      }));
      setActiveKeyOptions(options);
    },
    [ACCs]
  );

  const handleAddNewField = (currentId: string) => {
    setData((preData) => {
      const newData: AdvancedConfigData[] = [
        ...preData.map((d) => {
          if (d.id === currentId) {
            return {
              ...d,
              status: "value_selecting_close" as AdvancedConfigFieldStatusType,
            };
          }
          return d;
        }),
        {
          id: uuid(),
          name: "",
          value: "",
          status: "key_selecting",
        },
      ];
      regenerateOptions(newData);
      return newData;
    });
  };

  const handleUpdateFieldKey = (id: string, value: string | undefined) => {
    const acc = ACCs.find((acc) => acc.name === value);
    setData((prevData) => {
      return prevData.map((d) => {
        if (d.id === id) {
          return {
            ...d,
            id: acc ? acc.name : value || id,
            name: acc ? acc.name : value || id,
          };
        }
        return d;
      });
    });
  };

  const handleUpdateFieldValue = (id: string, value: string | undefined) => {
    setData((prevData) => {
      return prevData.map((d) => {
        if (d.id === id) {
          return {
            ...d,
            value: value || "",
            status:
              d.status === "value_selecting_close" || d.status === "default"
                ? d.status
                : "value_selecting_open",
          };
        }
        return d;
      });
    });
  };

  const handleRemoveField = (id: string) => {
    setData((prevData) => {
      const newData = prevData.filter((d) => d.id !== id);
      regenerateOptions(newData);
      return newData;
    });
  };

  const handleUpdate = () => {
    if (service) {
      dispatch(
        updateDBSAvancedConfigurationsAsync({
          clusterId: service.id,
          engine: service.engine,
          data: dataObject,
        })
      ).then((action) => {
        if (
          action.type === "service/advanced-configurations/update/fulfilled"
        ) {
          dispatch(
            getDBSAvancedConfigurationsAsync({
              clusterId: service.id,
              engine: service.engine,
            })
          );
        }
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Database - advanced configuration
        </title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-1">
              <Typography.Title level={5}>
                Advanced configuration
              </Typography.Title>
            </div>
            <div className=""></div>
          </div>

          {(ACCsLoading || configurationsLoading) && (
            <Flex justify="center" items="center" className="!min-h-[300px]">
              <Loading borderSize={2} />
            </Flex>
          )}

          {!ACCsLoading && !configurationsLoading && (
            <div className="grid grid-cols-8 gap-5">
              <div className="mt-5 w-full flex flex-col gap-5 col-span-8 md:col-span-5 xl:col-span-4">
                {data.map((d, index) => {
                  const acc = ACCs.find((acc) => acc.name === d.name);
                  return (
                    <AdvancedConfigField
                      key={index}
                      id={d.id}
                      status={d.status}
                      description={acc?.description}
                      name={acc?.name}
                      type={acc?.type}
                      maximum={acc?.maximum}
                      minimum={acc?.minimum}
                      values={acc?.values}
                      value={d.value}
                      acticeKeyOptions={activeKeyOptions}
                      handleAddField={handleAddNewField}
                      handleUpdateFieldKey={handleUpdateFieldKey}
                      handleUpdateFieldValue={handleUpdateFieldValue}
                      handleRemoveField={handleRemoveField}
                    />
                  );
                })}
              </div>
              <div className="mt-5 w-full flex flex-col gap-5 col-span-8 md:col-span-3 xl:col-span-4">
                <div className=" sticky top-24">
                  <div className="w-full overflow-x-auto">
                    <JsonViewer value={dataObject} />
                  </div>
                  <div className="w-full flex justify-end mt-3">
                    <Button
                      uiType="primary"
                      loading={configurationsActionLoading}
                      onClick={handleUpdate}
                    >
                      Update advanced configuration
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const ragioGroupItems = [
  { label: "True", value: "true" },
  { label: "False", value: "false" },
];

const AdvancedConfigField: React.FC<
  Partial<DBS_ACC> & {
    id: string;
    value: string;
    status: AdvancedConfigFieldStatusType;
    acticeKeyOptions: SelectOption<string>[];
    handleAddField: (currentId: string) => void;
    handleUpdateFieldKey: (id: string, value?: string) => void;
    handleUpdateFieldValue: (id: string, value?: string) => void;
    handleRemoveField: (id: string) => void;
  }
> = ({
  id,
  status,
  name,
  value,
  acticeKeyOptions,
  description,
  type,
  maximum,
  minimum,
  values,
  handleAddField,
  handleUpdateFieldKey,
  handleUpdateFieldValue,
  handleRemoveField,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [validateError, setValidateError] = useState<string>();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const options = useMemo(() => {
    if (status === "default" || status === "value_selecting_close") {
      return [{ label: name || "", value: name || "" }];
    }
    return acticeKeyOptions;
  }, [acticeKeyOptions, name, status]);

  const keyValue = useMemo(() => {
    if (status === "default" || status === "value_selecting_close") {
      return options[0].value;
    }
    if (status === "key_selecting" || status === "value_selecting_open") {
      return options.find((o) => o.value === name)?.value;
    }
  }, [name, options, status]);

  const handleChangeValue = (value: string) => {
    setInputValue(value);
    //validate
    if (validateInputValue(value)) {
      handleUpdateFieldValue(id, value);
    }
  };

  const validateInputValue = (value: string): boolean => {
    if (type === "long" || type === "double") {
      const v = Number(value);
      if (minimum && v < minimum) {
        setValidateError(`value must be bigger than ${minimum}`);
        return false;
      }
      if (maximum && v > maximum) {
        setValidateError(`value must be smaller than ${maximum}`);
        return false;
      }
    }
    setValidateError(undefined);
    return true;
  };

  return (
    <div className="w-full h-full flex items-center gap-2 border text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white shadow">
      <div className="flex flex-col gap-5 flex-1 p-4  border-r">
        <Select
          label="Key"
          tooltip={{ content: description }}
          options={options}
          value={keyValue}
          disabled={status === "default" || status === "value_selecting_close"}
          onChange={(value) => handleUpdateFieldKey(id, value)}
          optionsClassName="!max-h-[300px]"
        />
        {(type === "string" || type === "double" || type === "long") && (
          <>
            {!values && (
              <Input
                label="Value"
                name={name}
                value={inputValue}
                onChange={(e: any) => handleChangeValue(e.target.value)}
                type={
                  type === "double" || type === "long" ? "number" : "string"
                }
                error={validateError}
              />
            )}
            {values && (
              <Select
                label="Value"
                options={values.map((v) => ({ label: v, value: v }))}
                onChange={(v) => handleChangeValue(v || "")}
                value={inputValue}
                error={validateError}
              />
            )}
          </>
        )}
        {type === "boolean" && (
          <div>
            <Typography.Text className="flex items-center gap-1 !text-sm whitespace-nowrap">
              Value
            </Typography.Text>
            <RadioGrouper
              items={ragioGroupItems}
              selected={ragioGroupItems.find((i) => i.value === inputValue)}
              setSelected={(s) => handleChangeValue(s.value)}
            />
          </div>
        )}
      </div>
      {status && status !== "default" && (
        <div className=" h-full flex items-center pr-2">
          {(status === "key_selecting" ||
            status === "value_selecting_open") && (
            <Button
              disabled={
                status === "key_selecting" || validateError !== undefined
              }
              onClick={() => handleAddField(id)}
              uiType="primary"
            >
              <PlusIcon className="w-4 h-4" />
            </Button>
          )}
          {status === "value_selecting_close" && (
            <Button uiType="dangerLight" onClick={() => handleRemoveField(id)}>
              <ArchiveIcon className="w-4 h-4" />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default DBSAdvancedConfigurationsTab;
