import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteDSSACLAsync,
  getDatabaseServiceAsync,
  getDSSACLsAsync,
  handleToggleDSSAclModal,
  selectDatabaseService,
  selectDSSAcls,
  selectDSSAclsActionLoading,
  selectDSSAclsLoading,
} from "../../../store/database/serviceSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Typography,
} from "djuno-design";
import HighlighterText from "../../general/HighlighterText";
import { useSearch } from "../../../providers/SearchProvider";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { DeleteModal } from "../../modals/QuestionModal";
import { Helmet } from "react-helmet";
import { ACLCreateModal } from "./ACLCreateModal";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import { DataStreamGeneralInformationUrl } from "../../../utils/urls";
import { ACL } from "../../../types/data-straming";

const ACLTab = () => {
  const [filteredACL, setFilteredACL] = useState<ACL[]>([]);
  const [deleteACL, setDeleteACL] = useState<ACL | null>(null);

  const service = useAppSelector(selectDatabaseService);
  const acls = useAppSelector(selectDSSAcls);
  const loading = useAppSelector(selectDSSAclsLoading);
  const aclsActionLoading = useAppSelector(selectDSSAclsActionLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().acl;
      if (!permissions.includes(service.engine)) {
        navigate(DataStreamGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDSSACLsAsync({
            engine: service.engine,
            clusterId: service.id,
          })
        );
      }
    }
  }, [dispatch, navigate, searchValue, service]);

  useEffect(() => {
    if (acls && acls.length > 0) {
      const lookedUpFiles = acls.filter((a) =>
        a.username?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredACL(lookedUpFiles);
    }
  }, [dispatch, searchValue, acls]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex items-center justify-between">
            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Typography.Title level={5} className="!mb-0"></Typography.Title>
            </div>
            <div className="flex items-center gap-2">
              <Button
                uiType="light"
                onClick={() => {
                  if (service) {
                    dispatch(
                      getDSSACLsAsync({
                        engine: service.engine,
                        clusterId: service.id,
                      })
                    );
                  }
                }}
                disabled={!service}
                className="group"
                tooltip={{ content: "Refresh" }}
              >
                <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
              <Button
                uiType="primary"
                onClick={() => dispatch(handleToggleDSSAclModal(true))}
                className="group"
              >
                Add a new entry
                <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
            </div>
          </div>

          <div className="mt-5 w-full database-table">
            <SimpleTable loading={loading} containerClassName="min-h-[240px]">
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="User" />
                  <SimpleTable.TH lable="Topic" />
                  <SimpleTable.TH lable="Permission" />
                  <SimpleTable.TH lable="" />
                </SimpleTable.Row>
              </SimpleTable.Head>
              <SimpleTable.Body>
                {filteredACL.map((a, i) => (
                  <SimpleTable.Row key={i}>
                    <SimpleTable.TD>
                      <div className="flex items-center flex-row justify-between space-x-3">
                        <Flex>
                          <HighlighterText
                            searchValue={searchValue}
                            textToHighlight={a.username}
                            className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                          />
                        </Flex>
                      </div>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {a.topic}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {a.permission}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD className="w-20 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-end gap-1">
                        <div className="w-8 flex justify-center items-center">
                          <Dropdown
                            anchor="bottom end"
                            itemsClassName="!w-48"
                            menu={[
                              {
                                key: "end",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <ArchiveIcon className="w-4" />
                                    Delete
                                  </div>
                                ),
                                danger: true,
                                onClick: (_, close) => {
                                  close();
                                  setDeleteACL(a);
                                },
                                // disabled: ,
                              },
                            ]}
                          >
                            <Button
                              uiType="icon"
                              uiSize="small"
                              className="!px-2"
                            >
                              <MoreIcon className="w-4 h-4" />
                            </Button>
                          </Dropdown>
                        </div>
                        {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                      </div>
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                ))}

                {acls.length === 0 && (
                  <SimpleTable.Row
                    withoutHoverStyle={true}
                    className="h-[200px]"
                  >
                    <SimpleTable.TD colSpan={10} className="!border-0">
                      <EmptyState text="You have not created any ACL yet" />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>
          </div>
          <ACLCreateModal />
          <DeleteModal
            title="Delete ACL"
            isOpen={deleteACL !== null}
            onClose={() => setDeleteACL(null)}
            description=""
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            onConfirm={() => {
              deleteACL &&
                service &&
                dispatch(
                  deleteDSSACLAsync({
                    clusterId: service.id,
                    engine: service.engine,
                    aclId: deleteACL.id,
                  })
                ).then((action) => {
                  if (action.type === "service/acls/delete/fulfilled") {
                    dispatch(
                      getDSSACLsAsync({
                        engine: service.engine,
                        clusterId: service.id,
                      })
                    );
                    setDeleteACL(null);
                  }
                });
            }}
            loading={aclsActionLoading}
            confirmString={deleteACL?.username}
          />
        </>
      )}
    </>
  );
};

export default ACLTab;
