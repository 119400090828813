import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../../types";
import {
  RepositoriesAccounts,
  RepositoriesState,
} from "../../../types/repositories";
import {
  getRepositoriesAccountsApi,
  saveInstallationApi,
} from "../../../apis/repositoriesAPI";
import { CustomErrorToast } from "../../../components/general/Toast";

const initAccountsData = {
  GithubAccessToken: null,
  GithubAccount: null,
  GitlabAccessToken: null,
  BitbucketAccessToken: null,
};

// const initGithubReposData = {
//   repositories: [],
//   repository_selection: "",
//   total_count: 0,
// };

const initialState: RepositoriesState = {
  accounts: initAccountsData,
  accountsLoading: false,

  // installation
  installationModal: false,
  installationId: null,
  saveInstallationLoading: false,

  //github
  githubReposData: null,
  githubReposLoading: false,
};

export const getRepositoriesAccountsAsync = createAsyncThunk<
  {
    tokens: RepositoriesAccounts | null;
  },
  undefined,
  IThunkRejectValue
>(
  "repositories/get-accounts",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getRepositoriesAccountsApi();
      const tokens = response.data.Result;

      return fulfillWithValue({ tokens });
    } catch (e) {
      if (isAxiosError(e))
        return rejectWithValue({ message: e.response?.data.message });
      return rejectWithValue({ message: "Bad Request" });
    }
  }
);

export const saveInstallationAsync = createAsyncThunk<
  boolean,
  { installationId: string; service: "github" },
  IThunkRejectValue
>(
  "repositories/installation",
  async ({ installationId, service }, { rejectWithValue }) => {
    try {
      await saveInstallationApi(service, installationId);
      return true;
    } catch (e) {
      if (isAxiosError(e))
        return rejectWithValue({ message: e.response?.data.message });
      return rejectWithValue({ message: "Bad Request" });
    }
  }
);

export const repositoriesSlice = createSlice({
  name: "repositories",
  initialState,
  reducers: {
    setInstallationData: (
      state,
      action: PayloadAction<{
        installationId?: string | null;
        installationModal?: boolean;
      }>
    ) => {
      if (typeof action.payload.installationId !== "undefined")
        state.installationId = action.payload.installationId;

      if (typeof action.payload.installationModal !== "undefined")
        state.installationModal = action.payload.installationModal;
    },

    setGithubReposLoading: (state, action: PayloadAction<boolean>) => {
      state.githubReposLoading = action.payload;
    },
    setGithubReposData: (state, action) => {
      state.githubReposData = action.payload;
      state.githubReposLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRepositoriesAccountsAsync.pending, (state) => {
        state.accountsLoading = true;
      })
      .addCase(getRepositoriesAccountsAsync.fulfilled, (state, action) => {
        const { tokens } = action.payload;
        if (tokens !== null) {
          state.accounts = tokens;
        } else {
          state.accounts = initAccountsData;
        }

        state.accountsLoading = false;
      })
      .addCase(getRepositoriesAccountsAsync.rejected, (state, { payload }) => {
        state.accountsLoading = false;
        state.accounts = initAccountsData;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload.message));
      });

    builder
      .addCase(saveInstallationAsync.pending, (state) => {
        state.saveInstallationLoading = true;
      })
      .addCase(saveInstallationAsync.fulfilled, (state, action) => {
        state.saveInstallationLoading = false;
      })
      .addCase(saveInstallationAsync.rejected, (state, { payload }) => {
        state.saveInstallationLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload.message));
      });
  },
});

export const selectRepoAccounts = (state: RootState) =>
  state.repositories.accounts;
export const selectRepoAccountsLoading = (state: RootState) =>
  state.repositories.accountsLoading;

export const selectIsOpenInstallationModal = (state: RootState) =>
  state.repositories.installationModal;
export const selectInstallationId = (state: RootState) =>
  state.repositories.installationId;
export const selectSaveInstallationLoading = (state: RootState) =>
  state.repositories.saveInstallationLoading;

export const selectGithubReposData = (state: RootState) =>
  state.repositories.githubReposData;
export const selectGithubReposLoading = (state: RootState) =>
  state.repositories.githubReposLoading;

export const {
  setInstallationData,
  setGithubReposLoading,
  setGithubReposData,
} = repositoriesSlice.actions;
export default repositoriesSlice.reducer;
