import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { jwtEnvAxios } from "../../../../../apis";
import { isAxiosError } from "axios";

export interface IGitFile {
  path: string;
  index: string;
  working_dir: string;
}
interface IGitStatus {
  not_added: string[];
  conflicted: string[];
  created: string[];
  deleted: string[];
  modified: string[];
  renamed: string[];
  files: Array<IGitFile>;
  staged: string[];
  ahead: number;
  behind: number;
  current: string;
  tracking: null | string;
  detached: boolean;
}

interface IGitConfig {
  name: string;
  email: string;
}

export type GitFlowType =
  | "Commit"
  | "AddAndCommit"
  | "Push"
  | "CommitAndPush"
  | "AddAndCommitAndPush"
  | "CommitAndSync"
  | "AddAndCommitAndSync"
  | "Sync";

const remoteName = "origin";
const branchName = "master";

type AddRemotePayload = {
  username: string;
  token: string;
  repoUrl: string;
};

interface DappGitContextType {
  gitHasInit: boolean;
  gitStatus: IGitStatus | null;
  gitConfig: IGitConfig | null;
  gitHasRemote: boolean;

  gitInitLoading: boolean;
  handleGitInit: () => Promise<void>;

  gitStatusLoading: boolean;
  handleGitStatus: (hard?: boolean) => Promise<void>;

  handleGitLog: () => Promise<void>;

  gitAddLoading: boolean;
  handleGitAdd: (files: string | string[]) => Promise<void>;

  gitCommitLoading: boolean;
  handleGitCommit: (message: string) => Promise<void>;

  gitGetRemoteLoading: boolean;
  handleGitGetRemote: () => Promise<void>;

  gitAddRemoteLoading: boolean;
  handleGitAddRemote: (data: AddRemotePayload) => Promise<void>;

  gitGetConfigLoading: boolean;
  handleGitGetConfig: () => Promise<void>;

  gitAddConfigLoading: boolean;
  handleGitAddConfig: (data: IGitConfig) => Promise<void>;

  diffLoading: boolean;
  handleGitDiff: () => Promise<void>;

  gitPushLoading: boolean;
  handleGitPush: () => Promise<void>;

  gitPullLoading: boolean;
  handleGitPull: () => Promise<void>;

  stagedChangedFiles: Array<IGitFile>;
  changedFiles: Array<IGitFile>;

  clearDappStudioGit: () => void;
}

const DappGitContext = createContext<DappGitContextType | null>(null);

export const DappGitProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [gitHasInit, setGitHasInit] = useState(false);
  const [gitStatus, setGitStatus] = useState<IGitStatus | null>(null);
  const [gitConfig, setGitConfig] = useState<IGitConfig | null>(null);
  const [gitHasRemote, setGitHasRemote] = useState(false);

  const [gitStatusLoading, setGitStatusLoading] = useState(false);
  const handleGitStatus = useCallback(
    async (hard: boolean = false) => {
      if (!hard && !gitHasInit) return;
      try {
        setGitStatusLoading(true);
        const response = await jwtEnvAxios("dapp").post(`/git/status`);
        setGitHasInit(true);
        const status =
          process.env.REACT_APP_USE_LOCAL_DAPP === "true"
            ? response.data.status
            : response.data.Result.status;
        setGitStatus(status);
      } catch (e) {
        if (isAxiosError(e)) {
          if (e.response?.status === 400) {
            setGitHasInit(false);
          }
        }
      } finally {
        setGitStatusLoading(false);
      }
    },
    [gitHasInit]
  );

  const [gitInitLoading, setGitInitLoading] = useState(false);
  const handleGitInit = useCallback(async () => {
    try {
      setGitInitLoading(true);
      await jwtEnvAxios("dapp").post(`/git/init`);
      setGitHasInit(true);
      handleGitStatus(true);
    } catch (e) {
      console.log("init error:", e);
    } finally {
      setGitInitLoading(false);
    }
  }, [handleGitStatus]);

  const [gitAddLoading, setGitAddLoading] = useState(false);
  const handleGitAdd = useCallback(async (files: string | string[]) => {
    try {
      setGitAddLoading(true);
      await jwtEnvAxios("dapp").post(`/git/add`, {
        files,
      });
    } catch (e) {
    } finally {
      setGitAddLoading(false);
    }
  }, []);

  const [gitGetConfigLoading, setGitGetConfigLoading] = useState(false);
  const handleGitGetConfig = useCallback(async () => {
    try {
      setGitGetConfigLoading(true);
      const response = await jwtEnvAxios("dapp").get(`/git/config`);
      const configResponse =
        process.env.REACT_APP_USE_LOCAL_DAPP === "true"
          ? response.data
          : response.data.Result;
      const config = configResponse as IGitConfig;
      setGitConfig(config);
    } catch (e) {
    } finally {
      setGitGetConfigLoading(false);
    }
  }, []);

  const [gitAddConfigLoading, setGitAddConfigLoading] = useState(false);
  const handleGitAddConfig = useCallback(async (data: IGitConfig) => {
    try {
      setGitAddConfigLoading(true);
      await jwtEnvAxios("dapp").post(`/git/config`, data);
      setGitConfig(data);
    } catch (e) {
    } finally {
      setGitAddConfigLoading(false);
    }
  }, []);

  const [gitCommitLoading, setGitCommitLoading] = useState(false);
  const handleGitCommit = useCallback(async (message: string) => {
    try {
      setGitCommitLoading(true);
      await jwtEnvAxios("dapp").post(`/git/commit`, {
        message,
      });
    } catch (e) {
    } finally {
      setGitCommitLoading(false);
    }
  }, []);

  const [gitGetRemoteLoading, setGitGetRemoteLoading] = useState(false);
  const handleGitGetRemote = useCallback(async () => {
    try {
      setGitGetRemoteLoading(true);
      const response = await jwtEnvAxios("dapp").get(`/git/remotes`);
      const remotesResponse =
        process.env.REACT_APP_USE_LOCAL_DAPP === "true"
          ? response.data.remotes
          : response.data.Result.remotes;
      const remotes = remotesResponse as Array<{ name: string }>;
      setGitHasRemote(!!remotes.find((r) => r.name === remoteName));
    } catch (e) {
    } finally {
      setGitGetRemoteLoading(false);
    }
  }, []);

  const [gitAddRemoteLoading, setGitAddRemoteLoading] = useState(false);
  const handleGitAddRemote = useCallback(
    async ({ username, token, repoUrl }: AddRemotePayload) => {
      try {
        setGitAddRemoteLoading(true);
        await jwtEnvAxios("dapp").post(`/git/add-remote`, {
          remoteName,
          username,
          token,
          repoUrl,
        });
        setGitHasRemote(true);
        handleGitStatus();
      } catch (e) {
      } finally {
        setGitAddRemoteLoading(false);
      }
    },
    [handleGitStatus]
  );

  const [diffLoading, setDiffLoading] = useState(false);
  const handleGitDiff = useCallback(async () => {
    try {
      setDiffLoading(true);
      await jwtEnvAxios("dapp").post(`/git/commit-differences`, {
        remote: remoteName,
        branch: branchName,
      });
    } catch (e) {
    } finally {
      setDiffLoading(false);
    }
  }, []);

  const [gitPushLoading, setGitPushLoading] = useState(false);
  const handleGitPush = useCallback(async () => {
    try {
      setGitPushLoading(true);
      await jwtEnvAxios("dapp").post(`/git/push`, {
        remote: remoteName,
        branch: branchName,
      });
    } catch (e) {
    } finally {
      setGitPushLoading(false);
    }
  }, []);

  const [gitPullLoading, setGitPullLoading] = useState(false);
  const handleGitPull = useCallback(async () => {
    try {
      setGitPullLoading(true);
      await jwtEnvAxios("dapp").post(`/git/pull`, {
        remote: remoteName,
        branch: branchName,
      });
    } catch (e) {
    } finally {
      setGitPullLoading(false);
    }
  }, []);

  const handleGitLog = useCallback(async () => {
    try {
      await jwtEnvAxios("dapp").post(`/git/log`);
    } catch {
    } finally {
    }
  }, []);

  // calc staged changes files
  const stagedChangedFiles = useMemo(() => {
    const files: Array<IGitFile> = [];
    if (!gitStatus || gitStatus.files.length === 0) return [];

    for (const file of gitStatus.files) {
      if (
        file.index === "A" ||
        (file.working_dir === "M" && file.index !== " ")
      ) {
        files.push(file);
      }
    }
    return files;
  }, [gitStatus]);

  // calc changed files
  const changedFiles = useMemo(() => {
    const files: Array<IGitFile> = [];
    if (!gitStatus || gitStatus.files.length === 0) return [];

    for (const file of gitStatus.files) {
      if (file.index === "?" || file.working_dir === "M") files.push(file);
    }
    return files;
  }, [gitStatus]);

  const clearDappStudioGit = useCallback(() => {
    setGitHasInit(false);
    setGitStatus(null);
    setGitConfig(null);
    setGitHasRemote(false);
  }, []);

  return (
    <DappGitContext.Provider
      value={{
        gitHasInit,
        gitStatus,
        gitConfig,
        gitHasRemote,

        gitInitLoading,
        handleGitInit,

        gitStatusLoading,
        handleGitStatus,

        handleGitLog,

        gitAddLoading,
        handleGitAdd,

        gitCommitLoading,
        handleGitCommit,

        gitGetRemoteLoading,
        handleGitGetRemote,

        gitAddRemoteLoading,
        handleGitAddRemote,

        gitGetConfigLoading,
        handleGitGetConfig,

        gitAddConfigLoading,
        handleGitAddConfig,

        diffLoading,
        handleGitDiff,

        gitPushLoading,
        handleGitPush,

        gitPullLoading,
        handleGitPull,

        stagedChangedFiles,
        changedFiles,

        clearDappStudioGit,
      }}
    >
      {children}
    </DappGitContext.Provider>
  );
};

export const useDappGit = () => {
  const context = useContext(DappGitContext);
  if (!context)
    throw new Error("useDappGit must be used within DappGitProvider");
  return context;
};
