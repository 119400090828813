import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import toast from "react-hot-toast";
import {
  WalletApiRequest,
  WalletApiOverviewType,
} from "../../types/wallet-api";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  getWalletApiRequestsApi,
  getWalletApiUsageApi,
} from "../../apis/walletApiAPI";

const initialState: WalletApiOverviewType = {
  usage: 0,
  usageLoading: false,
  requests: [],
  requestsLoading: false,
};

export const getWalletApiUsageAysnc = createAsyncThunk<
  number,
  undefined,
  IThunkRejectValue
>("wallet-api/usage", async (_, { fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await getWalletApiUsageApi();
    const usage = response.data.Result;
    return fulfillWithValue(usage);
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getWalletApiRequestsAysnc = createAsyncThunk<
  Array<WalletApiRequest>,
  undefined,
  IThunkRejectValue
>("wallet-api/requests", async (_, { fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await getWalletApiRequestsApi();
    const requests = response.data.Result;
    return fulfillWithValue(requests);
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

const walletApiOverviewSlice = createSlice({
  name: "walletApiOverview",
  initialState: initialState,
  reducers: {
    clearOverviewApiKeys(state) {
      state.usage = initialState.usage;
      state.usageLoading = initialState.usageLoading;
      state.requests = initialState.requests;
      state.requestsLoading = initialState.requestsLoading;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWalletApiUsageAysnc.pending, (state) => {
        state.usageLoading = true;
      })
      .addCase(getWalletApiUsageAysnc.fulfilled, (state, action) => {
        state.usage = action.payload;
        state.usageLoading = false;
      })
      .addCase(getWalletApiUsageAysnc.rejected, (state, { payload }) => {
        state.usageLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getWalletApiRequestsAysnc.pending, (state) => {
        state.requestsLoading = true;
      })
      .addCase(getWalletApiRequestsAysnc.fulfilled, (state, action) => {
        state.requests = action.payload;
        state.requestsLoading = false;
      })
      .addCase(getWalletApiRequestsAysnc.rejected, (state, { payload }) => {
        state.requestsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectWalletApiUsage = (state: RootState) =>
  state.walletApiApiOverview.usage;
export const selectWalletApiUsageLoading = (state: RootState) =>
  state.walletApiApiOverview.usageLoading;

export const selectWalletApiRequests = (state: RootState) =>
  state.walletApiApiOverview.requests;
export const selectWalletApiRequestsLoading = (state: RootState) =>
  state.walletApiApiOverview.requestsLoading;

export const { clearOverviewApiKeys } = walletApiOverviewSlice.actions;

export default walletApiOverviewSlice.reducer;
