import { useCallback, useEffect, useState } from "react";
import UserDropdown from "../dropdowns/UserDropdown";
import { EnvironmentButton } from "../environments/EnvironmentSelectModal";
import SearchInput from "../general/SearchInput";
import {
  SearchActionType,
  useSearchDispatch,
} from "../../providers/SearchProvider";
import { useLocation } from "react-router-dom";
import useWindowOnClick from "../../hooks/useWindowOnClick";
import { ReactComponent as SearchIcon } from "./../../assets/icons/search.svg";
import NotifDropdown from "../dropdowns/NotifDropdown";
import { cn, ThemeChanger } from "djuno-design";

const Header = () => {
  const location = useLocation();
  const dispatch = useSearchDispatch();

  const [openMobileSearch, setOpenMobileSearch] = useState(false);

  useEffect(() => {
    dispatch({ type: SearchActionType.CLEAR, payload: "" });
  }, [dispatch, location.pathname]);

  useWindowOnClick(() => {
    setOpenMobileSearch(false);
  }, []);

  const handleClickMobileSearchBtn: React.MouseEventHandler<HTMLDivElement> =
    useCallback((e) => {
      e.stopPropagation();
      setOpenMobileSearch(true);
    }, []);

  return (
    <div className="w-full flex-col lg:max-w-7xl mx-auto">
      <div className="w-full  flex items-center justify-end lg:justify-between">
        <div
          className={cn(
            "flex-1 w-full bg-white dark:bg-[rgb(22,26,29)] items-center h-14 absolute lg:static flex  -top-14 bottom-0 left-0 right-0 z-50 transition-all lg:transition-none",
            {
              "!top-0": openMobileSearch,
            }
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full lg:w-96">
            <SearchInput />
          </div>
        </div>
        <div className="flex items-center gap-2 md:gap-4 text-slate-500 dark:text-slate-300">
          <EnvironmentButton />
          <NotifDropdown />
          <ThemeChanger anchor="top end" />
          <div className="h-[35px] w-[2px] bg-slate-300 dark:bg-[rgb(34,39,43)] hidden md:block " />
          <div className="flex items-center cursor-pointer">
            <UserDropdown />
          </div>
          <div onClick={handleClickMobileSearchBtn}>
            <SearchIcon className="w-6 aspect-square lg:hidden" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
