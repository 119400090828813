import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  DatabaseServiceGeneralInformationUrl,
  DatabaseUsersUrl,
  DatabaseAuthorisedIPsUrl,
  DatabaseLogsUrl,
  DatabaseServicesUrl,
  DatabaseMetricsUrl,
  DatabaseDatabasesUrl,
  DatabaseQueryStatisticsUrl,
  DatabaseCurrentQueriesUrl,
  DatabaseServiceIntegrationsUrl,
  DatabaseBackupsUrl,
  DatabaseServiceAdvancedConfigurationsUrl,
  DatabasePoolsUrl,
  DatabaseServiceNamespacesUrl,
  DataStreamServicesUrl,
  DataStreamConnectorsUrl,
  DataStreamReplicationFlowsUrl,
  DataStreamAclUrl,
  DataStreamTopicsUrl,
  DataStreamGeneralInformationUrl,
  DataStreamUsersUrl,
  DataStreamAuthorisedIPsUrl,
  DataStreamLogsUrl,
  DataStreamMetricsUrl,
  DataStreamServiceIntegrationsUrl,
  DataStreamServiceAdvancedConfigurationsUrl,
} from "../../utils/urls";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Helmet } from "react-helmet";
import { selectLoading } from "../../store/rpc/servicesSlice";
import {
  getDatabaseServiceAsync,
  handleClearServiceSlice,
  selectDatabaseService,
} from "../../store/database/serviceSlice";
import {
  Skeleton,
  TabOption,
  TabOptions,
  Tabs,
  Typography,
} from "djuno-design";
import {
  DBSCategory,
  DBSEngineType,
  DatabaseService,
} from "../../types/database";
import { ReactComponent as UsersIcon } from "./../../assets/icons/users.svg";
import { ReactComponent as BeatIcon } from "./../../assets/icons/beat.svg";
import { ReactComponent as MetricsIcon } from "./../../assets/icons/adjustments-vertical.svg";
import { ReactComponent as CircleStackIcon } from "./../../assets/icons/circle-stack.svg";
import { ReactComponent as NoticeIcon } from "./../../assets/icons/logs/notice.svg";
import { ReactComponent as QueryIcon } from "./../../assets/icons/presentation-chart-line.svg";
import { ReactComponent as IPIcon } from "./../../assets/icons/ip.svg";
import { ReactComponent as BackupIcon } from "./../../assets/icons/arrow-up-on-square.svg";
import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { ReactComponent as PoolsIcon } from "./../../assets/icons/archive-box2.svg";
import { ReactComponent as NamespaceIcon } from "./../../assets/icons/bars-2.svg";

export const generateServicePermissions = () => {
  const permissions: Record<string, DBSEngineType[] | "*"> = {
    generalInformation: "*",
    users: [
      "mongodb",
      "mysql",
      "postgresql",
      "redis",
      "cassandra",
      "m3db",
      "kafka",
      "kafkaConnect",
    ],
    acl: ["kafka"],
    databases: ["mysql", "postgresql"],
    backups: ["mongodb", "mysql", "postgresql", "redis", "cassandra", "m3db"],
    allowedIps: [
      "mongodb",
      "mysql",
      "postgresql",
      "redis",
      "cassandra",
      "m3db",
      "kafka",
      "kafkaConnect",
    ],
    queryStatistics: ["mysql", "postgresql"],
    currentQueries: ["mysql", "mongodb", "postgresql"],
    pools: ["postgresql"],
    logs: [
      "mongodb",
      "mysql",
      "postgresql",
      "redis",
      "cassandra",
      "m3db",
      "m3aggregator",
      "kafka",
      "kafkaMirrorMaker",
      "kafkaConnect",
    ],
    metrics: [
      "mongodb",
      "mysql",
      "postgresql",
      "redis",
      "cassandra",
      "m3db",
      "m3aggregator",
      "kafka",
      "kafkaMirrorMaker",
      "kafkaConnect",
    ],
    topics: ["kafka"],
    integrations: [
      "mysql",
      "postgresql",
      "redis",
      "cassandra",
      "m3db",
      "m3aggregator",
      "kafka",
      "kafkaMirrorMaker",
      "kafkaConnect",
    ],
    replicationFlows: ["kafkaMirrorMaker"],
    connectors: ["kafkaConnect"],
    advancedConfigurations: [
      "mysql",
      "postgresql",
      "redis",
      "cassandra",
      "kafka",
      "kafkaConnect",
    ],
    namespaces: ["m3db"],
  };

  return permissions;
};

export const generateServiceRoutes = (
  service: DatabaseService,
  section: DBSCategory
) => {
  const options: TabOptions = [];

  const generalInformationOption = {
    label: (
      <div className="flex items-center gap-1">
        <NoticeIcon className="w-3" />
        General information
      </div>
    ),
    url:
      section === "ds"
        ? DataStreamGeneralInformationUrl(service.id)
        : DatabaseServiceGeneralInformationUrl(service.id),
    testId: "1th-tab",
  };

  const usersOption: TabOption = {
    label: (
      <div className="flex items-center gap-1">
        <UsersIcon className="w-3" />
        Users
      </div>
    ),
    url:
      section === "ds"
        ? DataStreamUsersUrl(service.id)
        : DatabaseUsersUrl(service.id),
    disabled: service.status !== "READY",
    testId: "2th-tab",
  };

  const aclOption: TabOption = {
    label: (
      <div className="flex items-center gap-1">
        <UsersIcon className="w-3" />
        ACL
      </div>
    ),
    url: DataStreamAclUrl(service.id),
    disabled: service.status !== "READY",
    testId: "aclOption",
  };

  const databasesOption = {
    label: (
      <div className="flex items-center gap-1">
        <CircleStackIcon className="w-3" />
        Databases
      </div>
    ),
    url: DatabaseDatabasesUrl(service.id),
    disabled: service.status !== "READY",
    testId: "3th-tab",
  };

  const authorisedIPsOption = {
    label: (
      <div className="flex items-center gap-1">
        <IPIcon className="w-3" />
        Authorised IPs
      </div>
    ),
    url:
      section === "ds"
        ? DataStreamAuthorisedIPsUrl(service.id)
        : DatabaseAuthorisedIPsUrl(service.id),
    disabled: service.status !== "READY",
    testId: "4th-tab",
  };

  const querryStatisticsOption = {
    label: (
      <div className="flex items-center gap-1">
        <QueryIcon className="w-3" />
        Query Statistics
      </div>
    ),
    url: DatabaseQueryStatisticsUrl(service.id),
    disabled: service.status !== "READY",
    testId: "5th-tab",
  };

  const queriesInProgressOption = {
    label: (
      <div className="flex items-center gap-1">
        <QueryIcon className="w-3" />
        Queries in progress
      </div>
    ),
    url: DatabaseCurrentQueriesUrl(service.id),
    disabled: service.status !== "READY",
    testId: "6th-tab",
  };

  const poolsOption = {
    label: (
      <div className="flex items-center gap-1">
        <PoolsIcon className="w-3" />
        Pools
      </div>
    ),
    url: DatabasePoolsUrl(service.id),
    disabled: service.status !== "READY",
    testId: "7th-tab",
  };

  const logsOption = {
    label: (
      <div className="flex items-center gap-1">
        <BeatIcon className="w-3" />
        Logs
      </div>
    ),
    url:
      section === "ds"
        ? DataStreamLogsUrl(service.id)
        : DatabaseLogsUrl(service.id),
    disabled: service.status !== "READY",
    testId: "8th-tab",
  };

  const metricsOption = {
    label: (
      <div className="flex items-center gap-1">
        <MetricsIcon className="w-3" />
        Metrics
      </div>
    ),
    url:
      section === "ds"
        ? DataStreamMetricsUrl(service.id)
        : DatabaseMetricsUrl(service.id),
    disabled: service.status !== "READY",
    testId: "9th-tab",
  };

  const topicsOption = {
    label: (
      <div className="flex items-center gap-1">
        <MetricsIcon className="w-3" />
        Topics
      </div>
    ),
    url: DataStreamTopicsUrl(service.id),
    disabled: service.status !== "READY",
    testId: "topicsOption",
  };

  const integrationsOption = {
    label: (
      <div className="flex items-center gap-1">
        <MetricsIcon className="w-3" />
        Service integration
      </div>
    ),
    url:
      section === "ds"
        ? DataStreamServiceIntegrationsUrl(service.id)
        : DatabaseServiceIntegrationsUrl(service.id),
    disabled: service.status !== "READY",
    testId: "10th-tab",
  };

  const replicationFlowsOption = {
    label: (
      <div className="flex items-center gap-1">
        <MetricsIcon className="w-3" />
        Replication Flows
      </div>
    ),
    url: DataStreamReplicationFlowsUrl(service.id),
    disabled: service.status !== "READY",
    testId: "replicationFlowsOption",
  };

  const connectorsOption = {
    label: (
      <div className="flex items-center gap-1">
        <MetricsIcon className="w-3" />
        Connectors
      </div>
    ),
    url: DataStreamConnectorsUrl(service.id),
    disabled: service.status !== "READY",
    testId: "connectorsOption",
  };

  const backupsOption = {
    label: (
      <div className="flex items-center gap-1">
        <BackupIcon className="w-3" />
        Backups
      </div>
    ),
    url: DatabaseBackupsUrl(service.id),
    disabled: service.status !== "READY",
    testId: "11th-tab",
  };

  const advancedOption = {
    label: (
      <div className="flex items-center gap-1">
        <SettingIcon className="w-3" />
        Advanced configuration
      </div>
    ),
    url:
      section === "ds"
        ? DataStreamServiceAdvancedConfigurationsUrl(service.id)
        : DatabaseServiceAdvancedConfigurationsUrl(service.id),
    disabled: service.status !== "READY",
    testId: "12th-tab",
  };

  const namespacesOption = {
    label: (
      <div className="flex items-center gap-1">
        <NamespaceIcon className="w-3" />
        Namespaces
      </div>
    ),
    url: DatabaseServiceNamespacesUrl(service.id),
    disabled: service.status !== "READY",
    testId: "13th-tab",
  };

  options.push(generalInformationOption);

  if (
    service.engine === "mongodb" ||
    service.engine === "mysql" ||
    service.engine === "postgresql" ||
    service.engine === "redis" ||
    service.engine === "cassandra" ||
    service.engine === "m3db" ||
    service.engine === "kafka" ||
    service.engine === "kafkaConnect"
  ) {
    options.push(usersOption);
  }

  if (service.engine === "kafka") {
    options.push(aclOption);
  }

  if (service.engine === "mysql" || service.engine === "postgresql") {
    options.push(databasesOption);
  }

  if (service.capabilities.backups && service.engine !== "kafkaConnect") {
    options.push(backupsOption);
  }

  if (
    service.engine === "mongodb" ||
    service.engine === "mysql" ||
    service.engine === "postgresql" ||
    service.engine === "redis" ||
    service.engine === "cassandra" ||
    service.engine === "m3db" ||
    service.engine === "kafka" ||
    service.engine === "kafkaConnect"
  ) {
    options.push(authorisedIPsOption);
  }

  if (service.engine === "mysql" || service.engine === "postgresql") {
    options.push(querryStatisticsOption);
  }

  if (service.engine === "mysql" || service.engine === "postgresql") {
    options.push(queriesInProgressOption);
  }

  if (service.engine === "postgresql") {
    options.push(poolsOption);
  }

  if (
    service.engine === "mongodb" ||
    service.engine === "mysql" ||
    service.engine === "postgresql" ||
    service.engine === "redis" ||
    service.engine === "cassandra" ||
    service.engine === "m3db" ||
    service.engine === "m3aggregator" ||
    service.engine === "kafka" ||
    service.engine === "kafkaMirrorMaker" ||
    service.engine === "kafkaConnect"
  ) {
    options.push(logsOption);
  }

  if (
    service.engine === "mongodb" ||
    service.engine === "mysql" ||
    service.engine === "postgresql" ||
    service.engine === "redis" ||
    service.engine === "cassandra" ||
    service.engine === "m3db" ||
    service.engine === "m3aggregator" ||
    service.engine === "kafka" ||
    service.engine === "kafkaMirrorMaker" ||
    service.engine === "kafkaConnect"
  ) {
    options.push(metricsOption);
  }

  if (service.engine === "kafka") {
    options.push(topicsOption);
  }

  if (
    service.engine === "mysql" ||
    service.engine === "postgresql" ||
    service.engine === "redis" ||
    service.engine === "cassandra" ||
    service.engine === "m3db" ||
    service.engine === "m3aggregator" ||
    service.engine === "kafka" ||
    service.engine === "kafkaMirrorMaker" ||
    service.engine === "kafkaConnect"
  ) {
    options.push(integrationsOption);
  }

  if (service.engine === "kafkaMirrorMaker") {
    options.push(replicationFlowsOption);
  }

  if (service.engine === "kafkaConnect") {
    options.push(connectorsOption);
  }

  if (
    service.engine === "mysql" ||
    service.engine === "postgresql" ||
    service.engine === "redis" ||
    service.engine === "cassandra" ||
    service.engine === "kafka" ||
    service.engine === "kafkaConnect"
  ) {
    options.push(advancedOption);
  }

  if (service.engine === "m3db") {
    options.push(namespacesOption);
  }

  return options;
};

const ServicePage: React.FC<{ section: DBSCategory }> = ({ section }) => {
  const { databaseId } = useParams();
  const service = useAppSelector(selectDatabaseService);
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const DataservicePageMenus = useMemo(() => {
    if (service) {
      return generateServiceRoutes(service, section);
    }
    return [];
  }, [service, section]);

  useEffect(() => {
    if (databaseId) {
      dispatch(getDatabaseServiceAsync({ id: databaseId }));
    }

    return () => {
      dispatch(handleClearServiceSlice());
    };
  }, [dispatch, databaseId]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (service && ["UPDATING", "CREATING"].includes(service.status)) {
      interval = setInterval(() => {
        dispatch(
          getDatabaseServiceAsync({
            id: service.id,
            withoutLoading: true,
          })
        );
      }, 20000);
    } else {
      if (interval) clearInterval(interval);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, service]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Database (
          {service ? service.description : ""})
        </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-16">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <div className="flex items-center gap-2">
            <RightArrow
              onClick={() => {
                if (section === "ds") {
                  navigate(DataStreamServicesUrl);
                } else {
                  navigate(DatabaseServicesUrl);
                }
              }}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            {service && !loading && (
              <>
                <div className="flex flex-col">
                  <Typography.Title level={5} className="!mb-0">
                    {service.description}
                  </Typography.Title>
                </div>
              </>
            )}
            {loading && (
              <>
                <Skeleton
                  shape="rectangle"
                  style={{ width: 200, height: 30 }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="px-6 mt-5">
        <div className="h-11">
          <Tabs options={DataservicePageMenus} />
        </div>
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default ServicePage;
