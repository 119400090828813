import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import {
  BlockEventDestination,
  BlockEventDestinationsState,
  DestinationPayloadType,
  DestinationWebhookType,
} from "../../types/block-events";
import {
  createDestinationsApi,
  deleteDestinationApi,
  getDestinationApi,
  getDestinationsApi,
  getDestinationsPayloadTypesApi,
  getDestinationsWebhookTypesApi,
  updateDestinationsApi,
} from "../../apis/blockEventAPI";
import toast from "react-hot-toast";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: BlockEventDestinationsState = {
  destinations: [],
  loading: false,
  selectedDestination: null,
  showEditor: false,
  actionLoading: false,

  //single page
  destination: null,
  destinationLoading: false,

  //settings
  webhookTypes: [],
  webhookTypesLoading: false,
  payloadTypes: [],
  payloadTypesLoading: false,
};

export const getDestinationsAsync = createAsyncThunk<
  { destinations: BlockEventDestination[] },
  undefined,
  IThunkRejectValue
>(
  "block-event/destinations",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getDestinationsApi();

      const { Result } = response.data;
      const destinations = Result || [];

      return fulfillWithValue({ destinations });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDestinationAsync = createAsyncThunk<
  { destination: BlockEventDestination },
  string,
  IThunkRejectValue
>(
  "block-event/destination",
  async (destId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getDestinationApi(destId);

      const { Result } = response.data;
      const destinations = Result || [];

      return fulfillWithValue({ destination: destinations[0] });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createDestinationAsync = createAsyncThunk<
  any,
  { data: any },
  IThunkRejectValue
>("block-event/destinations/create", async ({ data }, { rejectWithValue }) => {
  try {
    const response = await createDestinationsApi(data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateDestinationAsync = createAsyncThunk<
  any,
  { destId: string; data: any },
  IThunkRejectValue
>(
  "block-event/destinations/update",
  async ({ destId, data }, { rejectWithValue }) => {
    try {
      const response = await updateDestinationsApi(destId, data);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteDestinationAsync = createAsyncThunk<
  any,
  { destId: string },
  IThunkRejectValue
>(
  "block-event/destinations/delete",
  async ({ destId }, { rejectWithValue }) => {
    try {
      const res = await deleteDestinationApi(destId);
      return res.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDestinationWebhookTypesAsync = createAsyncThunk<
  { webhookTypes: DestinationWebhookType[] },
  undefined,
  IThunkRejectValue
>(
  "block-event/destinations/webhook-types",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getDestinationsWebhookTypesApi();

      const { Result } = response.data;
      const webhookTypes = Result || [];

      return fulfillWithValue({ webhookTypes });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDestinationPayloadTypesAsync = createAsyncThunk<
  { payloadTypes: DestinationPayloadType[] },
  undefined,
  IThunkRejectValue
>(
  "block-event/destinations/payload-types",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getDestinationsPayloadTypesApi();

      const { Result } = response.data;
      const payloadTypes = Result || [];

      return fulfillWithValue({ payloadTypes });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const blockEventDestinationsSlice = createSlice({
  name: "blockEventDestinations",
  initialState,
  reducers: {
    handleShowDestinationEditor: (
      state,
      action: PayloadAction<{ destination?: BlockEventDestination }>
    ) => {
      state.showEditor = true;
      if (action.payload.destination) {
        state.selectedDestination = action.payload.destination;
      }
    },
    handleHideDestinationEditor: (state) => {
      state.selectedDestination = null;
      state.showEditor = false;
    },
    handleSetDestination: (
      state,
      action: PayloadAction<BlockEventDestination | null>
    ) => {
      state.destination = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDestinationsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDestinationsAsync.fulfilled, (state, action) => {
        const { destinations } = action.payload;

        state.destinations = destinations;
        state.loading = false;
      })
      .addCase(getDestinationsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getDestinationAsync.pending, (state) => {
        state.destinationLoading = true;
      })
      .addCase(getDestinationAsync.fulfilled, (state, action) => {
        const { destination } = action.payload;

        state.destination = destination;
        state.destinationLoading = false;
      })
      .addCase(getDestinationAsync.rejected, (state, { payload }) => {
        state.destinationLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createDestinationAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createDestinationAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createDestinationAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateDestinationAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateDestinationAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateDestinationAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(deleteDestinationAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteDestinationAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteDestinationAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getDestinationWebhookTypesAsync.pending, (state) => {
        state.webhookTypesLoading = true;
      })
      .addCase(getDestinationWebhookTypesAsync.fulfilled, (state, action) => {
        state.webhookTypesLoading = false;
        state.webhookTypes = action.payload.webhookTypes;
      })
      .addCase(
        getDestinationWebhookTypesAsync.rejected,
        (state, { payload }) => {
          state.webhookTypesLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(getDestinationPayloadTypesAsync.pending, (state) => {
        state.payloadTypesLoading = true;
      })
      .addCase(getDestinationPayloadTypesAsync.fulfilled, (state, action) => {
        state.payloadTypesLoading = false;
        state.payloadTypes = action.payload.payloadTypes;
      })
      .addCase(
        getDestinationPayloadTypesAsync.rejected,
        (state, { payload }) => {
          state.payloadTypesLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );
  },
});

export const selectDestinations = (state: RootState) =>
  state.blockEventDestinations.destinations;
export const selectDestinationsLoading = (state: RootState) =>
  state.blockEventDestinations.loading;

export const selectSelectedDestination = (state: RootState) =>
  state.blockEventDestinations.selectedDestination;

export const selectShowDestinationEditor = (state: RootState) =>
  state.blockEventDestinations.showEditor;

export const selectDestinationsActionLoading = (state: RootState) =>
  state.blockEventDestinations.actionLoading;

export const selectDestination = (state: RootState) =>
  state.blockEventDestinations.destination;
export const selectDestinationLoading = (state: RootState) =>
  state.blockEventDestinations.destinationLoading;

export const selectDestinationsWebhookTypes = (state: RootState) =>
  state.blockEventDestinations.webhookTypes;
export const selectDestinationsWebhookTypesLoading = (state: RootState) =>
  state.blockEventDestinations.webhookTypesLoading;

export const selectDestinationsPayloadTypes = (state: RootState) =>
  state.blockEventDestinations.payloadTypes;
export const selectDestinationsPayloadTypesLoading = (state: RootState) =>
  state.blockEventDestinations.payloadTypesLoading;

export const {
  handleShowDestinationEditor,
  handleHideDestinationEditor,
  handleSetDestination,
} = blockEventDestinationsSlice.actions;
export default blockEventDestinationsSlice.reducer;
