import {
  cn,
  Flex,
  Input,
  Loading,
  Tag,
  Tooltip,
  Typography,
} from "djuno-design";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import {
  DappFile,
  NewFileType,
  useDappFilesContext,
} from "../contexts/DappFilesContext";

import { ReactComponent as FileIcon } from "./../../../../../assets/icons/editor-files/languages/plaintext.svg";
import { ReactComponent as NewFolderIcon } from "./../../../../../assets/icons/editor-files/new-folder.svg";
import { ReactComponent as NewFileIcon } from "./../../../../../assets/icons/editor-files/new-file.svg";
import { ReactComponent as ArrowUpTrayIcon } from "./../../../../../assets/icons/editor-files/arrow-up-tray.svg";
import { ReactComponent as SaveIcon } from "./../../../../../assets/icons/editor-files/cloud-arrow-up.svg";
import { ReactComponent as ArrowPathIcon } from "./../../../../../assets/icons/editor-files/arrow-path.svg";
import { ReactComponent as Trashcon } from "./../../../../../assets/icons/trash.svg";
import { ReactComponent as RenameIcon } from "./../../../../../assets/icons/pencil-square.svg";
import { ReactComponent as ArrowUpIcon } from "./../../../../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "./../../../../../assets/icons/arrow-down.svg";

import { getDirectoryPath, getFileIcon } from "../utils";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ScrollArea } from "../../../../general/ScrollArea";
import { DeleteModal } from "../../../../modals/QuestionModal";
import toast from "react-hot-toast";
import { Menu, Item, useContextMenu, ItemParams } from "react-contexify";
import "react-contexify/ReactContexify.css";
import { useDappGit } from "../contexts/DappGitContext";
const CONTEXT_MENU_ID = "dapp-file-context-menu";

interface TreeViewComponentProps extends React.HTMLAttributes<HTMLDivElement> {}

export type TreeViewProps = {
  initialSelectedId?: string;
  indicator?: boolean;
  initialExpandedItems?: string[];
} & TreeViewComponentProps;

interface FolderComponentProps {}

type FolderProps = {
  file: DappFile;
  isSelectable?: boolean;
} & FolderComponentProps;

const MAX_FILE_SIZE_MB = 20; // Max file size in MB
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert to bytes

const FileExplorerSection = () => {
  const {
    files,
    handleFileUpload,
    dirtyFiles,
    saveAllDirtyFiles,
    actionLoading,
    compileLoading,
    loadPath,
    selectedFile,
    setNewFileData,
    deleteFile,
    changeActiveSection,
  } = useDappFilesContext();

  const { gitStatus } = useDappGit();

  const [deleteFilePath, setDeleteFilePath] = useState("");

  const handleUpload = async (
    files: globalThis.FileList,
    parentFile: DappFile | null
  ) => {
    const oversizedFiles = Array.from(files).filter(
      (file) => file.size > MAX_FILE_SIZE_BYTES
    );

    if (oversizedFiles.length > 0) {
      toast.error(
        `The following files exceed the size limit of ${MAX_FILE_SIZE_MB}MB:\n` +
          oversizedFiles
            .map(
              (file) =>
                `- ${file.name} (${(file.size / (1024 * 1024)).toFixed(2)} MB)`
            )
            .join("\n")
      );
      return;
    }

    const _t = toast.loading(`uploading file`);
    try {
      // Use the context function to handle the upload
      for (let i = 0; i < files.length; i++) {
        await handleFileUpload(files[i], parentFile);
      }
      console.log("");
      toast.success(`File(s) uploaded successfully`, {
        id: _t,
      });
      // Optionally, refresh or fetch the updated file list here
    } catch (error) {
      toast.error("Failed to upload file(s)", {
        id: _t,
      });
      console.error("Failed to upload file(s):", error);
    } finally {
      // Clear the input value to allow re-uploading the same file
      // event.target.value = "";
    }
  };

  const handleUploadInSelectedFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (!files) return;
    await handleUpload(files, selectedFile);

    event.target.value = "";
  };

  const handleStartCreateNewFile = useCallback(
    (type: NewFileType) => {
      const dir = getDirectoryPath(selectedFile);
      setNewFileData({
        name: "",
        dir,
        type,
      });
    },
    [selectedFile, setNewFileData]
  );

  const renderFileTree = (files: DappFile[]) => {
    return files.map((file) => {
      if (file.isDirectory) {
        return (
          <Folder key={file.path} file={file}>
            {file.children && renderFileTree(file.children)}
          </Folder>
        );
      } else {
        return <File key={file.path} file={file} />;
      }
    });
  };

  return (
    <>
      <Typography.Text size="sm" className="whitespace-nowrap px-3 mt-5">
        File explorer
      </Typography.Text>
      <div className="flex gap-2 px-3 my-3 items-center justify-between">
        <div className="flex gap-2 my-1 items-center flex-1">
          <Tooltip className="!text-xs" content="New file" place="top-start">
            <NewFileIcon
              className="w-4 h-4 flex-shrink-0 hover:cursor-pointer hover:scale-110 transition-all duration-200 text-slate-500 hover:text-slate-800 dark:text-slate-300 hover:dark:text-slate-50"
              onClick={() => handleStartCreateNewFile("file")}
            />
          </Tooltip>
          <Tooltip className="!text-xs" content="New folder" place="top-start">
            <NewFolderIcon
              className="w-4 h-4 flex-shrink-0 hover:cursor-pointer hover:scale-110 transition-all duration-200 text-slate-500 hover:text-slate-800 dark:text-slate-300 hover:dark:text-slate-50"
              onClick={() => handleStartCreateNewFile("folder")}
            />
          </Tooltip>
          <Tooltip
            className="!text-xs"
            content="Open a File from your File System"
            place="top-start"
          >
            <label>
              <ArrowUpTrayIcon className="w-4 h-4 flex-shrink-0 hover:cursor-pointer hover:scale-110 transition-all duration-200 text-slate-500 hover:text-slate-800 dark:text-slate-300 hover:dark:text-slate-50" />
              <input
                type="file"
                multiple
                className="hidden"
                onChange={handleUploadInSelectedFile}
              />
            </label>
          </Tooltip>
          {dirtyFiles.length > 0 && (
            <Tooltip className="!text-xs" content="Save" place="top-start">
              <SaveIcon
                className="w-4 h-4 flex-shrink-0 hover:cursor-pointer hover:scale-110 transition-all duration-200 text-slate-500 hover:text-slate-800 dark:text-slate-300 hover:dark:text-slate-50"
                onClick={saveAllDirtyFiles}
              />
            </Tooltip>
          )}
          <Tooltip className="!text-xs" content="Refresh" place="top-start">
            <ArrowPathIcon
              className="w-4 h-4 flex-shrink-0 hover:cursor-pointer hover:scale-110 transition-all duration-200 text-slate-500 hover:text-slate-800 dark:text-slate-300 hover:dark:text-slate-50"
              onClick={() => loadPath(selectedFile?.path)}
            />
          </Tooltip>
          {(actionLoading || compileLoading) && (
            <Loading borderSize={2} uiSize={14} />
          )}
        </div>
        {gitStatus && (gitStatus.ahead > 0 || gitStatus.behind > 0) && (
          <Tag
            className="!text-xs cursor-pointer"
            onClick={() => changeActiveSection("source-controll")}
            color="processing"
          >
            <Flex items="center" className="gap-0.5">
              {gitStatus && gitStatus.ahead > 0 && (
                <Flex items="center" className="-tracking-widest">
                  <Typography.Text size="xs" uiType="transparent">
                    {gitStatus.ahead}
                  </Typography.Text>
                  <ArrowUpIcon className="w-3 h-4 flex-shrink-0" />
                </Flex>
              )}
              {gitStatus && gitStatus.behind > 0 && (
                <Flex items="center" className="-tracking-widest">
                  <Typography.Text size="xs" uiType="transparent">
                    {gitStatus.behind}
                  </Typography.Text>
                  <ArrowUpIcon className="w-3 h-4 flex-shrink-0 rotate-180" />
                </Flex>
              )}
            </Flex>
          </Tag>
        )}
      </div>
      <Tree>{renderFileTree(files)}</Tree>
      <ContextMemu handleDelete={(path: string) => setDeleteFilePath(path)} />
      <DeleteModal
        isOpen={deleteFilePath !== ""}
        onConfirm={async () => {
          if (deleteFilePath !== "") {
            const status = await deleteFile(deleteFilePath);
            if (status) {
              setDeleteFilePath("");
            }
          }
        }}
        onClose={() => setDeleteFilePath("")}
        confirmButtonType="danger"
        loading={actionLoading}
      />
    </>
  );
};

const Tree = forwardRef<HTMLDivElement, TreeViewProps>(
  ({ className, children, ...props }, ref) => {
    const [bordered, setBordered] = useState(false);
    const { expandedFilesPath, clearSelectedFile, newFileData } =
      useDappFilesContext();

    return (
      <ScrollArea
        ref={ref}
        className={cn(
          "tree w-full flex-1 border border-transparent transition-colors duration-300",
          { "!border-primary-400": bordered },
          className
        )}
        scrollbarClassName="!w-1.5"
        onClick={(e) => {
          // e.stopPropagation();
          e.preventDefault();
          clearSelectedFile();
          setBordered(true);
          setTimeout(() => {
            setBordered(false);
          }, 2000);
        }}
      >
        <AccordionPrimitive.Root
          {...props}
          type="multiple"
          dir="ltr"
          defaultValue={expandedFilesPath}
          value={expandedFilesPath}
          className="flex flex-col max-h-full pb-10"
          // onValueChange={(value) => handleAddExpandedFilesPath(value[0])}
        >
          {children}
          {newFileData.type && newFileData.dir === "" && <NewItemInput />}
        </AccordionPrimitive.Root>
      </ScrollArea>
    );
  }
);

const Folder = forwardRef<
  HTMLDivElement,
  FolderProps & React.HTMLAttributes<HTMLDivElement>
>(({ className, file, isSelectable = true, children, ...props }, ref) => {
  const { show } = useContextMenu({
    id: CONTEXT_MENU_ID,
  });

  const {
    expandedFilesPath,
    selectedFile,
    loadingFilesPath,
    handleFileClick,
    newFileData,
    renameFileData,
  } = useDappFilesContext();

  const isSelected = selectedFile?.path === file.path;
  const isCreating = newFileData.dir === file.path && newFileData.type;
  const isRenaming =
    renameFileData?.renamingFile?.path === file.path && renameFileData.type;

  const isLoadingStage = useMemo(() => {
    if (loadingFilesPath === undefined) return false;
    return loadingFilesPath.includes(file.path);
  }, [file.path, loadingFilesPath]);

  return (
    <AccordionPrimitive.Item
      {...props}
      value={file.path}
      className="relative h-full overflow-hidden"
    >
      <AccordionPrimitive.Trigger
        className={cn(
          `flex items-center gap-1 w-full select-none py-1`,
          className,
          {
            "bg-blue-500/20 dark:bg-blue-600/80": isSelected,
            " hover:bg-gray-100 dark:hover:bg-dark-2": !isSelected,
            "cursor-pointer": isSelectable,
            "cursor-not-allowed opacity-50": !isSelectable,
          }
        )}
        disabled={!isSelectable}
        onClick={(e) => {
          e.stopPropagation();
          handleFileClick(file);
        }}
        onContextMenu={(e) => {
          show({
            event: e,
            props: {
              file,
            },
          });
        }}
      >
        {isLoadingStage ? (
          <Loading borderSize={2} uiSize={12} className="!mx-0.5" />
        ) : (
          <ArrowDownIcon
            className={cn(
              "w-4 h-4 flex-shrink-0 text-slate-700 dark:text-slate-200 transition-all duration-200 -rotate-90",
              { "rotate-0": expandedFilesPath?.includes(file.path) }
            )}
          />
        )}
        {isRenaming ? (
          <RenameItemInput />
        ) : (
          <Typography.Text size="xs">{file.name}</Typography.Text>
        )}
      </AccordionPrimitive.Trigger>
      <AccordionPrimitive.Content className="relative h-full overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down group">
        {isCreating && <NewItemInput />}
        <AccordionPrimitive.Root
          dir={"ltr"}
          type="multiple"
          className="pl-3 flex flex-col"
          defaultValue={expandedFilesPath}
          value={expandedFilesPath}
          // onValueChange={(value) => handleAddExpandedFilesPath(value[0])}
        >
          {children}
        </AccordionPrimitive.Root>
      </AccordionPrimitive.Content>
    </AccordionPrimitive.Item>
  );
});

export const File = forwardRef<
  HTMLButtonElement,
  {
    file: DappFile;
    isSelectable?: boolean;
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ file, className, isSelectable = true, children, ...props }, ref) => {
  const { show } = useContextMenu({
    id: CONTEXT_MENU_ID,
  });

  const { selectedFile, handleFileClick, isDirtyFile, renameFileData } =
    useDappFilesContext();
  const isSelected = selectedFile?.path === file.path;
  const isRenaming =
    renameFileData?.renamingFile?.path === file.path && renameFileData.type;

  return (
    <button
      ref={ref}
      type="button"
      disabled={!isSelectable}
      className={cn(
        "w-full select-none py-1",
        {
          "bg-blue-500/20 dark:bg-blue-500/80": isSelected,
          "hover:bg-gray-100 dark:hover:bg-dark-2": !isSelected,
        },
        isSelectable ? "cursor-pointer" : "cursor-not-allowed opacity-50",
        className
      )}
      onClick={(e) => {
        e.stopPropagation();
        handleFileClick(file);
      }}
      onContextMenu={(e) => {
        show({
          event: e,
          props: {
            file,
          },
        });
      }}
      {...props}
    >
      <div className="flex items-center gap-1 w-full">
        {getFileIcon(file.name)}
        {isRenaming ? (
          <RenameItemInput />
        ) : (
          <Typography.Text
            size="xs"
            uiType={isDirtyFile(file.path) ? "danger" : undefined}
            className="whitespace-nowrap overflow-hidden text-ellipsis"
          >
            {file.name}
          </Typography.Text>
        )}
      </div>
    </button>
  );
});

const ContextMemu: React.FC<{ handleDelete: (path: string) => void }> = ({
  handleDelete,
}) => {
  const { handleRightClick, setNewFileData, loadPath, setRenameFileData } =
    useDappFilesContext();

  const handleStartCreateNewFile = useCallback(
    (type: NewFileType, file: DappFile) => {
      const dir = getDirectoryPath(file);
      setNewFileData({
        name: "",
        dir,
        type,
      });
    },
    [setNewFileData]
  );

  const handleStartRenameFile = useCallback(
    (file: DappFile) => {
      const type: NewFileType = file.isDirectory ? "folder" : "file";
      setRenameFileData({
        renamingFile: file,
        type,
        newName: file.name,
      });
    },
    [setRenameFileData]
  );

  const handleItemClick = ({ id, props }: ItemParams) => {
    const file = props.file as DappFile;
    handleRightClick(file);

    switch (id) {
      case "new-file":
        handleStartCreateNewFile("file", file);
        break;

      case "new-folder":
        handleStartCreateNewFile("folder", file);
        break;

      case "reload":
        loadPath(file.path);
        break;

      case "rename":
        handleStartRenameFile(file);
        break;

      case "delete":
        handleDelete(file.path);
        break;
    }
  };

  return (
    <Menu
      id={CONTEXT_MENU_ID}
      theme="dark"
      className="!bg-white dark:!bg-zinc-700 !bg-opacity-95 !rounded-md !ring-1 !ring-slate-200 dark:!ring-dark-3 !p-0.5"
    >
      <Item id="new-file" onClick={handleItemClick} className="group">
        <Flex items="center" className="gap-1">
          <NewFileIcon className="w-4 h-4 flex-shrink-0 text-slate-500 group-hover:!text-white dark:text-slate-300" />
          <Typography.Text size="xs" className="group-hover:!text-white">
            New File
          </Typography.Text>
        </Flex>
      </Item>

      <Item id="new-folder" onClick={handleItemClick} className="group">
        <Flex items="center" className="gap-1">
          <NewFolderIcon className="w-4 h-4 flex-shrink-0 text-slate-500 group-hover:!text-white dark:text-slate-300" />
          <Typography.Text size="xs" className="group-hover:!text-white">
            New Folder
          </Typography.Text>
        </Flex>
      </Item>

      <Item id="reload" onClick={handleItemClick} className="group">
        <Flex items="center" className="gap-1">
          <ArrowPathIcon className="w-4 h-4 flex-shrink-0 text-slate-500 group-hover:!text-white dark:text-slate-300" />
          <Typography.Text size="xs" className="group-hover:!text-white">
            Reload
          </Typography.Text>
        </Flex>
      </Item>

      <div className="block m-1.5 bg-slate-200 dark:bg-zinc-600 h-[1px]" />

      <Item id="rename" onClick={handleItemClick} className="group">
        <Flex items="center" className="gap-1">
          <RenameIcon className="w-4 h-4 flex-shrink-0 text-slate-500 group-hover:!text-white dark:text-slate-300" />
          <Typography.Text size="xs" className="group-hover:!text-white">
            Rename
          </Typography.Text>
        </Flex>
      </Item>
      <Item id="delete" onClick={handleItemClick} className="group">
        <Flex items="center" className="gap-1">
          <Trashcon className="w-4 h-4 flex-shrink-0 text-red-500 group-hover:!text-white dark:text-slate-300" />
          <Typography.Text
            size="xs"
            className="!text-red-500 group-hover:!text-white"
          >
            Delete
          </Typography.Text>
        </Flex>
      </Item>
    </Menu>
  );
};

const NewItemInput = () => {
  const {
    newFileData,
    setNewFileData,
    handleCreateNewFile,
    getFolderContents,
  } = useDappFilesContext();

  const newFileInputRef = useRef<HTMLInputElement>(null);

  const { name, type, dir } = newFileData;
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState<string | undefined>();
  const [existingNames, setExistingName] = useState<string[]>([]);

  const handleCreate = async () => {
    if (type && !inputError) {
      setLoading(true);
      await handleCreateNewFile();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type) {
      setTimeout(() => newFileInputRef.current?.focus(), 10);
    }
  }, [type]);

  useEffect(() => {
    const names = getFolderContents(dir).map((f) => f.name);
    setExistingName(names);
  }, [dir, getFolderContents]);

  const handleOnChange = useCallback(
    (name: string) => {
      if (existingNames.length > 0 && existingNames.includes(name)) {
        setInputError(
          `A file or Folder ${name} already exists at this location. Please choose a different name.`
        );
      } else {
        setInputError(undefined);
      }
      setNewFileData((prev) => ({
        ...prev,
        name,
      }));
    },
    [existingNames, setNewFileData]
  );

  const handleOnBlur = useCallback(() => {
    setInputError(undefined);
    setNewFileData({
      dir: "",
      name: "",
      type: null,
    });
  }, [setNewFileData]);

  return (
    <div
      className={cn(
        "select-none cursor-pointer items-start gap-1 py-0.5 hidden pl-1",
        {
          "!flex": type,
          "!pl-3": dir !== "",
        }
      )}
    >
      {type === "folder" && (
        <ArrowDownIcon className="w-4 h-4 flex-shrink-0 -rotate-90 text-slate-800 dark:text-slate-100 mt-0.5" />
      )}
      {type === "file" && (
        <FileIcon className="w-4 h-4 flex-shrink-0 text-slate-800 dark:text-slate-100 mt-0.5" />
      )}
      <Input
        ref={newFileInputRef}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleCreate();
          }
        }}
        onChange={(e) => handleOnChange(e.target.value)}
        onBlur={handleOnBlur}
        value={name}
        uiType="simple"
        className="!h-5 dark:!bg-transparent !text-xs !px-0 !rounded-none"
        error={inputError}
        loading={loading}
        // containerClassName="!h-6"
      />
    </div>
  );
};

const RenameItemInput = () => {
  const {
    renameFileData,
    setRenameFileData,
    handleRenameFile,
    getFolderContents,
  } = useDappFilesContext();

  const newFileInputRef = useRef<HTMLInputElement>(null);

  const { type, renamingFile, newName } = renameFileData;
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState<string | undefined>();
  const [existingNames, setExistingName] = useState<string[]>([]);

  const handleRename = async () => {
    if (type && !inputError) {
      setLoading(true);
      await handleRenameFile();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type) {
      setTimeout(() => newFileInputRef.current?.focus(), 10);
    }
  }, [type]);

  useEffect(() => {
    const names = getFolderContents(renamingFile?.path || "").map(
      (f) => f.name
    );
    setExistingName(names);
  }, [renamingFile, getFolderContents]);

  const handleOnChange = useCallback(
    (name: string) => {
      if (existingNames.length > 0 && existingNames.includes(name)) {
        setInputError(
          `A file or Folder ${name} already exists at this location. Please choose a different name.`
        );
      } else {
        setInputError(undefined);
      }
      setRenameFileData((prev) => ({
        ...prev,
        newName: name,
      }));
    },
    [existingNames, setRenameFileData]
  );

  const handleOnBlur = useCallback(() => {
    setInputError(undefined);
    setRenameFileData({
      type: null,
      renamingFile: null,
      newName: "",
    });
  }, [setRenameFileData]);

  return (
    <div
      className={cn("select-none cursor-pointer items-start gap-1 hidden", {
        "!flex": type,
        // "!pl-3": dir !== "",
      })}
    >
      {/* {type === "folder" && (
        <ArrowDownIcon className="w-4 h-4 flex-shrink-0 -rotate-90 text-slate-800 dark:text-slate-100 mt-0.5" />
      )}
      {type === "file" && (
        <FileIcon className="w-4 h-4 flex-shrink-0 text-slate-800 dark:text-slate-100 mt-0.5" />
      )} */}
      <Input
        ref={newFileInputRef}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleRename();
          }
        }}
        onChange={(e) => handleOnChange(e.target.value)}
        onBlur={handleOnBlur}
        value={newName}
        uiType="simple"
        className="!h-[16px] dark:!bg-transparent !text-xs !px-0 !rounded-none"
        error={inputError}
        loading={loading}
        // containerClassName="!h-6"
      />
    </div>
  );
};

export default FileExplorerSection;
