import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  Alert,
  Button,
  Flex,
  Input,
  Loading,
  sleep,
  Typography,
} from "djuno-design";
import {
  getWebAppAsync,
  selectWebApp,
  selectWebAppLoading,
  selectWebAppUpdateLoading,
  updateWebAppAsync,
} from "../../../store/web-app/webAppSlice";
import { formatTimestamp } from "../../../utils/date";
import { AiMessage, useAiChatContext } from "../../../providers/AiChatProvider";
import { ReactComponent as DjunoLogo } from "../../../assets/icons/logo.svg";
import { ReactComponent as ArrowPathIcon } from "../../../assets/icons/arrow-path.svg";
import { selectUser } from "../../../store/auth/authSlice";
import { UserAvatar } from "../../dropdowns/UserDropdown";
import MarkdownText from "../../general/MarkdownText";
import useConfigIntercomeSetting from "../../../hooks/useConfigIntercomeSetting";

const WebAppAIChatTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);
  const webAppUpdateLoading = useAppSelector(selectWebAppUpdateLoading);

  const dispatch = useAppDispatch();

  useConfigIntercomeSetting();

  const [thinking, setThinking] = useState<boolean>(false);
  const [errorBox, setErrorBox] = useState<null | string>(null);

  const [reDeploying, setReDeploying] = useState<boolean>(false);
  const [isParsable, setIsParsable] = useState<boolean>(false);
  const [newSourceCode, setNewSourceCode] = useState<string | null>(null);

  // console.log({ newSourceCode });

  const {
    handleClearAiContext,
    handleGetThread,
    messages,
    messagesLoading,
    handleSendMessage,
    runLoading,
    handleSetRun,
    handleGetRun,
    handleSetParse,
  } = useAiChatContext();

  const [input, setInput] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const handleDeployChanges = useCallback(async () => {
    if (isParsable && webApp && newSourceCode) {
      try {
        setReDeploying(true);
        const parseRes = await handleSetParse(
          webApp.ServiceName,
          newSourceCode
        );
        if (!parseRes) throw Error("Failed to parse response");
        dispatch(
          updateWebAppAsync({
            webAppId: webApp.Id.toString(),
            data: {
              ...webApp,
              Port: parseRes.port,
              EnvironmentVariables: parseRes.EnvironmentVariables,
              ImageName: parseRes.imagename,
              ImageTag: parseRes.imagetag,
              WebAppSourceCode: parseRes.sourceCode,
            },
          })
        )
          .then((action) => {
            if (action.type === "web-app/update/fulfilled") {
              setIsParsable(false);
              dispatch(getWebAppAsync({ webAppId: webApp.Id.toString() }));
            } else {
              setErrorBox(
                "An unexpected error occurred. Please try again later."
              );
            }
          })
          .finally(() => {
            setReDeploying(false);
          });
      } catch (e) {
        setReDeploying(false);
        setErrorBox("An unexpected error occurred. Please try again later.");
        console.log("error:", e);
      }
    }
  }, [dispatch, handleSetParse, isParsable, newSourceCode, webApp]);

  const handleGetRunUntilSuccess = useCallback(
    async (runId: string, threadId: string) => {
      try {
        setThinking(true);
        setIsParsable(false);
        await sleep(10000);
        const runRes = await handleGetRun(runId, threadId);
        if (!runRes) throw Error("Failed to retrieve run data.");
        if (runRes?.status === "completed") {
          const lastMessage = await handleGetThread(threadId);
          if (!lastMessage || !lastMessage.content?.[0]?.text?.value)
            throw Error("Failed to retrieve thread messages.");
          setNewSourceCode(lastMessage.content?.[0]?.text?.value);
          setInput("");
          setIsParsable(true);
          setThinking(false);
        } else {
          handleGetRunUntilSuccess(runId, threadId);
        }
      } catch (e) {
        setThinking(false);
        setErrorBox("An unexpected error occurred. Please try again later.");
        console.log("error:", e);
      }
    },
    [handleGetRun, handleGetThread]
  );

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSend = useCallback(
    async (userMessage: string) => {
      if (
        userMessage &&
        !messagesLoading &&
        !runLoading &&
        webApp &&
        webApp.ThreadId
      ) {
        try {
          setErrorBox(null);
          setThinking(true);
          const messageRes = await handleSendMessage(
            userMessage,
            webApp.ThreadId
          );
          if (!messageRes) throw Error("Failed to send message.");
          const runRes = await handleSetRun(webApp.ThreadId);
          if (runRes)
            await handleGetRunUntilSuccess(runRes.id, webApp.ThreadId);
          scrollToBottom();
        } catch (e) {
          setThinking(false);
          setErrorBox("An unexpected error occurred. Please try again later.");
          console.log("error:", e);
        }
      }
    },
    [
      handleGetRunUntilSuccess,
      handleSendMessage,
      handleSetRun,
      messagesLoading,
      runLoading,
      webApp,
    ]
  );

  const handleTryAgain = useCallback(() => {
    setErrorBox(null);
    if (input !== "") {
      handleSend(input);
    } else if (newSourceCode && isParsable) {
      handleDeployChanges();
    }
  }, [handleDeployChanges, handleSend, input, isParsable, newSourceCode]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // get all prev messages
  useEffect(() => {
    const getPrevMessages = async (threadId: string) => {
      const lastAiMessage = await handleGetThread(threadId);
      if (lastAiMessage && lastAiMessage.content?.[0]?.text?.value) {
        setNewSourceCode(lastAiMessage.content?.[0]?.text?.value);
        setIsParsable(true);
      }
    };
    if (webApp && webApp.ThreadId) {
      getPrevMessages(webApp.ThreadId);
    }
  }, [handleGetThread, webApp]);

  useEffect(() => {
    return () => {
      handleClearAiContext();
    };
  }, [handleClearAiContext]);

  return (
    <>
      {/* Messages Container */}
      <div className="flex-grow overflow-y-auto p-4">
        {webAppLoading ||
          (messages.length === 0 && (
            <Flex items="center" justify="center" className="!min-h-[300px]">
              <Loading />
            </Flex>
          ))}
        {!webAppLoading && (
          <MessagesSection
            messages={messages}
            handleTryAgain={handleTryAgain}
            thinking={thinking}
            errorBox={errorBox}
          />
        )}

        <div ref={messagesEndRef} />
      </div>

      {/* Input Section */}
      <div className="mt-5 p-2 border-t border-gray-300 bg-white dark:border-dark-2 dark:bg-dark-1 flex items-center space-x-2">
        <Input
          type="text"
          value={input}
          onChange={(e) => {
            console.log(e.target.value);
            setInput(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              if (input.trim() !== "") handleSend(input.trim());
            }
          }}
          disabled={thinking}
          placeholder="Type a message..."
          className="focus:outline-none focus:ring-2 focus:!ring-blue-500"
          containerClassName="!flex-grow"
        />
        <Button
          onClick={() => handleSend(input.trim())}
          uiType="primary"
          disabled={
            !webApp?.ThreadId ||
            input.trim() === "" ||
            thinking ||
            messagesLoading ||
            runLoading ||
            webAppLoading
          }
          loading={thinking}
        >
          Send
        </Button>
        <Button
          onClick={handleDeployChanges}
          uiType="primary"
          disabled={
            !newSourceCode ||
            thinking ||
            reDeploying ||
            webAppLoading ||
            webAppUpdateLoading
          }
          // loading={reDeploying || webAppLoading || webAppUpdateLoading}
        >
          Deploy Changes
        </Button>
      </div>
    </>
  );
};

const MessagesSection: React.FC<{
  messages: AiMessage[];
  thinking: boolean;
  errorBox: string | null;
  handleTryAgain: () => void;
}> = ({ messages, thinking, errorBox, handleTryAgain }) => {
  const user = useAppSelector(selectUser);
  return (
    <Flex direction="col" className="gap-2">
      {messages.map((msg) => (
        <div
          key={msg.id}
          className={`p-2 md:p-4 rounded-3xl !text-sm w-[95%] ${
            msg.role === "user"
              ? "bg-blue-500 text-white self-end ml-auto !rounded-br-none"
              : "bg-gray-200 text-gray-800 dark:bg-dark-2 dark:text-slate-100 self-start !rounded-bl-none"
          }`}
        >
          <Flex
            items="center"
            justify="between"
            className="w-full border-b mb-3 pb-1 border-slate-300 dark:border-slate-600"
          >
            <Typography.Text size="sm" uiType="transparent">
              {msg.role === "user" ? (
                <Flex items="center" className="gap-1">
                  <div className="w-4 h-4 flex-shrink-0">
                    <UserAvatar />
                  </div>
                  {user?.FullName}
                </Flex>
              ) : (
                <Flex items="center" className="gap-1">
                  <DjunoLogo className="w-4 h-4 flex-shrink-0" />
                  Djuno AI
                </Flex>
              )}
            </Typography.Text>
            <Typography.Text size="xs" uiType="transparent">
              {
                formatTimestamp(msg.created_at, "HH:mm A", {
                  isUTC: false,
                }).datetime
              }
            </Typography.Text>
          </Flex>
          <MarkdownText text={msg.content[0]?.text?.value} />
        </div>
      ))}
      {thinking && (
        <Flex className="items-center gap-1">
          <Loading borderSize={2} uiSize={16} />
          <Typography.Text size="sm">thinking</Typography.Text>
        </Flex>
      )}
      {errorBox && (
        <Flex className="items-center gap-1">
          <Alert uiType="error" showIcon className="!text-xs">
            <Flex className="items-center justify-between gap-1 w-full">
              <Typography.Text size="sm">{errorBox}</Typography.Text>
              <Button uiType="light" uiSize="small" onClick={handleTryAgain}>
                <ArrowPathIcon className="w-4 h-4 flex-shrink-0" />
                try again
              </Button>
            </Flex>
          </Alert>
        </Flex>
      )}
    </Flex>
  );
};
export default WebAppAIChatTab;
