import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { useNavigate } from "react-router-dom";
import {
  DatabaseBackupsDuplicateUrl,
  DatabaseServiceGeneralInformationUrl,
} from "../../../utils/urls";
import HighlighterText from "../../general/HighlighterText";
import { useSearch } from "../../../providers/SearchProvider";
import {
  getDBSAvailabilityAsync,
  selectDBSAvailability,
  selectDBSAvailabilityLoading,
  updateDBSAsync,
} from "../../../store/database/servicesSlice";
import { convertTimeStringToUTC, getGmtOffsetHours } from "../../../utils/date";
import {
  DBSRestoreAsync,
  getDBSBackupsAsync,
  getDatabaseServiceAsync,
  selectDBSBackups,
  selectDBSBackupsLoading,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { IDBSBackup } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ReactComponent as VerifyIcon } from "./../../../assets/icons/verify.svg";
import { ReactComponent as ErrorIcon } from "./../../../assets/icons/error-warning.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import {
  Button,
  Card,
  Dropdown,
  EmptyState,
  SimpleTable,
  Skeleton,
  Tag,
  Typography,
} from "djuno-design";
import QuestionModal from "../../modals/QuestionModal";
import DatabaseRestorPoint from "../services/DatabaseRestorPoint";

const DBSBackupsTab = () => {
  const [filteredBackups, setFilteredBackups] = useState<IDBSBackup[]>([]);
  const [showHandleBackupTimeButton, setShowHandleBackupTimeButton] =
    useState<boolean>(false);

  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false);
  const [selectedBackup, setSelectedBackup] = useState<IDBSBackup | undefined>(
    undefined
  );

  const service = useAppSelector(selectDatabaseService);
  const backups = useAppSelector(selectDBSBackups);
  const backupsLoading = useAppSelector(selectDBSBackupsLoading);

  const availability = useAppSelector(selectDBSAvailability);
  const availabilityLoading = useAppSelector(selectDBSAvailabilityLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  const [backupTime, setBackupTime] = useState(service?.backupTime || "");
  const handleChangeBackupTime = (event: any) => {
    setBackupTime(event.target.value);
    setShowHandleBackupTimeButton(true);
  };
  const handleActionBackupTime = (isCancel: boolean) => {
    if (service) {
      dispatch(
        updateDBSAsync({
          id: service.id,
          engine: service.engine,
          data: {
            backupTime: isCancel ? service?.backupTime : backupTime,
          },
        })
      ).then((action) => {
        if (action.type === "db-services/update/fulfilled") {
          dispatch(getDatabaseServiceAsync({ id: service.id }));
          setShowHandleBackupTimeButton(false);
          setBackupTime(service.maintenanceTime);
        }
      });
    }
  };

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().backups;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSBackupsAsync({ engine: service.engine, id: service.id })
        );
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    const lookedUpFiles = backups?.filter((backup) =>
      backup.description?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredBackups(lookedUpFiles);
  }, [dispatch, searchValue, backups]);

  const formatTimestamp = (timestamp: any, format: any) => {
    const dateTime = moment(timestamp).format(format);
    return { datetime: dateTime };
  };

  const formatWithRetention = (timestamp: any, retention: number) => {
    const dateWithRetention = moment(timestamp)
      .add(retention, "days")
      .format("MMM DD hh:mm:ss A");
    return { datetime: dateWithRetention };
  };

  const handleDuplicateBackup = (backup: any) => {
    service &&
      navigate(DatabaseBackupsDuplicateUrl(service.id), {
        state: { selectedBackup: backup, selectedValue: 3 },
      });
  };

  const handleDuplicateNewest = (backups: any) => {
    if (service && service.capabilities.backupTime.update === "enabled") {
      navigate(DatabaseBackupsDuplicateUrl(service.id), {
        // state: { selectedValue: 1 },
        state: { selectedValue: 3 },
      });
    } else if (service) {
      navigate(DatabaseBackupsDuplicateUrl(service.id), {
        state: { selectedBackupTime: backups, selectedValue: 3 },
      });
    }
  };

  const availabilityData = useMemo(() => {
    return availability?.find(
      (a) => a.engine === service?.engine && a.plan === service.plan
    );
  }, [service, availability]);

  useEffect(() => {
    if (availability === undefined || availability.length === 0) {
      dispatch(getDBSAvailabilityAsync());
    }
  }, [availability, dispatch]);

  const handleRestoreClick = () => {
    setIsRestoreModalOpen(true);
  };

  // Handle closing the modal
  const handleCloseRestoreModal = () => {
    setIsRestoreModalOpen(false);
  };

  const handleConfirmRestore = () => {
    if (selectedBackup && service) {
      setIsRestoreModalOpen(false);
      dispatch(
        DBSRestoreAsync({
          engine: service.engine,
          clusterId: service.id,
          backupId: selectedBackup.id,
        })
      ).then((action) => {
        if (action.type === "service/backup/restore/fulfilled") {
          dispatch(
            getDBSBackupsAsync({ engine: service.engine, id: service.id })
          );
        }
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <div className="flex flex-col gap-3">
            <Typography.Title level={5} className="!mb-0">
              Backups
            </Typography.Title>

            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Typography.Text className="!text-sm ">
                Below you will find the list of your backups. You can restore or
                fork any of them.
              </Typography.Text>
            </div>
            <div className="items-center justify-between flex flex-1 transition duration-150">
              <Typography.Text className="!text-sm ">
                These rolling backups are kept for your service to remain
                operational.
              </Typography.Text>
            </div>
            <div className="mt-5">
              <Card title="Database backups">
                <ul className="mt-2 ">
                  <li className="p-4 ">
                    <div className="flex justify-between flex-col md:flex-row">
                      <div className="mb-4 md:mb-0 w-full ">
                        <Typography.Title level={6}>
                          Retention period
                        </Typography.Title>
                        {availabilityLoading && (
                          <Skeleton style={{ width: 110, height: 24 }} />
                        )}
                        {!availabilityLoading && (
                          <Typography.Text className="!text-sm flex items-center gap-1">
                            {availabilityData?.backups?.retentionDays &&
                            availabilityData.backups.retentionDays > 0
                              ? `${availabilityData.backups.retentionDays} days`
                              : "0 days"}
                          </Typography.Text>
                        )}
                      </div>
                      <div className="mb-4 md:mb-0 w-full">
                        <Typography.Title level={6}>
                          Backup time (UTC)
                        </Typography.Title>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div className="flex flex-row">
                            <TextField
                              type="time"
                              defaultValue={service.backupTime}
                              inputProps={{ step: 1, className: "!p-1" }}
                              onChange={handleChangeBackupTime}
                              InputProps={{
                                className: "dark:bg-slate-400 p-0",
                              }}
                              disabled={!service.capabilities.backupTime.update}
                            />
                            {showHandleBackupTimeButton && (
                              <div className="flex flex-col mt-1 ml-2">
                                <div
                                  onClick={() => handleActionBackupTime(false)}
                                >
                                  <Typography.Text>
                                    <VerifyIcon className="w-6 h-6" />
                                  </Typography.Text>
                                </div>
                                <div
                                  onClick={() => handleActionBackupTime(true)}
                                >
                                  <Typography.Text>
                                    <ErrorIcon className="w-6 h-6" />
                                  </Typography.Text>
                                </div>
                              </div>
                            )}
                          </div>
                        </LocalizationProvider>
                        <Typography.Text className="!text-xs mt-1">
                          Local time (GMT {getGmtOffsetHours()}
                          ):{" "}
                          {service &&
                            convertTimeStringToUTC(
                              service?.backupTime
                            ).toLocaleTimeString()}
                        </Typography.Text>
                      </div>
                    </div>
                  </li>
                </ul>
              </Card>
            </div>
            <div className="flex justify-end">
              {service && backups && (
                <div className="flex items-center gap-2">
                  <Button
                    uiType="light"
                    onClick={() => {
                      if (service)
                        dispatch(
                          getDBSBackupsAsync({
                            engine: service.engine,
                            id: service.id,
                          })
                        );
                    }}
                    disabled={!service}
                    className="group"
                    tooltip={{ content: "Refresh" }}
                  >
                    <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
                  </Button>
                  <Button
                    uiType="primary"
                    onClick={() =>
                      handleDuplicateNewest(
                        service.capabilities.backupTime.update ? backups : null
                      )
                    }
                    //  disabled={backupsLoading},
                    className="group"
                  >
                    Duplicate (Fork)
                  </Button>
                  {service.engine === "mongodb" && (
                    <Button
                      uiType="primary"
                      onClick={handleRestoreClick}
                      className="group"
                    >
                      Restore
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 w-full">
            <SimpleTable
              loading={backupsLoading}
              containerClassName="min-h-[240px]"
            >
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Name" />
                  <SimpleTable.TH lable="Location" />
                  <SimpleTable.TH lable="Creation date" />
                  <SimpleTable.TH lable="Expiry date" />
                  <SimpleTable.TH lable="Status" />
                  <SimpleTable.TH lable="" />
                </SimpleTable.Row>
              </SimpleTable.Head>

              <SimpleTable.Body>
                {filteredBackups.map((backup, i) => (
                  <SimpleTable.Row key={i}>
                    <SimpleTable.TD>
                      <div className="flex items-center flex-row justify-between space-x-3">
                        <HighlighterText
                          searchValue={searchValue}
                          textToHighlight={backup.description}
                          className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                        />
                      </div>
                    </SimpleTable.TD>

                    <SimpleTable.TD>
                      <Typography.Text className="!text-sm">
                        {backup.regions.map((r) => (
                          <div className="mr-2">{r.name}</div>
                        ))}
                      </Typography.Text>
                    </SimpleTable.TD>

                    <SimpleTable.TD>
                      <Typography.Text className="!text-sm">
                        {
                          formatTimestamp(backup.createdAt, "MMM DD hh:mm:ss A")
                            .datetime
                        }
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-sm">
                        {
                          formatWithRetention(
                            backup.createdAt,
                            (availabilityData &&
                              availabilityData.backups.retentionDays) ||
                              0
                          ).datetime
                        }
                      </Typography.Text>
                    </SimpleTable.TD>

                    <SimpleTable.TD>
                      <Tag
                        className="text-xs"
                        color={
                          backup.status === "READY" ? "success" : "warning"
                        }
                      >
                        {backup.status}
                      </Tag>
                    </SimpleTable.TD>

                    <SimpleTable.TD className="w-20 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-end gap-1">
                        <div className="w-8 flex justify-center items-center ">
                          <Dropdown
                            anchor="bottom end"
                            itemsClassName="!w-48"
                            menu={[
                              {
                                key: "1",
                                label: (
                                  <div className="flex items-center gap-1">
                                    Duplicate (Fork)
                                  </div>
                                ),
                                onClick: (_, close) => {
                                  close();
                                  handleDuplicateBackup(backup);
                                },
                                disabled: backupsLoading,
                              },
                            ]}
                          >
                            <Button
                              uiSize="small"
                              uiType="icon"
                              className="!px-2"
                            >
                              <MoreIcon className="w-4 h-4" />
                            </Button>
                          </Dropdown>
                        </div>
                        {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                      </div>
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                ))}

                {backups.length === 0 && (
                  <SimpleTable.Row
                    withoutHoverStyle={true}
                    className="h-[200px]"
                  >
                    <SimpleTable.TD colSpan={6} className="!border-0">
                      <EmptyState />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>
          </div>
          <QuestionModal
            title="Restore Backup"
            description=""
            isOpen={isRestoreModalOpen}
            onClose={handleCloseRestoreModal}
            onConfirm={handleConfirmRestore}
            loading={false}
            confirmButtonType="primary"
            confirmButtonText="Restore"
            contentClassName="max-w-lg "
          >
            <DatabaseRestorPoint
              isRestore={true}
              onBackupSelect={setSelectedBackup}
            />
          </QuestionModal>
        </>
      )}
    </>
  );
};

export default DBSBackupsTab;
