import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  CreateBlockApiApiKeyData,
  BlockApiApiKey,
  BlockApiApiKeyStateType,
} from "../../types/block-api";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  getBlockApiApiKeysApi,
  createBlockApiApiKeyApi,
  updateBlockApiApiKeyApi,
  deleteBlockApiApiKeyApi,
} from "../../apis/blockApiAPI";

const initialState: BlockApiApiKeyStateType = {
  loading: false,
  apiKeys: [],
  selectedApiKey: null,
  showEditorModal: false,
  actionLoading: false,
};

export const getBlockApiApiKeysAysnc = createAsyncThunk<
  {
    apiKeys: Array<BlockApiApiKey>;
  },
  undefined,
  IThunkRejectValue
>("block-api/api-keys", async (_, { fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await getBlockApiApiKeysApi();
    const apiKeys = response.data.Result;
    return fulfillWithValue({ apiKeys });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteBlockApiApiKeyAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("block-api/api-keys/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteBlockApiApiKeyApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createBlockApiApiKeyAsync = createAsyncThunk<
  boolean,
  CreateBlockApiApiKeyData,
  IThunkRejectValue
>("block-api/api-keys/create", async (formData, { rejectWithValue }) => {
  try {
    await createBlockApiApiKeyApi(formData);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateBlockApiApiKeyAsync = createAsyncThunk<
  boolean,
  { id: number; formData: CreateBlockApiApiKeyData },
  IThunkRejectValue
>(
  "block-api/api-keys/update",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      await updateBlockApiApiKeyApi(id, formData);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

const blockApiApiKeysSlice = createSlice({
  name: "blockApiApiKeys",
  initialState: initialState,
  reducers: {
    clearBlockApiApiKeys(state) {
      state.apiKeys = initialState.apiKeys;
      state.loading = initialState.loading;
    },
    handleShowBlockApiEditorModal: (
      state,
      action: PayloadAction<{ apiKey?: BlockApiApiKey }>
    ) => {
      state.showEditorModal = true;
      state.selectedApiKey = action.payload.apiKey || null;
    },
    handleHideBlockApiEditorModal: (state) => {
      state.showEditorModal = false;
      state.selectedApiKey = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlockApiApiKeysAysnc.fulfilled, (state, action) => {
        state.apiKeys = action.payload.apiKeys;
        state.loading = false;
      })
      .addCase(getBlockApiApiKeysAysnc.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBlockApiApiKeysAysnc.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteBlockApiApiKeyAsync.fulfilled, (state, action) => {
        state.actionLoading = false;
      })
      .addCase(deleteBlockApiApiKeyAsync.pending, (state, action) => {
        state.actionLoading = true;
      })
      .addCase(deleteBlockApiApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createBlockApiApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createBlockApiApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createBlockApiApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateBlockApiApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateBlockApiApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateBlockApiApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectBlockApiLoading = (state: RootState) =>
  state.blockApiApiKeys.loading;
export const selectBlockApiActionLoading = (state: RootState) =>
  state.blockApiApiKeys.actionLoading;

export const selectBlockApiApiKeys = (state: RootState) =>
  state.blockApiApiKeys.apiKeys;

export const selectSelectedBlockApiApiKey = (state: RootState) =>
  state.blockApiApiKeys.selectedApiKey;

export const selectShowBlockApiEditorModal = (state: RootState) =>
  state.blockApiApiKeys.showEditorModal;

export const {
  clearBlockApiApiKeys,
  handleShowBlockApiEditorModal,
  handleHideBlockApiEditorModal,
} = blockApiApiKeysSlice.actions;

export default blockApiApiKeysSlice.reducer;
