import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as PlusIcon } from "./../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as RefreshIcon } from "./../../assets/icons/arrow-path.svg";
import { useNavigate } from "react-router-dom";
import { DeleteModal } from "../../components/modals/QuestionModal";
import HighlighterText from "../../components/general/HighlighterText";
import { useSearch } from "../../providers/SearchProvider";
import { Helmet } from "react-helmet";
import { WebAppsRefreshStatus } from "../../store/web-app/webAppsSlice";
import {
  BlockchainCreateUrl,
  BlockchainUrl,
  WebAppUrl,
} from "../../utils/urls";
import {
  Button,
  cn,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Typography,
} from "djuno-design";
import useServiceType from "../../hooks/useServiceType";
import { Blockchain } from "../../types/blockchain";
import {
  deleteBlockchainsAsync,
  getBlockchainsAsync,
  selectBlockchains,
  selectBlockchainsActionLoading,
  selectBlockchainsLoading,
} from "../../store/blockchain-studio/blockchainsSlice";
import { WebAppStatusTag } from "../web-apps/WebAppsPage";
import useLimitations from "../../hooks/useLimitations";
import { useTour } from "../../providers/TourProvider";

const BlockchainsPage = () => {
  const blockchains = useAppSelector(selectBlockchains);
  const blockchainsLoading = useAppSelector(selectBlockchainsLoading);
  const blockchainsActionLoading = useAppSelector(
    selectBlockchainsActionLoading
  );

  const { serviceType } = useServiceType(
    process.env.REACT_APP_BLOCKCHAIN_STUDIO_SERVICE_TYPE
  );

  const { numberOfBlockchainsLimit, numberOfBlockchainsLimitChecker } =
    useLimitations({
      cases: ["NumberOfBlockChains"],
    });
  const isValidToCreateNewBC = useMemo(
    () => !numberOfBlockchainsLimitChecker(blockchains.length),
    [numberOfBlockchainsLimitChecker, blockchains.length]
  );

  const [filteredBlockchains, setFilteredBlockchains] = useState<Blockchain[]>(
    []
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { run } = useTour("blockchains");

  const [deleteBlockchain, setDeleteBlockchain] = useState<Blockchain | null>(
    null
  );
  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getBlockchainsAsync({}));
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = blockchains?.filter((blockchain) =>
      blockchain.ServiceName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredBlockchains(lookedUpFiles);
  }, [dispatch, searchValue, blockchains]);

  //refresh the list
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let intervalTime = 8000;

    const shouldSetInterval = blockchains.some((bc) =>
      WebAppsRefreshStatus.includes(bc.ServiceStatus)
    );

    if (shouldSetInterval) {
      interval = setInterval(() => {
        dispatch(getBlockchainsAsync({ withoutLoading: true }));
      }, intervalTime);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, blockchains]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""}
        </title>
        <meta name="description" content="" />
      </Helmet>

      <div className="p-6 ">
        <Flex items="center" justify="between" className="blockchain-header">
          <Typography.Title level={5}>
            {serviceType?.ServiceName}
          </Typography.Title>

          <div className="flex ">
            <Button
              onClick={() => {
                run();
              }}
              className="mr-3"
            >
              Start Tour
            </Button>
            <Button
              uiSize="medium"
              uiType="simple"
              onClick={() => dispatch(getBlockchainsAsync({}))}
              className="group mr-3 refresh-button"
              tooltip={{ content: "Refresh" }}
            >
              <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
            <Button
              uiType="primary"
              onClick={() => {
                if (isValidToCreateNewBC) navigate(BlockchainCreateUrl);
              }}
              className={cn(
                "create-database-button group create-blockchain-button",
                {
                  "!cursor-not-allowed": !isValidToCreateNewBC,
                }
              )}
              tooltip={{
                content: !isValidToCreateNewBC
                  ? numberOfBlockchainsLimit?.LimitationMessage
                  : "",
                className: "!text-xs",
              }}
            >
              Create Blockchain
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </Flex>

        <div className="mt-5 w-full blockchain-table">
          <SimpleTable
            loading={blockchainsLoading}
            containerClassName="min-h-[240px]"
          >
            <SimpleTable.Head>
              <SimpleTable.Row>
                <SimpleTable.TH>Name</SimpleTable.TH>
                <SimpleTable.TH>Consensus</SimpleTable.TH>
                <SimpleTable.TH>Number of Nodes</SimpleTable.TH>
                <SimpleTable.TH>Instance Type</SimpleTable.TH>
                <SimpleTable.TH>Status</SimpleTable.TH>
                <SimpleTable.TH />
              </SimpleTable.Row>
            </SimpleTable.Head>
            <SimpleTable.Body>
              {filteredBlockchains.map((blockchain, i) => (
                <SimpleTable.Row
                  key={i}
                  onClick={() => {
                    navigate(BlockchainUrl(blockchain.Id.toString()));
                  }}
                >
                  <SimpleTable.TD>
                    <Flex
                      items="center"
                      justify="between"
                      className="flex items-center flex-row justify-between space-x-3"
                    >
                      <HighlighterText
                        searchValue={searchValue}
                        textToHighlight={blockchain.ServiceName}
                        className="max-w-[110px] md:max-w-[400px] lg:max-w-[350px] xl:max-w-[400px] truncate"
                      />
                    </Flex>
                  </SimpleTable.TD>

                  <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {blockchain.ConsensusType}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {blockchain.ReplicaCounts}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {blockchain.PlanName}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD>
                    <WebAppStatusTag ServiceStatus={blockchain.ServiceStatus} />
                  </SimpleTable.TD>

                  <SimpleTable.TD className="w-32 gap-1">
                    <div className="h-full w-full inline-flex items-center justify-end gap-1 px-4">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          anchor="bottom end"
                          menu={[
                            {
                              key: "1",
                              label: (
                                <div className="flex items-center gap-1">
                                  <DetailsIcon className="w-4" />
                                  Get details
                                </div>
                              ),
                              onClick: () => {
                                navigate(WebAppUrl(blockchain.Id.toString()));
                              },
                              disabled: blockchainsLoading,
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: (_, close) => {
                                close();
                                setDeleteBlockchain(blockchain);
                              },
                              disabled: blockchainsLoading,
                            },
                          ]}
                        >
                          <Button
                            uiType="icon"
                            uiSize="small"
                            className="!px-2"
                          >
                            <MoreIcon className="w-4 h-4" />
                          </Button>
                        </Dropdown>
                      </div>
                      <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                    </div>
                  </SimpleTable.TD>
                </SimpleTable.Row>
              ))}

              {blockchains.length === 0 && (
                <SimpleTable.Row withoutHoverStyle className="h-[200px]">
                  <SimpleTable.TD colSpan={7} className="!border-0">
                    <EmptyState text="You have not created any blockchain yet" />
                  </SimpleTable.TD>
                </SimpleTable.Row>
              )}
            </SimpleTable.Body>
          </SimpleTable>
        </div>

        <DeleteModal
          title="Delete Blockchain"
          isOpen={deleteBlockchain !== null}
          onClose={() => setDeleteBlockchain(null)}
          description=""
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          onConfirm={() => {
            deleteBlockchain &&
              dispatch(
                deleteBlockchainsAsync({ id: deleteBlockchain.Id })
              ).then((action) => {
                if (action.type === "blockchains/delete/fulfilled") {
                  dispatch(getBlockchainsAsync({}));
                } else {
                }
                setDeleteBlockchain(null);
              });
          }}
          loading={blockchainsActionLoading}
          confirmString={deleteBlockchain?.ServiceName}
        />
      </div>
    </>
  );
};
export default BlockchainsPage;
