import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPageInfo, IThunkRejectValue, RootState } from "../../types";
import {
  BlockEvent,
  BlockEventHistory,
  BlockEventNetwork,
  BlockEventTemplate,
  BlockEventsState,
} from "../../types/block-events";
import {
  changeBlockEventStatusApi,
  deleteBlockEventApi,
  getBlockEventApi,
  getBlockEventHistoryApi,
  getBlockEventNetworksApi,
  getBlockEventTemplatesApi,
  getBlockEventsApi,
  updateBlockEventApi,
} from "../../apis/blockEventAPI";
import toast from "react-hot-toast";
import { getExtractErrorCode, getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

// const dummyBlockEvents: BlockEvent[] = [
//   {
//     Id: 7,
//     BlockEventId: "bbe92a80-2840-42f3-a9b4-6ad991abe658",
//     Created_at: "2024-03-04 00:49:43.175800",
//     Updated_at: "2024-03-04 00:49:43.175800",
//     Name: "My Notification",
//     Expression: "tx_to == '0xd8da6bf26964af9d7eed9e03e53415d37aa96045'",
//     Network: "ethereum-mainnet",
//     Enabled: 1,
//     Destinations: [
//       {
//         Id: 44,
//         Name: "My Destination",
//         To_url: "https://mydomain.com/webhook",
//         DestId: "1fc4e800-be48-44d6-b0e9-e3e152a40a63",
//         Service: "webhook",
//         Payload_type: 1,
//         Webhook_type: "POST",
//         Created_at: "",
//         Token: "dasda",
//         Updated_at: "",
//       },
//     ],
//   },
// ];

const initialState: BlockEventsState = {
  events: [],
  loading: false,
  actionLoading: false,

  //networks
  networks: [],
  networksLoading: false,

  //networks
  templates: [],
  templatesLoading: false,

  //single page
  blockEvent: null, //for: 1-single page, 2-edit name modal
  blockEventLoading: false,
  changeStatusLoading: false,
  showChangeNameModal: false,
  changeNameLoading: false,
  eventHistories: [],
  eventHistoriesPageInfo: { limit: 20, offset: 0, total: 0 },
  eventHistoryLoading: false,
  selectedHistory: null,
};

export const getBlockEventsAsync = createAsyncThunk<
  { events: BlockEvent[] },
  undefined,
  IThunkRejectValue
>("block-events", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getBlockEventsApi();

    const { Result } = response.data;
    const events = Result || [];

    return fulfillWithValue({ events });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getBlockEventAsync = createAsyncThunk<
  { event: BlockEvent },
  string,
  IThunkRejectValue
>(
  "block-event",
  async (blockEventId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getBlockEventApi(blockEventId);

      const { Result } = response.data;
      const event = Result;

      return fulfillWithValue({ event });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
        code: getExtractErrorCode(e),
      });
    }
  }
);

export const deleteBlockEventAsync = createAsyncThunk<
  any,
  { blockEventId: string },
  IThunkRejectValue
>("block-events/delete", async ({ blockEventId }, { rejectWithValue }) => {
  try {
    const res = await deleteBlockEventApi(blockEventId);
    return res.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateBlockEventNameAsync = createAsyncThunk<
  any,
  { newName: string; blockEvent: BlockEvent },
  IThunkRejectValue
>(
  "block-events/update/name",
  async ({ newName, blockEvent }, { rejectWithValue }) => {
    try {
      const response = await updateBlockEventApi(blockEvent.BlockEventId, {
        name: newName,
        expression: btoa(blockEvent.Expression),
        network: blockEvent.Network,
        destinationIds: blockEvent.Destinations.map((d) => d.DestId),
      });

      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const changeBlockEventStatusAsync = createAsyncThunk<
  boolean,
  { blockEventId: string; status: "disable" | "enable" },
  IThunkRejectValue
>(
  "block-events/update/status",
  async ({ blockEventId, status }, { rejectWithValue }) => {
    try {
      const res = await changeBlockEventStatusApi(blockEventId, status);
      return res.data;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const getBlockEventNetworksAsync = createAsyncThunk<
  { networks: BlockEventNetwork[] },
  undefined,
  IThunkRejectValue
>("block-events/networks", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getBlockEventNetworksApi();

    const { Result } = response.data;
    const networks = Result || [];

    return fulfillWithValue({ networks });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getBlockEventTemplatesAsync = createAsyncThunk<
  { templates: BlockEventTemplate[] },
  undefined,
  IThunkRejectValue
>(
  "block-events/templates",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getBlockEventTemplatesApi();

      const { Result } = response.data;
      const templates = Result || [];

      return fulfillWithValue({ templates });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getBlockEventHistoryAsync = createAsyncThunk<
  { histories: Array<BlockEventHistory>; pageInfo: IPageInfo },
  string,
  IThunkRejectValue
>(
  "block-events/history",
  async (blockEventId, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
      const { blockEvents } = getState() as RootState;
      const { eventHistoriesPageInfo } = blockEvents;
      const response = await getBlockEventHistoryApi(
        blockEventId,
        eventHistoriesPageInfo.limit,
        eventHistoriesPageInfo.offset
      );

      const { Result } = response.data;
      const histories = Result.data;
      const pageInfo = Result.pageInfo;

      return fulfillWithValue({ histories, pageInfo });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const blockEventsSlice = createSlice({
  name: "blockEvents",
  initialState,
  reducers: {
    setBlockEvent: (state, action: PayloadAction<BlockEvent>) => {
      state.blockEvent = action.payload;
      state.blockEventLoading = false;
    },
    handleShowBlockEventEditDetailModal: (state) => {
      state.showChangeNameModal = true;
    },
    handleHideBlockEventEditDetailModal: (state) => {
      state.showChangeNameModal = false;
    },
    handleClearSinglePage: (state) => {
      state.blockEvent = null;
      state.eventHistories = null;
      state.eventHistoryLoading = false;
    },
    handleShowBlockEventHistoryModal: (state, action: PayloadAction<any>) => {
      state.selectedHistory = action.payload;
    },
    handleHideBlockEventHistoryModal: (state) => {
      state.selectedHistory = null;
    },
    handleChangeBlockEventHistoriesOffset: (
      state,
      action: PayloadAction<number>
    ) => {
      state.eventHistoriesPageInfo.offset = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlockEventsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBlockEventsAsync.fulfilled, (state, action) => {
        const { events } = action.payload;
        state.events = events;
        state.loading = false;
      })
      .addCase(getBlockEventsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBlockEventAsync.pending, (state) => {
        state.blockEventLoading = true;
      })
      .addCase(getBlockEventAsync.fulfilled, (state, action) => {
        const { event } = action.payload;
        state.blockEvent = event;
        state.blockEventLoading = false;
      })
      .addCase(getBlockEventAsync.rejected, (state, { payload }) => {
        state.blockEventLoading = false;
        if (payload?.code !== 404)
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteBlockEventAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteBlockEventAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteBlockEventAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(changeBlockEventStatusAsync.pending, (state) => {
        state.changeStatusLoading = true;
      })
      .addCase(changeBlockEventStatusAsync.fulfilled, (state) => {
        state.changeStatusLoading = false;
      })
      .addCase(changeBlockEventStatusAsync.rejected, (state, { payload }) => {
        state.changeStatusLoading = false;

        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateBlockEventNameAsync.pending, (state) => {
        state.changeNameLoading = true;
      })
      .addCase(updateBlockEventNameAsync.fulfilled, (state) => {
        state.changeNameLoading = false;
      })
      .addCase(updateBlockEventNameAsync.rejected, (state, { payload }) => {
        state.changeNameLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBlockEventHistoryAsync.pending, (state) => {
        state.eventHistoryLoading = true;
      })
      .addCase(getBlockEventHistoryAsync.fulfilled, (state, action) => {
        state.eventHistoryLoading = false;
        state.eventHistories = action.payload.histories;
        state.eventHistoriesPageInfo = action.payload.pageInfo;
      })
      .addCase(getBlockEventHistoryAsync.rejected, (state, { payload }) => {
        state.eventHistoryLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBlockEventNetworksAsync.pending, (state) => {
        state.networksLoading = true;
      })
      .addCase(getBlockEventNetworksAsync.fulfilled, (state, action) => {
        state.networksLoading = false;
        state.networks = action.payload.networks;
      })
      .addCase(getBlockEventNetworksAsync.rejected, (state, { payload }) => {
        state.networksLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBlockEventTemplatesAsync.pending, (state) => {
        state.templatesLoading = true;
      })
      .addCase(getBlockEventTemplatesAsync.fulfilled, (state, action) => {
        state.templatesLoading = false;
        state.templates = action.payload.templates;
      })
      .addCase(getBlockEventTemplatesAsync.rejected, (state, { payload }) => {
        state.templatesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectBlockEvents = (state: RootState) => state.blockEvents.events;

export const selectBlockEventsLoading = (state: RootState) =>
  state.blockEvents.loading;

export const selectBlockEventsActionLoading = (state: RootState) =>
  state.blockEvents.actionLoading;

export const selectBlockEventNetwoks = (state: RootState) =>
  state.blockEvents.networks;
export const selectBlockEventNetwoksLoading = (state: RootState) =>
  state.blockEvents.networksLoading;

export const selectBlockEventTemplates = (state: RootState) =>
  state.blockEvents.templates;
export const selectBlockEventTemplatesLoading = (state: RootState) =>
  state.blockEvents.templatesLoading;

export const selectBlockEvent = (state: RootState) =>
  state.blockEvents.blockEvent;
export const selectBlockEventLoading = (state: RootState) =>
  state.blockEvents.blockEventLoading;
export const selectChangeStatusLoading = (state: RootState) =>
  state.blockEvents.changeStatusLoading;

export const selectShowBlockEventChangeNameModal = (state: RootState) =>
  state.blockEvents.showChangeNameModal;
export const selectBlockEventChangeNameLoading = (state: RootState) =>
  state.blockEvents.changeNameLoading;

export const selectBlockEventHistories = (state: RootState) =>
  state.blockEvents.eventHistories;
export const selectBlockEventHistoryLoading = (state: RootState) =>
  state.blockEvents.eventHistoryLoading;
export const selectBlockEventHistoriesPageInfo = (state: RootState) =>
  state.blockEvents.eventHistoriesPageInfo;

export const selectSelectedBlockEventHistory = (state: RootState) =>
  state.blockEvents.selectedHistory;

export const {
  setBlockEvent,
  handleShowBlockEventEditDetailModal,
  handleHideBlockEventEditDetailModal,
  handleClearSinglePage,
  handleShowBlockEventHistoryModal,
  handleHideBlockEventHistoryModal,
  handleChangeBlockEventHistoriesOffset,
} = blockEventsSlice.actions;
export default blockEventsSlice.reducer;
