import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import toast from "react-hot-toast";
import {
  MarketApiRequest,
  MarketApiOverviewType,
} from "../../types/market-api";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  getMarketApiRequestsApi,
  getMarketApiUsageApi,
} from "../../apis/marketApiAPI";

const initialState: MarketApiOverviewType = {
  usage: 0,
  usageLoading: false,
  requests: [],
  requestsLoading: false,
};

export const getMarketApiUsageAysnc = createAsyncThunk<
  number,
  undefined,
  IThunkRejectValue
>("market-api/usage", async (_, { fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await getMarketApiUsageApi();
    const usage = response.data.Result;
    return fulfillWithValue(usage);
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getMarketApiRequestsAysnc = createAsyncThunk<
  Array<MarketApiRequest>,
  undefined,
  IThunkRejectValue
>("market-api/requests", async (_, { fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await getMarketApiRequestsApi();
    const requests = response.data.Result;
    return fulfillWithValue(requests);
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

const marketApiOverviewSlice = createSlice({
  name: "marketApiOverview",
  initialState: initialState,
  reducers: {
    clearOverviewApiKeys(state) {
      state.requests = initialState.requests;
      state.requestsLoading = initialState.requestsLoading;
      state.usage = initialState.usage;
      state.usageLoading = initialState.usageLoading;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMarketApiUsageAysnc.pending, (state) => {
        state.usageLoading = true;
      })
      .addCase(getMarketApiUsageAysnc.fulfilled, (state, action) => {
        state.usage = action.payload;
        state.usageLoading = false;
      })
      .addCase(getMarketApiUsageAysnc.rejected, (state, { payload }) => {
        state.usageLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getMarketApiRequestsAysnc.fulfilled, (state, action) => {
        state.requests = action.payload;
        state.requestsLoading = false;
      })
      .addCase(getMarketApiRequestsAysnc.pending, (state) => {
        state.requestsLoading = true;
      })
      .addCase(getMarketApiRequestsAysnc.rejected, (state, { payload }) => {
        state.requestsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectMarketApiUsage = (state: RootState) =>
  state.marketApiApiOverview.usage;
export const selectMarketApiUsageLoading = (state: RootState) =>
  state.marketApiApiOverview.usageLoading;

export const selectMarketApiRequests = (state: RootState) =>
  state.marketApiApiOverview.requests;
export const selectMarketApiRequestsLoading = (state: RootState) =>
  state.marketApiApiOverview.requestsLoading;

export const { clearOverviewApiKeys } = marketApiOverviewSlice.actions;

export default marketApiOverviewSlice.reducer;
