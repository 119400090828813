import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from "react";
import { jwtEnvAxios } from "../apis";

export interface AiChat {
  id: number;
  role: "user" | "ai";
  content: string;
  date: number;
}

export interface AiThread {
  created_at: number;
  id: string;
  metadata: any;
  object: "thread";
  tool_resources: any;
}

export interface AiMessage {
  assistant_id: null | number;
  attachments: Array<any>;
  content: {
    text: {
      annotations: Array<any>;
      value: string;
    };
    type: "text";
  }[];
  created_at: number;
  id: string;
  metadata: any;
  object: string;
  role: "user" | "assistant";
  run_id: null | string;
  thread_id: string;
}

export interface AiRun {
  assistant_id?: string;
  cancelled_at: null | number;
  completed_at: null | number;
  created_at: number;
  expires_at: number;
  failed_at: null | number;
  id: string;
  incomplete_details: null | any; //
  instructions?: string;
  last_error: null | any; //
  max_completion_tokens: null; //
  max_prompt_tokens: null; //
  metadata: {}; //
  model: string;
  object: "thread.run";
  parallel_tool_calls: boolean;
  reasoning_effort: null; //
  required_action: null; //
  response_format: {
    type: "text";
  };
  started_at: null | number;
  status: "queued" | "in_progress" | "completed";
  temperature: number;
  thread_id: string;
  tool_choice: "auto";
  tool_resources: {};
  tools: [
    {
      type: "code_interpreter";
    }
  ];
  top_p: number;
  truncation_strategy: {
    last_messages: null;
    type: "auto";
  };
  usage: null | {
    completion_tokens: number;
    completion_tokens_details: {
      reasoning_tokens: number;
    };
    prompt_token_details: {
      cached_tokens: number;
    };
    prompt_tokens: number;
    total_tokens: number;
  };
}

export interface AiParse {
  EnvironmentVariables: any[];
  imagename: string;
  imagetag: string;
  port: string;
  sourceCode: string;
}

interface AiChatContextType {
  chats: AiChat[];
  chatLoading: boolean;
  handleSendChat: (chat: string) => Promise<void>;

  thread: AiThread | null;
  threadLoading: boolean;
  handleSetThread: () => Promise<AiThread | undefined>;
  handleGetThread: (
    threadId: string,
    signal?: AbortSignal
  ) => Promise<AiMessage | undefined>;

  messages: AiMessage[];
  messagesLoading: boolean;
  handleSendMessage: (
    message: string,
    threadId: string,
    signal?: AbortSignal
  ) => Promise<AiMessage | undefined>;

  run: AiRun | null;
  runLoading: boolean;
  handleSetRun: (
    threadId: string,
    signal?: AbortSignal
  ) => Promise<AiRun | undefined>;
  handleGetRun: (
    runId: string,
    threadId: string,
    signal?: AbortSignal
  ) => Promise<AiRun | undefined>;

  parse: AiParse | null;
  parseLoading: boolean;
  handleSetParse: (
    appname: string,
    content: string,
    signal?: AbortSignal
  ) => Promise<AiParse | undefined>;

  handleClearAiContext: () => void;
}

const AiChatContext = createContext<AiChatContextType | undefined>(undefined);

export const AiChatProvider = ({ children }: { children: ReactNode }) => {
  const [chats, setChats] = useState<AiChat[]>([]);
  const [chatLoading, setChatLoading] = useState<boolean>(false);

  const [thread, setThread] = useState<AiThread | null>(null);
  const [threadLoading, setThreadLoading] = useState<boolean>(false);

  const [messages, setMessages] = useState<AiMessage[]>([]);
  const [messagesLoading, setMessagesLoading] = useState<boolean>(false);

  const [run, setRun] = useState<AiRun | null>(null);
  const [runLoading, setRunLoading] = useState<boolean>(false);

  const [parse, setParse] = useState<AiParse | null>(null);
  const [parseLoading, setParseLoading] = useState<boolean>(false);

  //chats
  const handleSendChat = useCallback(async (chat: string) => {
    setChatLoading(true);
    try {
      const userMessage: AiChat = {
        id: Date.now(),
        role: "user",
        content: chat,
        date: Math.floor(Date.now() / 1000),
      };
      setChats((prev) => [...prev, userMessage]);

      const response = await jwtEnvAxios("ai").post(
        "/chat",
        JSON.stringify({ message: chat }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const aiMessage: AiChat = {
        id: Date.now() + 1,
        role: "ai",
        content: response.data.response,
        date: Math.floor(Date.now() / 1000),
      };
      setChats((prev) => [...prev, aiMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setChatLoading(false);
    }
  }, []);

  //thread
  const handleSetThread = useCallback(async () => {
    setThreadLoading(true);
    try {
      const response = await jwtEnvAxios("ai").post(
        "/thread",
        JSON.stringify({}),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const thread: AiThread = response.data.response;
      setThread(thread);
      return thread;
    } catch (error) {
      console.error("Error setting thread:", error);
    } finally {
      setThreadLoading(false);
    }
  }, []);

  const handleGetThread = useCallback(
    async (threadId: string, signal?: AbortSignal) => {
      setThreadLoading(true);
      try {
        const response = await jwtEnvAxios("ai").get(`/thread/${threadId}`, {
          headers: {
            "Content-Type": "application/json",
          },
          signal,
        });
        const messagesData: AiMessage[] = response.data.response.data;
        const lastAssistantMessage = messagesData
          .slice()
          .find((msg) => msg.assistant_id);
        const messages: AiMessage[] = messagesData.reverse();

        setMessages(messages);
        return lastAssistantMessage;
      } catch (error) {
        console.error("Error getting thread:", error);
      } finally {
        setThreadLoading(false);
      }
    },
    []
  );

  //run
  const handleSetRun = useCallback(
    async (threadId: string, signal?: AbortSignal) => {
      setRunLoading(true);
      try {
        const response = await jwtEnvAxios("ai").post(
          "/run",
          JSON.stringify({ threadId }),
          {
            headers: {
              "Content-Type": "application/json",
            },
            signal,
          }
        );
        const run: AiRun = response.data.response;
        setRun(run);
        return run;
      } catch (error) {
        console.error("Error setting run:", error);
      } finally {
        setRunLoading(false);
      }
    },
    []
  );

  const handleGetRun = useCallback(
    async (runId: string, threadId: string, signal?: AbortSignal) => {
      setRunLoading(true);
      try {
        const response = await jwtEnvAxios("ai").get(
          `/run/${runId}/${threadId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            signal,
          }
        );
        const run: AiRun = response.data.response;
        setRun(run);
        return run;
      } catch (error) {
        console.error("Error getting thread:", error);
      } finally {
        setRunLoading(false);
      }
    },
    []
  );

  //messages
  const handleSendMessage = useCallback(
    async (message: string, threadId: string, signal?: AbortSignal) => {
      setMessagesLoading(true);
      try {
        const response = await jwtEnvAxios("ai").post(
          "/message",
          JSON.stringify({ message, threadId }),
          {
            headers: {
              "Content-Type": "application/json",
            },
            signal,
          }
        );
        const messageRes: AiMessage = response.data.response;
        setMessages((prev) => [...prev, messageRes]);
        return messageRes;
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        setMessagesLoading(false);
      }
    },
    []
  );

  //parse
  const handleSetParse = useCallback(
    async (appname: string, content: string, signal?: AbortSignal) => {
      setParseLoading(true);
      try {
        const response = await jwtEnvAxios("ai").post(
          "/parse",
          JSON.stringify({ appname, content }),
          {
            headers: {
              "Content-Type": "application/json",
            },
            signal,
          }
        );
        const parse: AiParse = response.data.response;
        setParse(parse);
        return parse;
      } catch (error) {
        console.error("Error parsing:", error);
      } finally {
        setParseLoading(false);
      }
    },
    []
  );

  //general
  const handleClearAiContext = useCallback(() => {
    setChats([]);
    setThread(null);
    setMessages([]);
    setRun(null);
    setParse(null);
  }, []);

  return (
    <AiChatContext.Provider
      value={{
        //chats
        chats,
        chatLoading,
        handleSendChat,

        //thread
        thread,
        threadLoading,
        handleSetThread,
        handleGetThread,

        //messages
        messages,
        messagesLoading,
        handleSendMessage,

        //run
        run,
        runLoading,
        handleSetRun,
        handleGetRun,

        //pars
        parse,
        parseLoading,
        handleSetParse,

        //
        handleClearAiContext,
      }}
    >
      {children}
    </AiChatContext.Provider>
  );
};

export const useAiChatContext = () => {
  const context = useContext(AiChatContext);
  if (!context) {
    throw new Error("useAiChatContext must be used within a AiChatProvider");
  }
  return context;
};
