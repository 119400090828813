import { EmptyState, Flex, Loading } from "djuno-design";
import { useAppSelector } from "../../../hooks";
import {
  selectWebApp,
  selectWebAppLoading,
} from "../../../store/web-app/webAppSlice";
import MarkdownText from "../../general/MarkdownText";

const WebAppSourceCodeTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);
  return (
    <>
      {webAppLoading && (
        <Flex items="center" justify="center" className="w-full !min-h-[300px]">
          <Loading borderSize={2} />
        </Flex>
      )}
      {!webAppLoading && webApp && !webApp.WebAppSourceCode && (
        <EmptyState className="w-full !min-h-[300px]" />
      )}
      {!webAppLoading && webApp && webApp.WebAppSourceCode && (
        <div className="mt-5 w-full p-2 md:p-4 rounded-3xl !text-sm text-gray-800 dark:text-slate-100">
          <MarkdownText text={webApp.WebAppSourceCode} />
        </div>
      )}
    </>
  );
};

export default WebAppSourceCodeTab;
