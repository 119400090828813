import { jwtEnvAxios } from ".";
import { CreateWalletApiApiKeyData } from "../types/wallet-api";

const GET_WALLET_API_API_KEYS_URL = `/wallets/api-settings`;
const DELETE_WALLET_API_API_KEYS_URL = (id: number) =>
  `/wallets/api-settings/${id}`;
const CREATE_WALLET_API_API_KEYS_URL = `/wallets/api-settings`;
const UPDATE_WALLET_API_API_KEYS_URL = (id: number) =>
  `/wallets/api-settings/${id}`;

//overview
export function getWalletApiUsageApi() {
  return jwtEnvAxios().get(`/wallets/usage`);
}

export function getWalletApiRequestsApi() {
  return jwtEnvAxios().get(`/wallets/recent-requests`);
}

//api keys
export function getWalletApiApiKeysApi() {
  return jwtEnvAxios().get(GET_WALLET_API_API_KEYS_URL);
}

export function deleteWalletApiApiKeyApi(id: number) {
  return jwtEnvAxios().delete(DELETE_WALLET_API_API_KEYS_URL(id));
}

export async function createWalletApiApiKeyApi(
  formData: CreateWalletApiApiKeyData
) {
  return jwtEnvAxios().post(CREATE_WALLET_API_API_KEYS_URL, formData);
}

export function updateWalletApiApiKeyApi(
  id: number,
  formData: CreateWalletApiApiKeyData
) {
  return jwtEnvAxios().put(UPDATE_WALLET_API_API_KEYS_URL(id), formData);
}
