import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import {
  Web3FieldType,
  Web3Network,
  Web3PublicStateType,
} from "../../types/web3-auth";
import {
  getWeb3AuthFieldTypesApi,
  getWeb3AuthNetworksApi,
} from "../../apis/web3AuthAPI";
import toast from "react-hot-toast";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: Web3PublicStateType = {
  networks: [],
  loading: false,
  fieldTypes: [],
  fieldTypesLoading: false,
};

export const getWeb3NetworksAsync = createAsyncThunk<
  { networks: Web3Network[] },
  undefined,
  IThunkRejectValue
>("web3-auth-networks", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getWeb3AuthNetworksApi();
    const networks = response.data.Result;

    return fulfillWithValue({ networks });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getWeb3FieldTypesAsync = createAsyncThunk<
  { fieldTypes: Web3FieldType[] },
  undefined,
  IThunkRejectValue
>("web3-auth-field-types", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getWeb3AuthFieldTypesApi();
    const fieldTypes = response.data.Result;

    return fulfillWithValue({ fieldTypes });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

const web3PublicSlice = createSlice({
  name: "web3-public",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWeb3NetworksAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWeb3NetworksAsync.fulfilled, (state, action) => {
        state.networks = action.payload.networks;
        state.loading = false;
      })
      .addCase(getWeb3NetworksAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWeb3FieldTypesAsync.pending, (state) => {
        state.fieldTypesLoading = true;
      })
      .addCase(getWeb3FieldTypesAsync.fulfilled, (state, action) => {
        state.fieldTypes = action.payload.fieldTypes;
        state.fieldTypesLoading = false;
      })
      .addCase(getWeb3FieldTypesAsync.rejected, (state, { payload }) => {
        state.fieldTypesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectWeb3Networks = (state: RootState) =>
  state.web3Public.networks;
export const selectWeb3NetworksLoading = (state: RootState) =>
  state.web3Public.loading;

export const selectWeb3FiledTypes = (state: RootState) =>
  state.web3Public.fieldTypes;
export const selectWeb3FieldTypesLoading = (state: RootState) =>
  state.web3Public.fieldTypesLoading;

// export const { changeS3ActiveStatus } = web3PublicSlice.actions;
export default web3PublicSlice.reducer;
