import { useEffect, useMemo, useState } from "react";
import { Control, Controller, useController } from "react-hook-form";
import {
  InstanceBillingPeriod,
  InstanceFlavor,
  InstanceImage,
  InstanceImageGroup,
  InstanceImageGroups,
  InstanceModelCategory,
  OvhRegion,
} from "../../../types/instance";
import { Slider, Tabs, tabsClasses } from "@mui/material";
import { MuiTabItem, MuiTabPanel, a11yProps } from "../../general/MuiTab";
import { RadioGroup } from "@headlessui/react";
import {
  getModelIndexByFlavor,
  getModels,
  makeAppsImagesGroup,
  makeBackupImagesGroup,
  makeBillingPeriodsOptions,
  makeImagesCategory,
  makeModelGroup,
  makeUnixImagesGroup,
  makeWindowsImagesGroup,
  regionsData,
} from "./utils";
import { humanizeSize } from "../../../utils/file";
import { AnimatePresence, motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getInstancesImagesAsync,
  getInstancesSnapshotsAsync,
  handleClearInstanceImages,
  selectInstancesImages,
  selectInstancesImagesLoading,
  selectInstancesSnapshots,
  // selectInstancesSnapshotsLoading,
} from "../../../store/instances/instancesSlice";
import {
  getInstancesSshKeysAsync,
  handleInstancesSshKeyEditor,
  selectInstancesSshKeys,
  selectInstancesSshKeysLoading,
} from "../../../store/instances/instancesSSHkeysSlice";
import { DBSAddon } from "../../../types/database";
import { getDBSPriceAmout } from "../../databases/ServiceCreateComponents";
import {
  Alert,
  AnimatedFormError,
  Button,
  Checkbox,
  cn,
  Divider,
  Flex,
  Input,
  Loading,
  Select,
  SelectOption,
  Switcher,
  Tag,
  Textarea,
  Tooltip,
  Typography,
} from "djuno-design";
import { formatTimestamp } from "../../../utils/date";
import useLimitations from "../../../hooks/useLimitations";

export const InstanceModelInput: React.FC<{
  models: InstanceModelCategory[];
  addons: DBSAddon[];
  control: Control<any>;
  errorMessage: string | undefined;
  selectedFlavor?: InstanceFlavor;
  currentFlavor?: InstanceFlavor;
  loading?: boolean;
}> = ({
  models,
  addons,
  control,
  errorMessage,
  selectedFlavor,
  currentFlavor,
  loading,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (_: any, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (selectedFlavor) {
      setTabValue(getModelIndexByFlavor(selectedFlavor));
    }
  }, [selectedFlavor]);

  const { planPriceLimit, planPriceLimitChecker } = useLimitations({
    cases: ["PlanPrice"],
    existingServices: [],
  });

  return (
    <Controller
      name="selectedFlavor"
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
            >
              {models.map((model, index) => (
                <MuiTabItem
                  key={index}
                  label={model.title}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
            {!loading && (
              <RadioGroup value={value || null} onChange={onChange}>
                <div className="flex flex-col w-full mt-3">
                  {models.map((model, index) => {
                    return (
                      <MuiTabPanel value={tabValue} index={index} key={index}>
                        <div className="mb-5 mt-1">
                          <Typography.Text className="!text-sm">
                            {model.description}
                          </Typography.Text>
                        </div>
                        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
                          {makeModelGroup(model.flavors, model.keys).map(
                            (flavor, i) => {
                              const _addon = addons.find(
                                (a) => a.planCode === flavor.planCodes.hourly
                              );
                              // console.log(flavor.name, _addon);
                              const technical = _addon?.blobs?.technical;
                              if (!technical) return null;

                              const tags = _addon?.blobs?.tags;

                              const isLessDisk =
                                currentFlavor &&
                                currentFlavor.disk > flavor.disk;

                              const isLessQuota =
                                currentFlavor && flavor.quota === 0;

                              //----------------billing and pricing---------------
                              const billingOptions =
                                makeBillingPeriodsOptions(flavor);

                              let hourlyPrice: null | number = null;
                              let monthlyPrice: null | number = null;

                              const hourlyBillingPeriod = billingOptions.find(
                                (o) => o.value === "hourly"
                              );
                              const monthlyBillingPeriod = billingOptions.find(
                                (o) => o.value === "monthly"
                              );

                              if (hourlyBillingPeriod) {
                                const hourlyAddon = addons.find(
                                  (a) => a.planCode === hourlyBillingPeriod.id
                                );
                                if (hourlyAddon)
                                  hourlyPrice = hourlyAddon.pricings[0].price;
                              }

                              if (monthlyBillingPeriod) {
                                const monthlyAddon = addons.find(
                                  (a) => a.planCode === monthlyBillingPeriod.id
                                );
                                if (monthlyAddon)
                                  monthlyPrice = monthlyAddon.pricings[0].price;
                              }

                              const totalPriceInMonth =
                                monthlyPrice !== null
                                  ? monthlyPrice
                                  : hourlyPrice !== null
                                  ? hourlyPrice //* 720
                                  : 0;
                              const isExceedPrice = planPriceLimitChecker(
                                totalPriceInMonth / 10 ** 8
                              );
                              //

                              return (
                                <Tooltip
                                  content={
                                    isExceedPrice
                                      ? planPriceLimit?.LimitationMessage
                                      : ""
                                  }
                                  className="!text-xs"
                                >
                                  <RadioGroup.Option
                                    key={i}
                                    value={flavor.name}
                                    disabled={
                                      isLessDisk || isLessQuota || isExceedPrice
                                    }
                                  >
                                    {({ checked, disabled }) => {
                                      return (
                                        <div
                                          className={cn(
                                            "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/30 bg-white p-4 shadow transition-all duration-300 cursor-pointer h-full",
                                            {
                                              "border-primary-400 dark:border-primary-400":
                                                checked,
                                              "hover:shadow-lg": !disabled,
                                              "!cursor-not-allowed opacity-60":
                                                disabled,
                                            }
                                          )}
                                        >
                                          <div className="flex items-center justify-between mb-2">
                                            <Typography.Text className="!text-sm">
                                              {flavor.name.toUpperCase()}
                                            </Typography.Text>
                                            {tags &&
                                              tags.includes("is_new") && (
                                                <Tag
                                                  className="text-xs"
                                                  color="processing"
                                                >
                                                  New
                                                </Tag>
                                              )}
                                          </div>
                                          <div className="flex flex-col gap-1 pt-2">
                                            {technical.memory && (
                                              <Typography.Text
                                                className="!text-xs mt-1"
                                                uiType="secondary"
                                              >
                                                {`${humanizeSize(
                                                  technical.memory.size *
                                                    10 ** 9,
                                                  {
                                                    binaryBaseValue: 1000,
                                                  }
                                                ).join("B")} RAM`}
                                              </Typography.Text>
                                            )}

                                            {technical.cpu && (
                                              <Typography.Text
                                                className="!text-xs mt-1"
                                                uiType="secondary"
                                              >
                                                {`${technical.cpu.cores} ${technical.cpu.type} (${technical.cpu.frequency} GHz)`}
                                              </Typography.Text>
                                            )}

                                            {technical.storage &&
                                              technical.storage.disks &&
                                              technical.storage.disks
                                                .length && (
                                                <Typography.Text
                                                  className="!text-xs mt-1"
                                                  uiType="secondary"
                                                >
                                                  {`${humanizeSize(
                                                    technical.storage.disks[0]
                                                      .capacity *
                                                      10 ** 9,
                                                    {
                                                      binaryBaseValue: 1000,
                                                    }
                                                  ).join("B")} ${
                                                    technical.storage.disks[0]
                                                      .technology ||
                                                    technical.storage.disks[0]
                                                      .interface
                                                  }`}
                                                </Typography.Text>
                                              )}

                                            {technical.nvme &&
                                              technical.nvme.disks &&
                                              technical.nvme.disks.length && (
                                                <Typography.Text
                                                  className="!text-xs mt-1"
                                                  uiType="secondary"
                                                >
                                                  {`${
                                                    technical.nvme.disks[0]
                                                      .number &&
                                                    technical.nvme.disks[0]
                                                      .number > 1
                                                      ? `${technical.nvme.disks[0].number} x `
                                                      : ""
                                                  } ${humanizeSize(
                                                    technical.nvme.disks[0]
                                                      .capacity *
                                                      10 ** 9,
                                                    {
                                                      binaryBaseValue: 1000,
                                                      fractionDigits: 2,
                                                    }
                                                  ).join("B")} NVMe`}
                                                </Typography.Text>
                                              )}

                                            {technical.bandwidth && (
                                              <Typography.Text
                                                className="!text-xs mt-1"
                                                uiType="secondary"
                                              >
                                                {`${technical.bandwidth.level} Mbit/s`}
                                              </Typography.Text>
                                            )}
                                          </div>
                                          <div className="flex flex-col border-t border-slate-200 dark:border-gray-400/40 mt-2 pt-2 gap-0.5">
                                            {monthlyPrice !== null && (
                                              <div className="flex items-center">
                                                <Typography.Text className="!text-xs mr-1 !font-semibold">
                                                  From{" "}
                                                </Typography.Text>

                                                <Typography.Text className="!text-xs !font-semibold">
                                                  €
                                                  {getDBSPriceAmout(
                                                    monthlyPrice
                                                  )}{" "}
                                                  ex. VAT
                                                </Typography.Text>
                                                <Typography.Text className="!text-xs !font-semibold">
                                                  / month
                                                </Typography.Text>
                                              </div>
                                            )}

                                            {hourlyPrice !== null && (
                                              <div className="flex items-center">
                                                <Typography.Text className="!text-xs font-medium">
                                                  €
                                                  {getDBSPriceAmout(
                                                    hourlyPrice
                                                  )}{" "}
                                                  ex. VAT
                                                </Typography.Text>
                                                <Typography.Text className="!text-xs font-medium">
                                                  / hour
                                                </Typography.Text>
                                              </div>
                                            )}
                                          </div>
                                          {isLessQuota && (
                                            <div className="flex items-center border-t mt-2 pt-2 gap-0.5">
                                              <Alert
                                                className="!text-xs font-medium"
                                                uiType="error"
                                              >
                                                Your project quota will not
                                                allow you to add this machine
                                                type in this region
                                              </Alert>
                                            </div>
                                          )}
                                          {isLessDisk && (
                                            <div className="flex items-center border-t mt-2 pt-2 gap-0.5">
                                              <Typography.Text className="!text-xs font-medium">
                                                The disk space is lower than
                                                that of the current model
                                              </Typography.Text>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }}
                                  </RadioGroup.Option>
                                </Tooltip>
                              );
                            }
                          )}
                        </div>
                      </MuiTabPanel>
                    );
                  })}
                  <AnimatedFormError error={errorMessage} />
                </div>
              </RadioGroup>
            )}

            {loading && (
              <Flex items="center" justify="center" className="!min-h-[100px]">
                <Loading borderSize={2} />
              </Flex>
            )}
          </>
        );
      }}
    />
  );
};

export const InstanceRegionInput: React.FC<{
  regions: OvhRegion[];
  flavors: InstanceFlavor[];
  selectedFlavor: InstanceFlavor;
  control: Control<any>;
  errorMessage: string | undefined;
  loading?: boolean;
  handleEditModelSelection: () => void;
  disabled?: boolean;
}> = ({
  regions,
  flavors,
  selectedFlavor,
  control,
  errorMessage,
  loading,
  handleEditModelSelection,
  disabled,
}) => {
  const [showUnavalibles, setShowUnavalibles] = useState(false);
  // const [tabValue, setTabValue] = useState(0);
  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setTabValue(newValue);
  // };

  const selectedModelFlavors = useMemo(() => {
    return getModels(flavors, selectedFlavor.name);
  }, [flavors, selectedFlavor]);

  const activeRegions = useMemo(() => {
    return regions
      .filter((r) => r.services.find((s) => s.name === "instance"))
      .filter((r) => r.status === "UP");
  }, [regions]);

  const avalibleRegions = useMemo(() => {
    return activeRegions.filter((r) =>
      selectedModelFlavors.map((f) => f.region).includes(r.name)
    );
  }, [activeRegions, selectedModelFlavors]);

  const unavalibleRegions = useMemo(() => {
    return activeRegions.filter(
      (r) => !selectedModelFlavors.map((f) => f.region).includes(r.name)
    );
  }, [activeRegions, selectedModelFlavors]);

  return (
    <Controller
      name="selectedRegion"
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            {/* <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
              >
                {models.map((model, index) => (
                  <MuiTabItem label={model.title} {...a11yProps(index)} />
                ))}
              </Tabs> */}
            {!loading && (
              <RadioGroup
                value={value || null}
                disabled={disabled}
                onChange={onChange}
              >
                <div className="flex flex-col w-full mt-3">
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
                    {avalibleRegions.map((region, i) => (
                      <RadioGroup.Option key={i} value={region.name}>
                        {({ checked, disabled }) => {
                          const regionData = regionsData.find(
                            (rd) =>
                              rd.datacenterLocation ===
                              region.datacenterLocation
                          );
                          return (
                            <div
                              className={cn(
                                "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                                {
                                  "border-primary-400 dark:border-primary-400":
                                    checked,
                                  "!cursor-not-allowed": disabled,
                                }
                              )}
                            >
                              <div className="flex items-center justify-between mb-2">
                                <Typography.Text className="!text-sm">
                                  {regionData ? regionData.title : region.name}
                                </Typography.Text>
                                {regionData && regionData.dataImage && (
                                  <span
                                    style={{
                                      backgroundImage: `url(${regionData.dataImage})`,
                                      backgroundSize: "contain",
                                      backgroundPosition: "50%",
                                    }}
                                    className="w-4 h-4 inline-block align-middle bg-no-repeat"
                                  />
                                )}
                              </div>
                              <div className="flex flex-col border-t border-slate-200 dark:border-gray-400/40 mt-2 pt-2 gap-0.5">
                                <Typography.Text
                                  uiType="secondary"
                                  className="!text-xs"
                                >
                                  {region.name.toUpperCase()}
                                </Typography.Text>
                              </div>
                            </div>
                          );
                        }}
                      </RadioGroup.Option>
                    ))}

                    {unavalibleRegions.length > 0 && (
                      <>
                        <div className="col-span-full my-1 flex items-center gap-0.5">
                          <Switcher
                            value={showUnavalibles}
                            onChange={setShowUnavalibles}
                          />
                          <Typography.Text className="!text-xs">
                            Show unavailable regions
                          </Typography.Text>
                        </div>
                        {showUnavalibles && (
                          <>
                            {unavalibleRegions.map((region, i) => (
                              <RadioGroup.Option
                                key={i}
                                value={region.name}
                                disabled
                              >
                                {({ checked, disabled }) => {
                                  const regionData = regionsData.find(
                                    (rd) =>
                                      rd.datacenterLocation ===
                                      region.datacenterLocation
                                  );
                                  return (
                                    <div
                                      className={cn(
                                        "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                                        {
                                          "border-primary-400 dark:border-primary-400":
                                            checked,
                                          "!cursor-not-allowed opacity-60":
                                            disabled,
                                        }
                                      )}
                                    >
                                      <div className="flex items-center justify-between mb-2">
                                        <Typography.Text className="!text-sm">
                                          {regionData
                                            ? regionData.title
                                            : region.name}
                                        </Typography.Text>
                                        {regionData && regionData.dataImage && (
                                          <span
                                            style={{
                                              backgroundImage: `url(${regionData.dataImage})`,
                                              backgroundSize: "contain",
                                              backgroundPosition: "50%",
                                            }}
                                            className="w-4 h-4 inline-block align-middle bg-no-repeat"
                                          />
                                        )}
                                      </div>
                                      <div className="flex flex-col border-t border-slate-200 dark:border-gray-400/40 mt-2 pt-2 gap-0.5">
                                        <Typography.Text
                                          uiType="secondary"
                                          className="!text-xs"
                                        >
                                          {region.name.toUpperCase()}
                                        </Typography.Text>
                                      </div>
                                    </div>
                                  );
                                }}
                              </RadioGroup.Option>
                            ))}
                            <div className="col-span-full flex items-center justify-between">
                              <Typography.Text className="!text-sm">
                                These regions are available for other models.
                              </Typography.Text>
                              <Typography.Link
                                className="!text-sm"
                                onClick={handleEditModelSelection}
                              >
                                Change model
                              </Typography.Link>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <AnimatedFormError error={errorMessage} />
                </div>
              </RadioGroup>
            )}

            {loading && <Loading borderSize={2} className="!min-h-[100px]" />}
          </>
        );
      }}
    />
  );
};

export const InstanceImageInput: React.FC<{
  selectedRegion: string;
  selectedFlavor: InstanceFlavor;
  selectedImage?: InstanceImage;
  control: Control<any>;
  errorMessage?: string;
  versionsErrorMessage?: string;
}> = ({
  selectedRegion,
  selectedFlavor,
  selectedImage,
  control,
  errorMessage,
  versionsErrorMessage,
}) => {
  const dispatch = useAppDispatch();

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (_: any, newValue: number) => {
    setTabValue(newValue);
  };

  const images = useAppSelector(selectInstancesImages);
  const imagesLoading = useAppSelector(selectInstancesImagesLoading);

  const snapshots = useAppSelector(selectInstancesSnapshots);
  // const snapshotsLoading = useAppSelector(selectInstancesSnapshotsLoading);

  //get all images data from API via the selected region
  useEffect(() => {
    if (selectedRegion && selectedRegion !== "") {
      dispatch(getInstancesImagesAsync({ region: selectedRegion }));
    }
    return () => {
      dispatch(handleClearInstanceImages());
    };
  }, [dispatch, selectedRegion]);

  useEffect(() => {
    dispatch(getInstancesSnapshotsAsync({}));
  }, [dispatch]);

  const {
    field: { onChange: onChangeSelectedImageId, value: selectedImageId },
  } = useController({ name: "selectedImageId", control });

  const {
    field: {
      value: selectedImageGroupId,
      onChange: onChangeSelectedImageGroupId,
    },
  } = useController({ name: "selectedImageGroupId", control });

  //generate images by categories
  const imagesCategory = useMemo(() => {
    return makeImagesCategory(images, snapshots);
  }, [images, snapshots]);

  //calculate selected flavor is a baremetal or not
  const isBaremetal = useMemo(() => {
    return selectedFlavor.osType === "baremetal-linux";
  }, [selectedFlavor.osType]);

  //create an array of images in eache category
  const imagesGroupArr: InstanceImageGroups[] = useMemo(() => {
    let groups = [
      makeUnixImagesGroup(imagesCategory[0].images, isBaremetal),
      makeWindowsImagesGroup(imagesCategory[1].images, selectedFlavor.name),
      makeAppsImagesGroup(imagesCategory[2].images, isBaremetal),
    ];
    if (snapshots && snapshots.length) {
      groups = [
        ...groups,
        makeBackupImagesGroup(imagesCategory[3].images, selectedFlavor.region),
      ];
    }
    return groups;
  }, [imagesCategory, isBaremetal, selectedFlavor, snapshots]);

  const defaultImageGroup = useMemo(() => {
    let defaultGroup: InstanceImageGroup | undefined;
    let defaultTabValue: number | undefined;
    imagesGroupArr.forEach((iga, i) => {
      iga.avalibleImagesGroup.forEach((ig) => {
        const findImage = ig.images.find((i) => i.id === selectedImage?.id);
        if (findImage) {
          defaultGroup = ig;
          defaultTabValue = i;
        }
      });
    });
    return { defaultGroup, defaultTabValue };
  }, [imagesGroupArr, selectedImage]);

  useEffect(() => {
    if (defaultImageGroup.defaultGroup) {
      setTabValue(defaultImageGroup.defaultTabValue || 0);
      onChangeSelectedImageGroupId(defaultImageGroup.defaultGroup.id);
      if (selectedImage) {
        onChangeSelectedImageId(selectedImage.id);
      }
    }
  }, [
    defaultImageGroup.defaultGroup,
    defaultImageGroup.defaultTabValue,
    onChangeSelectedImageGroupId,
    onChangeSelectedImageId,
    selectedImage,
  ]);

  //get images for each tab of the category from tabValue state
  const imagesGroup: InstanceImageGroups = useMemo(() => {
    return imagesGroupArr[tabValue];
  }, [imagesGroupArr, tabValue]);

  //generate deffrent versions of each image from selected group
  const versionOptions: SelectOption[] = useMemo(() => {
    let verions: SelectOption[] = [];
    for (const arr of imagesGroupArr) {
      const group = arr.avalibleImagesGroup.find(
        (ig) => ig.id === selectedImageGroupId
      );
      if (group) {
        verions = group.images.map((i) => ({ label: i.name, value: i.id }));
        break;
      }
    }
    return verions;
  }, [imagesGroupArr, selectedImageGroupId]);

  if (imagesLoading) {
    return (
      <Flex items="center" justify="center" className="!min-h-[100px] w-full">
        <Loading borderSize={2} />
      </Flex>
    );
  }
  return (
    <Controller
      name="selectedImageGroupId"
      control={control}
      render={({ field: { value, onChange } }) => {
        const handleChangeSelectedGroup = (groupValue: any) => {
          const selectedGroup = imagesGroup.avalibleImagesGroup.find(
            (ig) => ig.id === groupValue
          );
          if (selectedGroup && selectedGroup.images.length > 0) {
            onChangeSelectedImageId(selectedGroup.images[0].id);
          }
          onChange(groupValue);
        };
        return (
          <>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
            >
              {imagesCategory.map((cat, index) => (
                <MuiTabItem
                  key={index}
                  label={cat.title}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
            <RadioGroup
              value={value || null}
              onChange={handleChangeSelectedGroup}
            >
              <div className="flex flex-col w-full mt-3 mb-5">
                {imagesCategory.map((imageCat, index) => {
                  return (
                    <MuiTabPanel value={tabValue} index={index} key={index}>
                      <div className="mb-3">
                        <Typography.Text className="!text-sm">
                          {imageCat.description}
                        </Typography.Text>
                      </div>

                      <ImagesTab imagesGroup={imagesGroup} />
                    </MuiTabPanel>
                  );
                })}

                <AnimatedFormError error={errorMessage} />
              </div>
            </RadioGroup>
            <Flex className="w-full lg:w-1/3">
              <Select
                label="select version"
                options={versionOptions}
                value={selectedImageId?.toString()}
                onChange={onChangeSelectedImageId}
                emptyString="select a version"
                error={versionsErrorMessage}
                className="flex-1"
              />
            </Flex>
          </>
        );
      }}
    />
  );
};

export const InstanceConfigurationInput: React.FC<{
  selectedFlavor: InstanceFlavor;
  selectedFlexFlavor?: InstanceFlavor;
  selectedRegion: string;
  control: Control<any>;
  numberOfInstancesErrorMessage?: string;
  instanceNameError?: string;
  backupMode?: boolean;
}> = ({
  selectedFlavor,
  selectedFlexFlavor,
  selectedRegion,
  control,
  instanceNameError,
  // numberOfInstancesErrorMessage,
  backupMode,
}) => {
  const autoBackups = [
    {
      id: "1",
      title: "Rotation 7",
      description:
        "A backup is taken every day between 22:00 and 06:00, and the rotation maintains a log of the 7 latest entries. When the new backup is taken, the previous one is deleted.",
      data: {
        cron: "35 5 * * *",
        rotation: 7,
      },
    },
    {
      id: "2",
      title: "Rotation 14",
      description:
        "A backup is taken every day between 22:00 and 06:00, and the rotation maintains a log of the 14 latest entries. When the new backup is taken, the previous one is deleted.",
      data: {
        cron: "15 23 * * *",
        rotation: 14,
      },
    },
  ];

  const rotation7Data = autoBackups[0].data;

  const {
    field: { value: numberOfInstances, onChange: onChangeNumberOfInstances },
  } = useController({ name: "numberOfInstances", control });

  const {
    field: { value: isFlexible, onChange: onChangeIsFlexible },
  } = useController({ name: "isFlexible", control });

  const {
    field: { value: instanceName, onChange: onChangeInstanceName },
  } = useController({ name: "instanceName", control });

  const {
    field: { value: postInstallation, onChange: onChangePostInstallation },
  } = useController({ name: "postInstallation", control });

  const {
    field: { value: autobackup, onChange: onChangeAutobackup },
  } = useController({ name: "autobackup", control });
  const [useBackup, setUseBackup] = useState(autobackup !== null);

  const onStageFlavor = useMemo(() => {
    if (selectedFlexFlavor && isFlexible) {
      return selectedFlexFlavor;
    } else {
      return selectedFlavor;
    }
  }, [isFlexible, selectedFlavor, selectedFlexFlavor]);

  useEffect(() => {
    onChangeInstanceName(
      `${onStageFlavor.name}-${selectedRegion.toLowerCase()}`
    );
  }, [onChangeInstanceName, onStageFlavor, selectedRegion]);

  const handleChangeUseBackup = (status: boolean) => {
    setUseBackup(status);
    if (!status) {
      onChangeAutobackup(null);
    } else {
      onChangeAutobackup(rotation7Data);
    }
  };

  return (
    <>
      {onStageFlavor.quota > 1 && (
        <div className="flex flex-col gap-1 mb-9">
          <Typography.Text className="!text-sm">
            Number of instances
          </Typography.Text>

          <div className="w-full md:w-1/2">
            <Slider
              aria-label="Temperature"
              defaultValue={numberOfInstances}
              value={numberOfInstances}
              onChange={(_, value) => onChangeNumberOfInstances(value)}
              // getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              shiftStep={1}
              step={1}
              marks
              min={1}
              max={onStageFlavor.quota}
            />
          </div>
          <Typography.Text className="!text-xs">
            Under your current quota, you can create up to a maximum of{" "}
            {onStageFlavor.quota}
            simultaneous {onStageFlavor.name} instance(s) for the (
            {selectedRegion}) region.{" "}
            <Typography.Link className="!text-xs">
              View your quota
            </Typography.Link>
          </Typography.Text>
        </div>
      )}

      {selectedFlexFlavor && (
        <div className="flex flex-col gap-1 mb-9">
          <Checkbox
            value={isFlexible}
            onChange={onChangeIsFlexible}
            label={
              <div>
                <Typography.Text className="!text-sm">
                  Flexible instance
                </Typography.Text>
                <Typography.Text className="!text-xs">
                  The Flex option opts for 50GB storage, providing faster
                  snapshots and the ability to downgrade later on.
                </Typography.Text>
              </div>
            }
          />
        </div>
      )}

      <div className="flex flex-col gap-1 mb-9">
        <Input
          label="Instance name"
          value={instanceName}
          onChange={onChangeInstanceName}
          error={instanceNameError}
        />
      </div>

      {!backupMode && (
        <div className="flex flex-col gap-1 mb-9">
          <Textarea
            label="Post-installation script"
            value={postInstallation}
            onChange={onChangePostInstallation}
            rows={6}
          />
        </div>
      )}

      {!backupMode && (
        <div className="flex flex-col gap-1 mb-9">
          <Checkbox
            value={useBackup}
            onChange={handleChangeUseBackup}
            label={
              <Flex direction="col">
                <Typography.Text className="!text-sm">
                  Automatic instance backup
                </Typography.Text>
                <Typography.Text className="!text-xs">
                  You can use this feature to back up your instance
                  automatically, as often as you like.
                </Typography.Text>
              </Flex>
            }
          />{" "}
          {useBackup && (
            // <Controller
            //   name="autobackup"
            //   control={control}
            //   render={({ field: { value, onChange } }) => {
            //     return (
            //       <Box sx={{ width: "100%", typography: "body1" }}>
            //          <RadioGroup
            //           value={value || null}
            //           by="rotation"
            //           onChange={onChange}
            //         >
            //           <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5 mt-3">
            //             {autoBackups.map((autoBackup, i) => {
            //               return (
            //                 <RadioGroup.Option
            //                   key={i}
            //                   value={autoBackup.data}
            //                   disabled={false}
            //                 >
            //                   {({ checked, disabled }) => {
            //                     return (
            //                       <div
            //                         className={classNames(
            //                           "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
            //                           {
            //                             "border-primary-400 dark:border-primary-400":
            //                               checked,
            //                             "!cursor-not-allowed": disabled,
            //                           }
            //                         )}
            //                       >
            //                         <div className="flex flex-col gap-2">
            //                           <Text className="text-sm">
            //                             {autoBackup.title}
            //                           </Text>
            //                           <Text className="text-xs">
            //                             {autoBackup.description}
            //                           </Text>
            //                         </div>
            //                       </div>
            //                     );
            //                   }}
            //                 </RadioGroup.Option>
            //               );
            //             })}
            //           </div>
            //         </RadioGroup>

            //       </Box>
            //     );
            //   }}
            // />
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5 mt-3">
              <Typography.Text className="!text-xs">
                Enabling backups will be recurringly billed, for 20 % per month
                of your current plan, and Each backup will be billed at: €0.011
                ex. VAT/month/GB
              </Typography.Text>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const InstanceBillingInput: React.FC<{
  billingPeriodsOptions: InstanceBillingPeriod[];
  addons: DBSAddon[];
  numberOfInstances: number;
  control: Control<any>;
  currentValue?: string;
}> = ({
  billingPeriodsOptions,
  numberOfInstances,
  addons,
  control,
  currentValue,
}) => {
  return (
    <>
      <Controller
        name="billingPeriod"
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <div className="w-full">
              <RadioGroup value={value || null} onChange={onChange}>
                <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5 mt-3">
                  {billingPeriodsOptions.map((billingPeriod, i) => {
                    return (
                      <RadioGroup.Option
                        key={i}
                        value={billingPeriod.value}
                        disabled={
                          billingPeriod.value === "hourly" &&
                          currentValue === "monthly"
                        }
                      >
                        {({ checked, disabled }) => {
                          const addon = addons.find(
                            (a) => a.planCode === billingPeriod.id
                          );

                          const exVAT = addon
                            ? getDBSPriceAmout(addon.pricings[0].price)
                            : "0";
                          return (
                            <div
                              className={cn(
                                "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                                {
                                  "border-primary-400 dark:border-primary-400":
                                    checked,
                                  "!cursor-not-allowed opacity-60": disabled,
                                }
                              )}
                            >
                              <div className="flex flex-col gap-2">
                                <Typography.Text className="!text-sm">
                                  {billingPeriod.title}
                                </Typography.Text>
                              </div>
                              <div className="flex items-center border-t mt-2 pt-2 gap-0.5">
                                <Typography.Text className="!text-xs font-medium">
                                  €{numberOfInstances * Number(exVAT)} ex. VAT
                                </Typography.Text>
                                <Typography.Text className="!text-xs font-medium">
                                  /
                                  {billingPeriod.value === "hourly"
                                    ? "hour"
                                    : "month"}
                                </Typography.Text>
                              </div>
                            </div>
                          );
                        }}
                      </RadioGroup.Option>
                    );
                  })}
                </div>
              </RadioGroup>
            </div>
          );
        }}
      />
    </>
  );
};

export const ImagesTab: React.FC<{
  imagesGroup: InstanceImageGroups;
  type?: string;
  images?: Array<InstanceImage>;
}> = ({ imagesGroup }) => {
  const [showUnavalibles, setShowUnavalibles] = useState(false);
  return (
    <>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
        {imagesGroup.avalibleImagesGroup.map((group, i) => (
          <RadioGroup.Option key={i} value={group.id}>
            {({ checked, disabled }) => {
              return (
                <div
                  className={cn(
                    "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                    {
                      "border-primary-400 dark:border-primary-400": checked,
                      "!cursor-not-allowed": disabled,
                    }
                  )}
                >
                  <div className="flex items-center justify-between">
                    <Typography.Text className="!text-sm font-medium">
                      {group.title}
                    </Typography.Text>
                    {group.imageIcon}
                  </div>
                  {group.images.length > 0 && (
                    <Flex direction="col" className="w-full">
                      <Divider className="!m-0" />
                      <Typography.Text
                        className="!text-xs !truncate"
                        uiType="secondary"
                      >
                        {group.type && group.type === "backups"
                          ? formatTimestamp(
                              group.images[0].creationDate,
                              "MM/DD/YYYY HH:mm:ss",
                              { isUTC: false }
                            ).datetime
                          : group.images.map((i) => i.name).join(", ")}
                      </Typography.Text>
                    </Flex>
                  )}
                </div>
              );
            }}
          </RadioGroup.Option>
        ))}
      </div>

      {imagesGroup.unavalibleImagesGroup.length > 0 && (
        <>
          <div className="col-span-full my-3 flex items-center gap-0.5">
            <Switcher value={showUnavalibles} onChange={setShowUnavalibles} />
            <Typography.Text className="!text-xs">
              Show unavailable images
            </Typography.Text>
          </div>
          <AnimatePresence>
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              className="overflow-hidden"
            >
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 overflow-hidden">
                {showUnavalibles &&
                  imagesGroup.unavalibleImagesGroup.map((group, i) => (
                    <RadioGroup.Option key={i} value={group.id} disabled>
                      {({ checked, disabled }) => {
                        return (
                          <div
                            className={cn(
                              "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                              {
                                "border-primary-400 dark:border-primary-400":
                                  checked,
                                "!cursor-not-allowed opacity-60": disabled,
                              }
                            )}
                          >
                            <div className="flex items-center justify-between">
                              <Typography.Text className="!text-sm">
                                {group.title}
                              </Typography.Text>
                              {group.imageIcon}
                            </div>
                            {group.images.length > 0 && (
                              <Flex direction="col" className="w-full">
                                <Divider className="!m-0" />
                                <Typography.Text
                                  className="!text-xs truncate"
                                  uiType="secondary"
                                >
                                  {group.images.map((i) => i.name).join(", ")}
                                </Typography.Text>
                              </Flex>
                            )}
                          </div>
                        );
                      }}
                    </RadioGroup.Option>
                  ))}
              </div>
            </motion.div>
          </AnimatePresence>
        </>
      )}
    </>
  );
};

export const SSHkeyInput: React.FC<{
  control: Control<any>;
  selectedImage: InstanceImage;
}> = ({ control, selectedImage }) => {
  const [showingMode, setShowingMode] = useState<"hidden" | "show">("hidden");

  const sshKeys = useAppSelector(selectInstancesSshKeys);
  const sshKeysLoading = useAppSelector(selectInstancesSshKeysLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setShowingMode(selectedImage.type === "windows" ? "hidden" : "show");
  }, [selectedImage]);

  useEffect(() => {
    dispatch(getInstancesSshKeysAsync());
  }, [dispatch]);

  const sshOptions: SelectOption[] = useMemo(() => {
    return sshKeys.map((key) => ({ label: key.name, value: key.id }));
  }, [sshKeys]);

  if (sshKeysLoading) {
    return (
      <div className="mb-5">
        <Loading borderSize={2} />
      </div>
    );
  }

  if (showingMode === "hidden" || selectedImage.type === "windows") return null;
  return (
    <>
      <Flex direction="col" className="gap-1 mb-5">
        <Flex items="end" className="gap-2 mb-2 w-full lg:w-1/3">
          <Controller
            name="selectedSshKeyId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                label="SSH key"
                options={sshOptions}
                value={value?.toString() || undefined}
                onChange={onChange}
                className="!flex-1"
                buttonClassName="!h-9 !min-h-9 !py-0"
              />
            )}
          />
          <Button
            // uiSize="default"
            onClick={() =>
              dispatch(handleInstancesSshKeyEditor({ status: true }))
            }
          >
            Add a key
          </Button>
        </Flex>
        <Typography.Text className="!text-xs">
          SSH keys are required to connect to your service. Please refer to our{" "}
          <Typography.Link className="!text-xs">guide</Typography.Link> to find
          out more.
        </Typography.Text>
        <Typography.Text className="!text-xs">
          Only RSA and ECDSA SSH keys are accepted. You cannot use ED25519 SSH
          keys.
        </Typography.Text>
      </Flex>
    </>
  );
};

export const InstanceWorkflowInput: React.FC<{
  title: string;
  description: string;
  control: Control<any>;
  errorMessage?: string;
  initialSelected: string;
  handleEditModelSelection: () => void;
}> = ({
  title,
  description,
  control,
  initialSelected,
  errorMessage,
  handleEditModelSelection,
}) => {
  return (
    <Controller
      name="selectedSchedule"
      control={control}
      defaultValue={initialSelected}
      render={({ field: { value, onChange } }) => (
        <div className="w-full">
          <RadioGroup value={value || initialSelected} onChange={onChange}>
            <div className="flex flex-col w-full mt-3">
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
                <RadioGroup.Option key={title} value={initialSelected}>
                  {({ checked, disabled }) => (
                    <div
                      className={cn(
                        "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer",
                        {
                          "border-primary-400 dark:border-primary-400": checked,
                          "!cursor-not-allowed": disabled,
                        }
                      )}
                    >
                      <div className="flex items-center justify-between">
                        <Typography.Title level={6} className="!text-sm">
                          {title}
                        </Typography.Title>
                      </div>
                      <Typography.Text className="text-xs mt-2">
                        {description}
                      </Typography.Text>
                    </div>
                  )}
                </RadioGroup.Option>
              </div>
            </div>
          </RadioGroup>
          {errorMessage && (
            <p className="mt-2 text-xs text-red-600 dark:text-red-500">
              {errorMessage}
            </p>
          )}
        </div>
      )}
    />
  );
};

export const InstanceAutomaticConfigurationInput: React.FC<{
  control: Control<any>;
}> = ({ control }) => {
  const [customSelected, setCustomSelected] = useState(false);
  const [rotation, setRotation] = useState(1);
  const [maxExecutionCount, setMaxExecutionCount] = useState(0);
  const [minute, setMinute] = useState("15");
  const [hour, setHour] = useState("23");
  const [dayOfMonth, setDayOfMonth] = useState("*");
  const [month, setMonth] = useState("*");
  const [dayOfWeek, setDayOfWeek] = useState("*");

  const {
    field: { value: autobackup, onChange: onChangeAutobackup },
  } = useController({ name: "autobackup", control });

  const autoBackups = useMemo(
    () => [
      {
        id: "1",
        title: "Rotation 7",
        description:
          "A backup is taken every day between 22:00 and 06:00, and the rotation maintains a log of the 7 latest entries. When the new backup is taken, the previous one is deleted.",
        data: {
          cron: "35 5 * * *",
          rotation: 7,
        },
      },
      {
        id: "2",
        title: "Rotation 14",
        description:
          "A backup is taken every day between 22:00 and 06:00, and the rotation maintains a log of the 14 latest entries. When the new backup is taken, the previous one is deleted.",
        data: {
          cron: "15 23 * * *",
          rotation: 14,
        },
      },
      {
        id: "3",
        title: "Custom",
        description:
          "Choose the scheduling yourself (in UNIX Cron format), the number of rotations, and the maximum executions you want.",
        data: {
          cron: `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`,
          rotation: rotation,
          maxExecutionCount: maxExecutionCount,
        },
      },
    ],
    [dayOfMonth, dayOfWeek, hour, maxExecutionCount, minute, month, rotation]
  );

  useEffect(() => {
    if (customSelected) {
      onChangeAutobackup(autoBackups[2].data);
    }
  }, [
    minute,
    hour,
    dayOfMonth,
    month,
    dayOfWeek,
    rotation,
    maxExecutionCount,
    customSelected,
    onChangeAutobackup,
    autoBackups,
  ]);

  const handleAutoBackupChange = (newValue: any) => {
    onChangeAutobackup(newValue);
    setCustomSelected(newValue === autoBackups[2].data);
  };

  const handleRotationIncrement = () => setRotation(rotation + 1);
  const handleMaxExecutionCountIncrement = () =>
    setMaxExecutionCount(maxExecutionCount + 1);

  const handleRotationDecrement = () =>
    setRotation(rotation > 1 ? rotation - 1 : 1);
  const handleMaxExecutionCountDecrement = () =>
    setMaxExecutionCount(maxExecutionCount > 0 ? maxExecutionCount - 1 : 0);

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };

  const CounterInput = ({
    label,
    value,
    onIncrement,
    onDecrement,
    disabledDecrement,
  }: {
    label: string;
    value: number;
    onIncrement: () => void;
    onDecrement: () => void;
    disabledDecrement: boolean;
  }) => (
    <div className="flex items-center gap-3">
      <Typography.Title level={6} className="!text-sm">
        {label}:
      </Typography.Title>
      <div className="flex items-center gap-3">
        <Button
          uiType="icon"
          onClick={onDecrement}
          disabled={disabledDecrement}
        >
          -
        </Button>
        <span className="mx-2">{value}</span>
        <Button uiType="icon" onClick={onIncrement}>
          +
        </Button>
      </div>
    </div>
  );

  return (
    <div className="w-full">
      <div className="flex flex-col gap-1 mb-9">
        <RadioGroup
          by="rotation"
          value={autobackup}
          onChange={handleAutoBackupChange}
        >
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5 mt-3">
            {autoBackups.map((autoBackup, i) => (
              <RadioGroup.Option key={i} value={autoBackup.data}>
                {({ checked, disabled }) => (
                  <div
                    className={cn(
                      "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer h-full",
                      {
                        "border-primary-400 dark:border-primary-400": checked,
                        "!cursor-not-allowed": disabled,
                      }
                    )}
                  >
                    <div className="flex flex-col gap-2">
                      <Typography.Title level={6} className="text-sm">
                        {autoBackup.title}
                      </Typography.Title>
                      <Typography.Text className="!text-xs">
                        {autoBackup.description}
                      </Typography.Text>
                    </div>
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>

        {customSelected && (
          <div className="flex flex-col gap-3 mt-5">
            <Typography.Title level={6}>
              UNIX Cron format (Time zone UTC){" "}
            </Typography.Title>
            <div className="flex flex-row gap-4">
              <Input
                label="Minute (0-59)"
                value={minute}
                type={"text"}
                onChange={handleInputChange(setMinute)}
              />
              <Input
                label="Hour (0-23)"
                value={hour}
                type="text"
                onChange={handleInputChange(setHour)}
              />
              <Input
                label="Day of the month (1-31)"
                value={dayOfMonth}
                type="text"
                onChange={handleInputChange(setDayOfMonth)}
              />
              <Input
                label="Month"
                value={month}
                type="text"
                onChange={handleInputChange(setMonth)}
              />
              <Input
                label="Day of the week"
                value={dayOfWeek}
                type="text"
                onChange={handleInputChange(setDayOfWeek)}
              />
            </div>

            <CounterInput
              label="Rotation"
              value={rotation}
              onIncrement={handleRotationIncrement}
              onDecrement={handleRotationDecrement}
              disabledDecrement={rotation === 1}
            />
            <CounterInput
              label="Number of executions"
              value={maxExecutionCount}
              onIncrement={handleMaxExecutionCountIncrement}
              onDecrement={handleMaxExecutionCountDecrement}
              disabledDecrement={maxExecutionCount === 0}
            />
            <Typography.Text className="!text-sx">
              Number of executions to process before the end of the task. Zero
              (0) means that the task will never end.
            </Typography.Text>
          </div>
        )}
      </div>
    </div>
  );
};
