import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { Accordion, Button, Input, Modal, Select } from "djuno-design";
import { useCallback, useEffect, useState } from "react";
import {
  createDSSTopicAsync,
  getDSSTopicsAsync,
  handleToggleDSSTopicModal,
  selectDatabaseService,
  selectDSSShowTopicEditorModal,
  selectDSSTopicsActionLoading,
} from "../../../store/database/serviceSlice";
import { DataStreamingTopicCreateSchema } from "../../../utils/validations";

export const TopicCreateModal: React.FC = () => {
  const [deletionPolicy, setDeletionPolicy] = useState<string>("");
  const isOpen = useAppSelector(selectDSSShowTopicEditorModal);
  const service = useAppSelector(selectDatabaseService);
  const topicActionLoading = useAppSelector(selectDSSTopicsActionLoading);

  const dispatch = useAppDispatch();

  const methods = useForm({
    resolver: yupResolver(DataStreamingTopicCreateSchema),
    mode: "all",
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = methods;

  const handleSubmitForm = (data: any) => {
    if (!topicActionLoading) {
      const formData = {
        minInsyncReplicas: data.minInsyncReplicas,
        name: data.name,
        partitions: data.partitions,
        replication: data.replication,
        retentionBytes: data.retentionBytes,
        retentionHours: data.retentionHours,
      };
      service &&
        dispatch(
          createDSSTopicAsync({
            engine: service?.engine,
            id: service?.id,
            data: formData,
          })
        ).then((action) => {
          if (action.type === "service/topics/create/fulfilled") {
            dispatch(
              getDSSTopicsAsync({
                engine: service?.engine,
                clusterId: service?.id,
              })
            );
            handleOnClose();
          }
        });
    }
  };

  const handleOnClose = useCallback(() => {
    reset();
    dispatch(handleToggleDSSTopicModal(false));
  }, [dispatch, reset]);

  useEffect(() => {
    return () => {
      handleOnClose();
    };
  }, [handleOnClose]);

  useEffect(() => {
    if (isOpen) {
      setValue("name", "");
      setValue("replication", 3);
      setValue("partitions", 1);
      setValue("retentionHours", -1);
      setValue("minInsyncReplicas", 2);
      setValue("retentionBytes", -1);
    }
  }, [isOpen, setValue]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      contentClassName="!max-w-xl overflow-hidden"
      title={"Create a topic"}
    >
      <form className="mt-5" onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="flex flex-col gap-3">
          <Input
            label="Topic name"
            {...register("name")}
            error={errors.name?.message}
          />

          <Accordion
            items={[
              {
                label: "Advanced configuration",
                panel: (
                  <div className="text-md space-y-4 p-4 ListItem_list-item-child__kMxMj ListItem_list-item-child--border__aiQDj dark:border-dark-2">
                    <div className="grid grid-cols-2 gap-4">
                      <Input
                        label="Replication"
                        {...register("replication")}
                        type="number"
                        error={errors.replication?.message}
                      />
                      <Input
                        label="Partitions"
                        {...register("partitions")}
                        type="number"
                        error={errors.partitions?.message}
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <Input
                        label="Retention time (hours)"
                        {...register("retentionHours")}
                        type="number"
                        error={errors.retentionHours?.message}
                      />
                      <Input
                        label="Minimum in-sync replica "
                        {...register("minInsyncReplicas")}
                        type="number"
                        error={errors.minInsyncReplicas?.message}
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <Input
                        label="Retention size (bytes) "
                        {...register("retentionBytes")}
                        type="number"
                        error={errors.retentionBytes?.message}
                      />
                      <Select
                        label="Deletion Policy"
                        value="default"
                        onChange={(value) => setDeletionPolicy(value ?? "")}
                        options={[{ label: "default", value: "default" }]}
                        disabled
                      />
                    </div>
                  </div>
                ),
              },
            ]}
          />

          <div className="flex justify-end gap-2">
            <Button uiType="simple" onClick={handleOnClose}>
              Cancel
            </Button>
            <Button uiType="primary" type="submit" loading={topicActionLoading}>
              Create topic
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
