import { Typography } from "djuno-design";
import { ContactUsParallaxBox } from "../../components/website/ParallaxBox";
import TeamMember, {
  TeamMemberProps,
} from "../../components/website/TeamMemberCard";
import WebBanner from "../../components/website/WebBanner";
import Moe from "../../assets/images/team/moe.png";
import Max from "../../assets/images/team/max.png";
import Ela from "../../assets/images/team/ela.png";
import Jason from "../../assets/images/team/jason.png";
import Jeremy from "../../assets/images/team/jeremy.png";
import Matt from "../../assets/images/team/matt.png";
import Scott from "../../assets/images/team/scott.png";
import Alex from "../../assets/images/team/alex.png";
import Misson from "../../assets/images/work-desk.webp";
import Vission from "../../assets/images/work-together.webp";

const teamMembers: TeamMemberProps[] = [
  {
    name: "Moe Sayadi",
    title: "CEO/CTO",
    imageSrc: Moe,
    twitterUrl: "https://twitter.com/momosayadi",
    linkedinUrl: "https://www.linkedin.com/in/moesayadi/",
  },
  {
    name: "Max Atanassov",
    title: "Chief Business Officer",
    imageSrc: Max,
    twitterUrl: "https://twitter.com/AtanMax",
    linkedinUrl: "https://www.linkedin.com/in/pseps/",
  },
  {
    name: "Ela Soltani",
    title: "CPO",
    imageSrc: Ela,
    twitterUrl: "https://twitter.com/ElaVantaBlack",
    linkedinUrl: "https://www.linkedin.com/in/elnaz-soltani-7b1a3513a/",
  },
  {
    name: "Alex Feerst ",
    title: "Data Governance Advisor",
    imageSrc: Alex,
    twitterUrl: "",
    linkedinUrl: "https://www.linkedin.com/in/feerst/",
  },
  {
    name: "Scott Carlson",
    title: "Security Advisor",
    imageSrc: Scott,
    twitterUrl: "",
    linkedinUrl: "https://www.linkedin.com/in/scottcarlsoninfosec/",
  },

  {
    name: "Jeremy Franzen",
    title: "Cloud IT Adviser",
    imageSrc: Jeremy,
    twitterUrl: "",
    linkedinUrl: "https://www.linkedin.com/in/jeremy-franzen-8111111/",
  },
  {
    name: "Matt Shocklee",
    title: "Deal Sourcing Adviser",
    imageSrc: Matt,
    twitterUrl: "",
    linkedinUrl: "https://www.linkedin.com/in/matt-shocklee-a5a28a9/",
  },
  {
    name: "Jason English",
    title: "Market Strategy Adviser",
    imageSrc: Jason,
    twitterUrl: "https://twitter.com/bluefug",
    linkedinUrl: "https://www.linkedin.com/in/jasonenglish/",
  },
];

const AboutUsPage = () => {
  return (
    <>
      <WebBanner
        title="About Us"
        breadcrumb={[{ name: "Home", link: "/" }, { name: "About Us" }]}
      />

      <div className="flex flex-col w-full max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1320px] mx-auto h-full px-8 mt-14 ">
        <div className="flex flex-col lg:flex-row md:flex-col relative mb-[-75px]">
          <div className="npm flex flex-col lg:w-2/3 md:w-full relative border-0 rounded-tl-[25px] rounded-bl-[25px] rounded-br-[25px] lg:mb-0 md:mb-8">
            <div className="w-full h-[300px] overflow-hidden relative border-0 rounded-tl-[25px] rounded-bl-[25px] rounded-br-[25px]">
              <img
                src={Misson}
                alt="Misson"
                className="w-full h-full object-cover rounded-tl-[25px] rounded-bl-[25px] rounded-br-[25px] absolute top-0 left-0 z-2"
              />
            </div>
          </div>

          <div className="lg:w-[46%] md:w-full lg:absolute lg:top-1/2 lg:left-0 lg:z-10 lg:-translate-y-1/2 lg:bg-white lg:p-6 rounded-tl-[25px] rounded-tr-[3px] rounded-br-[25px] rounded-bl-[25px] shadow-lg md:relative md:top-auto md:left-auto lg:ml-[54%] p-6 md:mb-8 dark:bg-dark-2 mt-8 sm:mt-8 md:mt-0">
            <Typography.Text className="font-semibold ">
              Our Mission
            </Typography.Text>
            <Typography.Paragraph className="mt-2 ">
              To empower developers and domain experts by providing a
              comprehensive, easy-to-use blockchain infrastructure, and offering
              intuitive, low-code visual tools to seamlessly develop and deploy
              Web3 applications, accelerating their journey to market.
            </Typography.Paragraph>
          </div>
        </div>
      </div>

      <ContactUsParallaxBox />

      <div className="flex flex-col w-full max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1320px] mx-auto h-full px-8 mt-14 ">
        <div className="flex flex-col lg:flex-row-reverse md:flex-col relative lg:items-center">
          <div className="flex flex-col lg:w-2/3 md:w-full relative border-0 rounded-tl-[25px] rounded-bl-[25px] rounded-br-[25px] lg:mb-0 md:mb-8">
            <div className="w-full h-[300px] overflow-hidden relative rounded-tl-[25px] rounded-bl-[25px] rounded-br-[25px]">
              <img
                src={Vission}
                alt="Vission"
                className="w-full h-full object-cover border-0 rounded-tl-[25px] rounded-bl-[25px] rounded-br-[25px] absolute top-0 left-0 z-2"
              />
            </div>
          </div>

          <div className="lg:w-[46%] md:w-full lg:absolute lg:top-1/2 lg:left-0 lg:z-10 lg:-translate-y-1/2 lg:bg-white lg:p-6 rounded-tl-[25px] rounded-tr-[3px] rounded-br-[25px] rounded-bl-[25px]  shadow-lg md:relative md:top-auto md:left-auto p-6 dark:bg-dark-2 mt-8 sm:mt-8 md:mt-0">
            <Typography.Text className="font-semibold ">
              Our Vision
            </Typography.Text>
            <Typography.Paragraph className="mt-2 ">
              Djuno is a complete stack for enterprise blockchain provided as a
              low cost low code platform
            </Typography.Paragraph>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1320px] mx-auto h-full px-8 mt-14 mb-40">
        <div className="flex flex-col lg:flex-row md:flex-col relative mb-[-100px]">
          <div className="container mx-auto px-4">
            <div className="text-center">
              <Typography.Title className="text-4xl font-black tracking-wider mb-6 dark:!text-white">
                Our Team
              </Typography.Title>
              <Typography.Paragraph className="max-w-md mx-auto ">
                We’re a team of seasoned business and IT professionals with deep
                experience in machine learning and cloud computing.
              </Typography.Paragraph>
            </div>

            {/* Team Members Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-16">
              {teamMembers.map((member, index) => (
                <TeamMember
                  key={index}
                  imageSrc={member.imageSrc}
                  name={member.name}
                  title={member.title}
                  twitterUrl={member.twitterUrl}
                  linkedinUrl={member.linkedinUrl}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;
