import {
  Button,
  cn,
  Dropdown,
  Flex,
  Loading,
  Tabs,
  Typography,
} from "djuno-design";
import { Helmet } from "react-helmet";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
// import CardImage from "./../../assets/images/bloomberg.png";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../assets/icons/more.svg";
import { WorkflowsUrl, WorkflowUrl } from "../../utils/urls";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  deleteWorkflowtemplateAsync,
  getWorkflowsTemplatesAsync,
  selectWrokflowsTemplates,
  selectWrokflowsTemplatesLoading,
} from "../../store/workflows/workflowsSlice";
import { WorkflowsTemplate } from "../../types/workflows";
import { DeleteModal } from "../../components/modals/QuestionModal";
import useConfigIntercomeSetting from "../../hooks/useConfigIntercomeSetting";

export const templateOptions = [
  {
    label: "All",
    value: "0",
  },
  {
    label: "Private",
    value: "1",
  },
  {
    label: "Market",
    value: "2",
  },
];

const WorkflowTemplatePage = () => {
  useConfigIntercomeSetting();
  const templates = useAppSelector(selectWrokflowsTemplates) || [];
  const templatesLoading = useAppSelector(selectWrokflowsTemplatesLoading);

  const [selectedTemplate, setSelectedTemplate] = useState<
    WorkflowsTemplate | undefined
  >();
  const [templateOption, setTemplateOption] = useState<string>("0");
  const [filtertemplates, setFiltertempaltes] = useState<WorkflowsTemplate[]>(
    []
  );

  const privateTemplates = useMemo(() => {
    return templates.filter((t) => t.IsPrivate === true);
  }, [templates]);

  const marketTemplates = useMemo(() => {
    return templates.filter((t) => t.IsPrivate === false);
  }, [templates]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getWorkflowsTemplatesAsync());
  }, [dispatch]);

  const handleSelectTemplate = (template: WorkflowsTemplate) => {
    setSelectedTemplate(template);
  };

  console.log("tempaltes", templates);

  useEffect(() => {
    const filterTemplatesByOption = () => {
      if (templateOption === "0") {
        setFiltertempaltes(templates);
      } else if (templateOption === "1") {
        setFiltertempaltes(privateTemplates);
      } else if (templateOption === "2") {
        setFiltertempaltes(marketTemplates);
      } else {
        setFiltertempaltes([]);
      }
    };

    filterTemplatesByOption();
  }, [templateOption, templates]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Templates</title>
        <meta
          name="description"
          content="RPC-endpoints, or Remote Procedure Call endpoints, are a fundamental aspect of distributed computing systems, enabling communication between different components or services across a network"
        />
      </Helmet>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 bg-white dark:bg-dark-1">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <div className="font-medium flex items-center gap-1">
            <Typography.Text>Templates</Typography.Text>
          </div>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(WorkflowsUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <div className="mt-5 w-full overflow-x-auto px-6 min-h-[calc(100%-6rem)]">
        <div className="flex justify-start mb-5 gap-5">
          <Tabs
            listClassName="!w-full"
            tabType="creamy"
            options={templateOptions.map((option) => ({
              label: option.label,
            }))}
            selectedIndex={templateOptions.findIndex(
              (o) => o.value === templateOption
            )}
            onChange={({ index }) => {
              if (index !== undefined) {
                const selectedOption = templateOptions[index];
                if (selectedOption) {
                  setTemplateOption(selectedOption.value);
                }
              }
            }}
          />
        </div>
        <div className="flex-1 flex  pb-20">
          <AnimatePresence>
            <>
              {!templatesLoading && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="flex flex-row pb-12 gap-10 flex-wrap"
                >
                  {!templatesLoading &&
                    Array.isArray(templates) &&
                    filtertemplates.map(
                      (template: WorkflowsTemplate, index: any) => (
                        <WorkflowTemplateCard
                          key={index}
                          {...template}
                          selected={selectedTemplate?.Id === template.Id}
                          onClick={() => handleSelectTemplate(template)}
                        />
                      )
                    )}
                </motion.div>
              )}
              {templatesLoading && (
                <motion.div
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="min-h-[300px] w-full flex justify-center items-center"
                >
                  <Loading borderSize={2} />
                </motion.div>
              )}
            </>
          </AnimatePresence>
          <div className="fixed bottom-0 right-0 left-0 flex items-center justify-end w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6 lg:pl-80 z-30">
            <div className="">
              <Button
                uiType="primary"
                disabled={!!!selectedTemplate}
                onClick={() =>
                  navigate(WorkflowUrl(), {
                    state: { selectedTemplate: selectedTemplate },
                  })
                }
                className="group"
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const WorkflowTemplateCard = ({
  TemplateDescription,
  TemplateImage,
  TemplateName,
  IsPrivate,
  selected,
  Id,
  onClick,
}: WorkflowsTemplate & {
  selected?: boolean;
  onClick?: () => void;
}) => {
  const [deleteTemplateId, setDeleteTemplateId] = useState<number | null>(null);
  const templates = useAppSelector(selectWrokflowsTemplates) || [];
  const templatesLoading = useAppSelector(selectWrokflowsTemplatesLoading);
  const dispatch = useAppDispatch();
  return (
    <div
      className={cn(
        "border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white shadow hover:shadow-lg transition-all duration-300 w-64",
        {
          "border-primary-400 dark:border-primary-400": selected,
          "cursor-pointer": onClick,
        }
      )}
      onClick={() => (onClick ? onClick() : {})}
    >
      <Flex direction="col" items="center" className="text-center">
        <Flex
          items="center"
          justify="center"
          className="relative h-40 w-full bg-gray-100 dark:bg-dark-2 rounded-tl-lg rounded-tr-lg "
        >
          {TemplateImage ? (
            <img
              className="w-full h-full rounded-tl-lg rounded-tr-lg object-cover"
              alt=""
              src={TemplateImage}
            />
          ) : (
            <div className="h-full w-full flex items-center justify-center rounded-tl-lg rounded-tr-lg bg-gray-200 dark:bg-gray-700">
              <Typography.Text size="sm" className="text-gray-500">
                No Image Available
              </Typography.Text>
            </div>
          )}

          {IsPrivate === true && (
            <div className="absolute top-2 right-2 z-10">
              <Dropdown
                anchor="bottom end"
                itemsClassName="!w-40"
                menu={[
                  {
                    key: "end",
                    label: (
                      <div className="flex items-center gap-1">
                        <ArchiveIcon className="w-4" />
                        Delete
                      </div>
                    ),
                    danger: true,
                    onClick: (_, close) => {
                      close();
                      setDeleteTemplateId(Id);
                    },
                    disabled: !IsPrivate,
                  },
                ]}
              >
                <Button uiType="icon" uiSize="small" className="!px-2">
                  <MoreIcon className="w-4 h-4" />
                </Button>
              </Dropdown>
            </div>
          )}
        </Flex>

        <Flex direction="col" className="gap-1 m-2">
          <Typography.Text size="sm" className="font-semibold whitespace-pre">
            {TemplateName}
          </Typography.Text>
          <Typography.Text
            size="sm"
            uiType="secondary"
            className="whitespace-normal break-words max-w-[200px] max-h-[60px] overflow-hidden"
          >
            {TemplateDescription}
          </Typography.Text>
        </Flex>
      </Flex>

      <DeleteModal
        title="Delete Template"
        isOpen={deleteTemplateId !== null}
        onClose={() => setDeleteTemplateId(null)}
        description=""
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteTemplateId &&
            dispatch(deleteWorkflowtemplateAsync(deleteTemplateId)).then(
              (action) => {
                if (action.type === "workflow/template/delete/fulfilled") {
                  dispatch(getWorkflowsTemplatesAsync());
                } else {
                }
                setDeleteTemplateId(null);
              }
            );
        }}
        loading={templatesLoading}
        confirmString={
          templates
            .filter((template) => template.IsPrivate === true)
            .find((t) => t.Id === deleteTemplateId)?.TemplateName
        }
      />
    </div>
  );
};

export default WorkflowTemplatePage;
